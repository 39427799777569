/**
 * eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/**
 * /* eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, no-mixed-spaces-and-tabs: 1
 *
 * @format
 */

import {ImageProps} from 'next/image';
import {linkResolver} from '../../lib/linkResolver';
import {accordionMapping} from '../Accordions/accordionMapping';
import {PartnerCardProps} from './PartnerCardProps';
// import {linkResolver} from '../../../lib/linkResolver';

export const partnerCardMapping = (data: any): PartnerCardProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {
		logo,
		logoLink,
		title,
		websiteAddress,
		description,
		personName,
		jobTitle,
		contactNumber,
		emailAddress,
		accordionList = [],
		socialMediaLinks,
		activeSubscriptions,
	} = data;

	// prettier-ignore
	const image: ImageProps = logo
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: logo?.altText || '',

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: logo?.sourceUrl,

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: logo?.mediaDetails.height,

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: logo?.mediaDetails.width,
		  }
		: undefined;

	const imageLink = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		title: logoLink?.title || '',
		href: logoLink?.url ? linkResolver(logoLink.url) : null,
		isExternal: logoLink?.target ? Boolean(logoLink.target === '_blank') : null,
	};

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const socialMediaLinksBtns =
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		socialMediaLinks?.map(item => ({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			title: item.socialMediaLinks?.title || '',
			url: linkResolver(item.socialMediaLinks?.url),
			isExternal: item.socialMediaLinks.target === '_blank',
		})) || [];

	return {
		image,
		imageLink,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		title,
		websiteAddress: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			title: websiteAddress?.title || '',
			url: websiteAddress?.url ? linkResolver(websiteAddress.url) : null,
			isExternal: websiteAddress?.target === '_blank',
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		description: description || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		personName: personName || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		jobTitle: jobTitle || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		contactNumber: contactNumber || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		emailAddress: emailAddress || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		accordions: accordionMapping({accordionList}),
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		socialMediaLinks: socialMediaLinksBtns,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		activeSubscriptions: activeSubscriptions || 0,
	};
};
