import {CSSWithMultiValues, Box, Text, Grid} from '@chakra-ui/react';
import Image from 'next/future/image';

type IconBulletProps = {
	bullet: {
		icon: {
			sourceUrl: string;
			altText: string;
		};
		text: string;
	};
	textStyle: CSSWithMultiValues;
};

const IconBullet = ({bullet, textStyle}: IconBulletProps) => {
	const {icon, text} = bullet;
	return (
		<Box pb={6}>
			<Grid gridTemplateColumns='50px 1fr' alignItems='center' gap={8}>
				<Box w={50} h={50}>
					<Image src={icon.sourceUrl} alt={icon.altText} width={50} height={50} />
				</Box>
				<Text sx={textStyle}>
					{text}
				</Text>
			</Grid>
		</Box>
	);
};

export default IconBullet;
