/* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-implicit-any-catch: 1, @typescript-eslint/no-unsafe-return: 1, @typescript-eslint/restrict-template-expressions: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/no-floating-promises: 1, array-callback-return: 1 */
import React, {ReactElement, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Box, Button, Container, Flex, Grid, Spinner} from '@chakra-ui/react';
import axios from 'axios';
import Card from '../../components/Card';
import {Filter} from './Filter';
import {PostGridProps} from './PostGridProps';
import {FilterProps, TermProps} from './FilterProps';
import {FilterPostsRequestProps} from './FilterPostsRequestProps';

function PostGrid({
	columns,
	pageSize,
	postType,
	taxonomiesArray,
	containerProps,
}: PostGridProps): ReactElement {
	const [loading, setLoading] = useState(true);
	const [posts, setPosts] = useState<any[]>([]);
	const [filters, setFilters] = useState<FilterProps[]>([]);
	const [endCursor, setEndCursor] = useState('');
	const [startCursor, setStartCursor] = useState('');
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPreviousPage, setHasPreviousPage] = useState(false);

	const fetchData = async (
		taxQueryArray: FilterPostsRequestProps['taxQueryArray'],
		after: FilterPostsRequestProps['after'],
		before: FilterPostsRequestProps['before'],
		first: FilterPostsRequestProps['first'],
		last: FilterPostsRequestProps['last'],
	) => {
		const body = {
			taxQueryArray,
			after,
			before,
			first,
			last,
		};

		try {
			setLoading(true);
			const response = await axios.post(`/api/post-types/${postType}/search`, {
				...body,
			});

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const {posts, pageInfo} = response.data;

			// This function just batches the state updates to prevent multiple rerenders
			ReactDOM.unstable_batchedUpdates(() => {
				setPosts(posts);
				setEndCursor(pageInfo.endCursor);
				setStartCursor(pageInfo.startCursor);
				setHasNextPage(pageInfo.hasNextPage);
				setHasPreviousPage(pageInfo.hasPreviousPage);
				setLoading(false);
			});
		} catch (e) {
			console.log(e.response);
		}
	};

	const buildTaxArrayFromFilters = (filters: FilterProps[]) => {
		const taxArray = filters.map((filter: FilterProps) => ({
			terms: [...filter.selectedTerms],
			taxonomySingle: filter.taxonomySingle,
		}));
		return taxArray;
	};

	function lowerCaseFirst(str: string) {
		return str[0].toLowerCase() + str.slice(1);
	}

	useEffect(() => {
		const fetchFiltersAndInitialPosts = async () => {
			try {
				// 1. First get taxonomies
				const taxonomyPromises = taxonomiesArray.map(async taxonomy =>
					axios.get(`/api/taxonomies/${taxonomy.taxonomy}`),
				);

				const taxonomyResponses = await Promise.all(taxonomyPromises);
				const taxonomies = taxonomyResponses.map(response => {
					const taxParameterPlural = lowerCaseFirst(
						response.data.taxonomy.graphqlPluralName,
					);
					const taxParameterSingle = lowerCaseFirst(
						response.data.taxonomy.graphqlSingleName,
					);

					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const taxonomy = {...response.data.taxonomy};
					taxonomy.graphqlPluralName = taxParameterPlural;
					taxonomy.graphqlSingleName = taxParameterSingle;
					return taxonomy;
				});

				// 2. Get terms from taxonomies
				const termPromises = taxonomies.map(async taxonomy =>
					axios.get(`/api/taxonomy-types/${taxonomy.graphqlPluralName}`),
				);

				const termReponses = await Promise.all(termPromises);
				const filters = taxonomiesArray.map((taxonomy, index) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const {terms} = termReponses[index].data;

					const filter: FilterProps = {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
						terms: terms.map((term: TermProps) => ({
							slug: term.slug,
							name: term.name,
						})),
						label: taxonomy.label,
						allLabel: taxonomy.allLabel,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						taxonomySingle: taxonomies[index].graphqlSingleName,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
						selectedTerms: terms.map((term: TermProps) => term.slug),
					};

					return filter;
				});

				setFilters(filters);

				const initialTaxArray = filters.map(filter => {
					const allTerms = filter.terms.map((term: TermProps) => term.slug);
					return {
						terms: [...allTerms],
						taxonomySingle: filter.taxonomySingle,
					};
				});

				// 3. Get posts
				fetchData(initialTaxArray, '', '', pageSize, null);
			} catch (err) {
				console.error('Error response PostGrid:');
				console.error(err);
			}
		};

		fetchFiltersAndInitialPosts();
	}, []);

	const handleChangeFilter = (
		e: React.ChangeEvent<HTMLInputElement>,
		taxonomy: string,
	) => {
		const newFilters = [...filters];

		newFilters.map(filter => {
			if (filter.taxonomySingle === taxonomy) {
				// Reset because this is a single select filter
				filter.selectedTerms = [];

				if (e.target.value === 'all') {
					filter.selectedTerms = filter.terms.map(
						(term: TermProps) => term.slug,
					);
					return;
				}

				filter.selectedTerms.push(e.target.value);
			}
		});

		setFilters(newFilters);
		const taxArray = buildTaxArrayFromFilters(newFilters);

		// Whenever the filter is changed we will rest the paging by sending empty strings to the before and after and settings last to null
		fetchData(taxArray, '', '', pageSize, null);
	};

	const handleNextPage = async (cursor: string) => {
		const taxArray = buildTaxArrayFromFilters(filters);

		fetchData(taxArray, cursor, '', pageSize, null);
	};

	const handlePreviousPage = async (cursor: string) => {
		const taxArray = buildTaxArrayFromFilters(filters);

		fetchData(taxArray, '', cursor, null, pageSize);
	};

	return (
		<Box
			backgroundColor={containerProps.backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			py={{base: '10', md: '12'}}
		>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{
						base: '1fr',
						sm: '1fr 1fr',
						md: 'repeat(3, 1fr)',
					}}
					columnGap='8'
					rowGap='4'
					marginBottom='8'
				>
					{filters.map((filter: FilterProps, i) => (
						<Filter
							key={`filter-${i}`}
							terms={filter.terms}
							handleChange={handleChangeFilter}
							label={filter.label}
							taxonomy={filter.taxonomySingle}
							allLabel={filter.allLabel}
						/>
					))}
				</Grid>
				<Grid
					templateColumns={{
						base: '1fr',
						sm: '1fr 1fr',
						md: `repeat(${columns}, 1fr)`,
					}}
					columnGap='8'
					rowGap='4'
				>
					{loading ? (
						<Spinner color='lime.500'/>
					) : (
						posts.map((post: any, index: number) => (
							<Card
								card={{
									image: {
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										alt: post.card.cardviewFeaturedImage.altText,
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										src: post.card.cardviewFeaturedImage.sourceUrl,
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										height:
                        post.card.cardviewFeaturedImage.mediaDetails.height,
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										width: post.card.cardviewFeaturedImage.mediaDetails.width,
									},
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									heading: post.card.cardviewHeading,
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									excerpt: post.card.cardviewExcerpt,
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									linkText: post.card.cardviewLinkText,
								}}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								uri={post.uri}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								terms={post.terms}
								key={`${post.slug}-${index}`}
								containerProps={{flexDirection: 'column'}}
								cardContentPaddingX='0'
								cardContentPaddingY={{base: '6', md: '6'}}
							/>
						))
					)}
				</Grid>
				<Flex mt={{base: '10', md: '12'}} justifyContent={'center'}>
					{hasPreviousPage && (
						<Button onClick={async () => handlePreviousPage(startCursor)} mr='auto'>
              Previous {pageSize}
						</Button>
					)}
					{hasNextPage && (
						<Button onClick={async () => handleNextPage(endCursor)} ml='auto'>
              Next {pageSize}
						</Button>
					)}
				</Flex>
			</Container>
		</Box>
	);
}

export default PostGrid;
