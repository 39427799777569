/** @format */

import React, {ReactElement} from 'react';
import {chakra, Flex} from '@chakra-ui/react';
import {TitleTextProps} from './TitleTextProps';
import {textStyles} from '../../styles/js/textStyles';

function TitleText({heading, variant}: TitleTextProps): ReactElement {
	const color = variant && variant === 'light' ? 'Arctic' : 'Abyss';
	const headingTextStyle = textStyles.heading1LeftDark;

	return (
		<Flex
			direction='column'
			alignItems='flex-start'
			sx={{'> *:last-child': {mb: '0'}}}>
			<chakra.h1
				pr={{lg: '1.5em'}}
				sx={headingTextStyle}
				mb={{base: '6'}}
				whiteSpace='pre-line'>
				{heading}
			</chakra.h1>
		</Flex>
	);
}

export default TitleText;
