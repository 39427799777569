/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/** @format */

import React, {ReactElement} from 'react';
import {Box, Container, Grid, space} from '@chakra-ui/react';
import TextComponent1 from '../../components/TextComponent/TextComponent1';
import {IntroductionProps} from './IntroductionProps';

function Introduction({
	textComponent1,
	textAlignment,
	containerProps,
	backgroundColor,
}: IntroductionProps): ReactElement {
	if (!textComponent1) {
		return null;
	}

	return (
		<Box
			backgroundColor={
				backgroundColor || containerProps?.backgroundColor || 'white'
			}
			as='section'
			overflow='hidden'
			{...containerProps}>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{base: '1fr', md: '1fr 9fr 1fr 1fr'}}
					templateAreas={
						textAlignment === 'center'
							? {base: '"content"', md: '". content content ."'}
							: {base: '"content"', md: '"content content . ."'}
					}
					columnGap='8'>
					<Box gridArea='content' py={{base: '5rem'}}>
						<TextComponent1
							containerProps={{
								textAlign: textAlignment,
								alignItems: textAlignment,
							}}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							heading={textComponent1.heading}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							text={textComponent1.text}
							buttonLink={textComponent1.buttonLink}
							variant={'dark'}
							linkButtonJustifyContent={'center'}
						/>
					</Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default Introduction;
