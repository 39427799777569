/** @format */

import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons';
import {
	Text,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	IconButton,
	InputRightElement,
} from '@chakra-ui/react';
import {Field} from 'formik';
import React from 'react';
import {fonts} from '../../styles/js/fonts';

export function FormikField({
	errors,
	touched,
	label = '',
	nameKey = '',
	placeholder = '',
	type = 'input',
	...fieldProps
}): any {
	const [showPassword, setShowPassword] = React.useState(false);

	return (
		<Field name={nameKey}>
			{({field, form}: any) => (
				<FormControl
					isInvalid={Boolean(touched[nameKey] && errors[nameKey])}
					pt={label ? '8' : '0.75rem'}
					position='relative'>
					{label ? (
						<>
							<FormLabel htmlFor={nameKey} textStyle='displayFormLabel'>
								{label}{' '}
								{fieldProps.tooltip ? <span>{fieldProps.tooltip}</span> : null}
							</FormLabel>
						</>
					) : null}

					<InputGroup>
						{type === 'password' ? (
							<>
								<Input
									{...field}
									{...fieldProps}
									id={nameKey}
									placeholder={placeholder}
									type={showPassword ? 'text' : 'password'}
									onBlur={() => {
										setShowPassword(false);
									}}
								/>
								<InputRightElement width='4.5rem'>
									<IconButton
										variant={''}
										onClick={() => {
											setShowPassword(!showPassword);
										}}
										icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
										aria-label='view password'
									/>
								</InputRightElement>
							</>
						) : (
							<Input
								{...field}
								{...fieldProps}
								id={nameKey}
								placeholder={placeholder}
								type={type}
							/>
						)}
					</InputGroup>

					{touched[nameKey] && errors[nameKey] && (
						<Text color='tomato' fontFamily={fonts.base}>
							{errors[nameKey]}
						</Text>
					)}
				</FormControl>
			)}
		</Field>
	);
}
