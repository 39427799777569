/**
 * eslint react/prop-types: 1
 *
 * @format
 */

import {
	Box,
	Stack,
	Image,
	Text,
	useStyleConfig,
	useMultiStyleConfig,
	useBreakpointValue,
	StackDirection,
	VStack,
	HStack,
	Grid,
	Container,
} from '@chakra-ui/react';
import Link from 'next/link';
import ButtonLink from '../../components/ButtonLink';
import {textStyles} from '../../styles/js/textStyles';
import {ImageAndTextProps} from './imageAndTextProps';

const ImageAndText = ({
	image,
	buttonLinks,
	text,
	backgroundColor,
}: ImageAndTextProps) => {
	const styles = useMultiStyleConfig('imageAndText');
	const headingTextStyle = textStyles.display5LeftDark;
	const bodyTextStyle = textStyles.paragraph1LeftItalicDark;

	const stackDirection: StackDirection = useBreakpointValue(
		{
			base: 'column',
			md: 'row',
		},
		{ssr: true},
	);

	const ImageContainer = () => (
		<Box>
			{/* eslint-disable-next-line react/prop-types */}
			<Image src={image.src} sx={styles.image} fit='cover' />
		</Box>
	);

	let linkButtons = null;

	if (buttonLinks && buttonLinks.length > 0) {
		linkButtons = buttonLinks.map(buttonLink => {
			const buttonSize = useBreakpointValue(
				{
					base: 'fullWidth',
					md: '',
				},
				{ssr: true},
			);

			const link = {
				buttonVariant: buttonLink.variant,
				link: {
					target: buttonLink.target,
					title: buttonLink.title,
					url: buttonLink.url,
				},
			};
			return <ButtonLink buttonLink={link} key={link.link.url} />;
		});
	}

	const MobileButtons = () => <VStack my='1rem'>{linkButtons}</VStack>;

	const DesktopButtons = () => <HStack my='2rem'>{linkButtons}</HStack>;

	const ButtonContainer = useBreakpointValue(
		{
			base: MobileButtons,
			md: DesktopButtons,
		},
		{ssr: true},
	);

	return (
		<Box bgColor={backgroundColor}>
			<Container maxW='container.xl' mx='auto'>
				<Stack
					spacing='1.8rem'
					direction={stackDirection ?? 'column'}
					sx={styles.stack}>
					{image.src && image.alignment === 'left' && <ImageContainer />}
					<Box sx={styles.textContainer}>
						<Box sx={styles.headingTextContainer} mr='9rem'>
							<Text sx={headingTextStyle}>{text.heading}</Text>
						</Box>
						<Box sx={styles.bodyTextContainer}>
							<Text sx={bodyTextStyle}>{text.body}</Text>
						</Box>
						{ButtonContainer ? <ButtonContainer /> : null}
					</Box>
					{image.src && image.alignment === 'right' && <ImageContainer />}
				</Stack>
			</Container>
		</Box>
	);
};

export default ImageAndText;
