/** @format */

import React, {ReactElement} from 'react';
import {chakra, Box, Flex, SystemStyleObject} from '@chakra-ui/react';
import LinkButton from '../../components/LinkButton';
import Link from 'next/link';
import Badge from '../../components/Badge';
import {TextComponent1Props} from './TextComponent1Props';

function TextComponent1({
	heading,
	text,
	overline,
	buttonLink,
	buttonVariant,
	variant,
	label,
	containerProps,
	badge,
	linkButtonJustifyContent,
}: TextComponent1Props): ReactElement {
	const color = variant === 'light' ? 'Light' : 'Dark';

	const bottomMargin = heading && !(text || overline || buttonLink || label) ? '0' : '10';

	const styles: SystemStyleObject = {
		'> *:last-child': {mb: '0'},
		mb: bottomMargin,
	};

	return (
		<Flex
			direction='column'
			sx={styles}
			alignItems='flex-start'
			{...containerProps}>
			{label && (
				<Box mb={{base: '4', md: '5'}}>
					<Badge colorScheme='azure.600' size={badge?.size} label={label} />
				</Box>
			)}

			{overline && (
				<Box
					mb={{base: '4', md: '5'}}
					textStyle={{
						base: `display8LeftAbyss${color}`,
					}}>
					{overline}
				</Box>
			)}

			{heading && (
				<chakra.h2
					textStyle={{
						base: `display5Left${color}`,
						md: `display5Left${color}`,
					}}
					mb={{base: '4', md: '6'}}
					whiteSpace='pre-line'>
					{heading}
				</chakra.h2>
			)}

			{text && (
				<Box
					textStyle={{
						base: `paragraph1Left${color}2`,
					}}
					mb={{base: '6', md: '10'}}
					whiteSpace='pre-line'>
					{text}
				</Box>
			)}

			{buttonLink && (
				<Box w={{base: '100%', sm: 'auto'}}>
					<Link href={buttonLink.url} passHref>
						<LinkButton
							w={{base: '100%', sm: 'auto'}}
							text={buttonLink.title}
							isExternal={buttonLink.target === '_blank'}
							variant={buttonVariant ? buttonVariant : 'solid'}
							color={color}
							justifyContent={linkButtonJustifyContent}
						/>
					</Link>
				</Box>
			)}
		</Flex>
	);
}

export default TextComponent1;
