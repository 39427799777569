/**
 * /* eslint-disable complexity
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/** @format */

import React, {ReactElement, useEffect, useState} from 'react';
import {
	Box,
	Container,
	Grid,
	GridItem,
	useBreakpointValue,
} from '@chakra-ui/react';
import {HeroBannerProps} from './HeroBannerProps';
import Image from '../../../components/Image';
import HeroText from '../HeroText';

function HeroBanner({
	heroText,
	overlayTextAlignment,
	hasBackground,
	image,
	textColor,
	backgroundColor,
	backgroundHeight,
}: HeroBannerProps): ReactElement {
	if (!heroText) {
		return null;
	}

	const view = useBreakpointValue({base: 'mobile', md: 'desktop'}, {ssr: true});

	const [heroTextVariantColor, setHeroTextVariantColor] = useState('light');

	useEffect(() => {
		let textVariantColor = hasBackground && image ? 'light' : 'light';
		if (backgroundColor === 'white' && !image) {
			textVariantColor = 'Dark';
		}

		if (textColor && textColor !== 'white') {
			textVariantColor = textColor;
		}

		setHeroTextVariantColor(textVariantColor);

		return () => {
			setHeroTextVariantColor('light');
		};
	}, [hasBackground]);

	const badgeProps = {size: 'lg', label: heroText?.badge?.label};
	const overlay
		= hasBackground && image
			? 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))'
			: 'none';

	const computedBackgroundColor
		= overlay === 'none' && view === 'mobile' && backgroundColor === 'white'
			? 'white'
			: backgroundColor;

	return (
		<Box
			px={{base: 4}}
			backgroundColor={computedBackgroundColor}
			as='section'
			overflow='hidden'
			position='relative'>
			{view === 'mobile' ? null : (
				<Box position='absolute' top='0' left='0' right='0' bottom='0'>
					{image && (
						<Image
							src={image.src}
							alt={image.alt}
							/* width={backgroundImage.mediaDetails.width}
	height={backgroundImage.mediaDetails.height} */
							layout='fill'
							objectFit='cover'
							objectPosition='center'
						/>
					)}
				</Box>
			)}
			{overlay ? (
				<Box
					position='absolute'
					top='0'
					left='0'
					right='0'
					bottom='0'
					backgroundImage={overlay}
					backgroundSize='cover'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'></Box>
			) : null}
			<Container
				position='relative'
				maxW='container.xl'
				mx={{base: 'auto'}}
				className='Container'
				px={{base: 0, md: '16px'}}
			>
				<Grid
					templateColumns={{base: '1fr', md: '10fr 2fr'}}
					columnGap={view === 'mobile' ? 0 : 8}
					rowGap={{base: 0, md: '0'}}
					minHeight={
						backgroundHeight === 'short'
							? {base: '520px', md: '536px'}
							: backgroundHeight === 'tall'
								? {base: '664px', md: '776px'}
								: {base: '302px', md: '360px'}
					}>
					{view === 'mobile' && image ? (
						<GridItem>
							<Box>
								{image && (
									<Image
										src={image.src}
										alt={image.alt}
										width={image.width}
										height={image.height}
										layout='intrinsic'
										objectPosition='center'
									/>
								)}
							</Box>
						</GridItem>
					) : null}
					<GridItem my={{md: 10}}>
						<Box
							alignSelf={
								overlayTextAlignment === 'bottom'
									? 'flex-end'
									: overlayTextAlignment === 'center'
										? 'center'
										: 'flex-start'
							}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							height='100%'
						>
							<HeroText
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								heading={heroText.heading}
								supportingText={heroText.supportingText}
								buttonLink={heroText.button}
								variant={heroTextVariantColor}
								badge={badgeProps}></HeroText>
						</Box>
					</GridItem>
				</Grid>
			</Container>
		</Box>
	);
}

export default HeroBanner;
