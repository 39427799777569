/** @format */

import {
	FormControl,
	FormLabel,
	InputGroup,
	FormErrorMessage,
	Select,
} from '@chakra-ui/react';
import {Field} from 'formik';
import {colors} from '../../styles/js/colors';

export function FormikFieldSelect({
	errors,
	touched,
	label = '',
	nameKey = '',
	placeholder = '',
	options = [],
	required = false,
	...fieldProps
}): any {
	return (
		<Field name={nameKey}>
			{({field, form}: any) => (
				<FormControl
					isInvalid={Boolean(errors[nameKey] && touched[nameKey])}
					pt={label ? '8' : '0.75rem'}
					position='relative'>
					{label ? (
						<FormLabel
							htmlFor={nameKey}
							textStyle={{
								base: 'displayFormLabel',
							}}>
							{label}
						</FormLabel>
					) : null}
					<Select
						{...field}
						{...fieldProps}
						style={{
							height: fieldProps.multiple ? '100px' : '48px',
							paddingLeft: '16px',
							paddingRight: '32px',
							width: '100%',
							border: `${colors.obsidian[100]} 2px solid`,
						}}
						id={nameKey}
						required={Boolean(required)}>
						{options.map((option, i) => {
							if (option.options) {
								return (
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									<optgroup key={option.text} label={option.text}>
										{
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											option.options.map(childOption => (
												<option
													// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
													key={childOption.value}
													// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
													value={childOption.value}
													disabled={Boolean(option.disable)}>
													{childOption.text}
												</option>
											))
										}
									</optgroup>
								);
							}

							return (
								<option
									key={`${i}-option-type`}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									value={option.value}
									disabled={Boolean(option.disable)}>
									{option.text}
								</option>
							);
						})}
					</Select>
					<FormErrorMessage position='absolute'>
						{errors[nameKey]}
					</FormErrorMessage>
				</FormControl>
			)}
		</Field>
	);
}
