/** @format */

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Container,
} from '@chakra-ui/react';
import {ReactElement} from 'react';
import {AccordionProps} from './accordionProps';

import minusIcon from '../../../public/images/icon-minus-24-px.png';
import plusIcon from '../../../public/images/icon-plus-24-px.png';
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {colors} from '../../styles/js/colors';

function Accordions({
	accordions,
	allowMultiple,
	alignTitleText,
	backgroundColor,
	containerProps,
}: AccordionProps): ReactElement {
	if (!accordions || accordions.length === 0) {
		return null;
	}

	return (
		<Box
			className='accordions'
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			py={{base: '3rem'}}>
			<Container maxW='container.xl' mx='auto' {...containerProps}>
				<Accordion allowMultiple={allowMultiple}>
					{accordions.map((accordion, i) => (
						<AccordionItem key={`accordion-item-${i}`}>
							{({isExpanded}) => (
								<>
									<h2>
										<AccordionButton
											borderTop='2px solid #c4c5c7'
											_expanded={{
												borderTop: '2px solid #c4c5c7',
												borderBottom: '2px solid #c4c5c7',
												// borderBottom: `2px solid ${colors.slate.500}`,
											}}
											py={{base: '1rem', md: '1.5rem'}}
											textStyle={{
												base: 'display7LeftDark',
												md: 'display7LeftDark',
											}}>
											{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
											<Box flex='1' textAlign={alignTitleText}>
												{accordion.title}
											</Box>
											{/* <AccordionIcon /> */}
											{isExpanded ? (
												<MinusIcon fontSize='19px' color='aquaGreen.500' />
											) : (
												<AddIcon fontSize='19px' color='aquaGreen.500' />
											)}
										</AccordionButton>
									</h2>
									<AccordionPanel
										pt={{base: '1.5rem', md: '2rem'}}
										pb={{base: '1.5rem', md: '4rem'}}
										textStyle={{
											base: 'paragraph1LeftDark2',
											md: 'paragraph1LeftItalicDark',
										}}>
										{accordion.content}
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					))}
				</Accordion>
			</Container>
		</Box>
	);
}

export default Accordions;
