/* eslint @typescript-eslint/no-unsafe-assignment: 1, no-mixed-spaces-and-tabs: 1 */
import {linkResolver} from '../../lib/linkResolver';
import {IntroductionProps} from './IntroductionProps';

export const introductionMapping = (
	data: any,
): IntroductionProps | undefined => {
	if (!data) {
		return null;
	}

	return {
		textComponent1: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.textcomponent1Variant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.textcomponent1Heading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			text: data.textcomponent1Text,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink: data.textcomponent1ButtonLink
				? {
					...data.textcomponent1ButtonLink,
					url: linkResolver(data.textcomponent1ButtonLink.url),
				  }
				: undefined,
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor: data.backgroundColor,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		textAlignment: data.textAlignment,
	};
};
