/** @format */

import {ImageProps} from 'next/image';
import {FeaturedQuoteProps} from './FeaturedQuoteProps';

export const featuredQuoteMapping = (data: {
	backgroundImage: {
		altText: string;
		details: {
			imageHeight: string;
			imageWidth: string;
		};
		image: {
			sourceUrl: string;
		};
	};
	bgImageIsTransparent: string;
	quote: {
		quote: string;
		textColor: string;
	};
	quoteInfo: {
		quoteInfo: string;
		textColor: string;
	};
}): FeaturedQuoteProps => {
	const image: ImageProps = {
		alt: data.backgroundImage?.altText,
		src: data.backgroundImage?.image?.sourceUrl,
		height: data.backgroundImage?.details.imageHeight,
		width: data.backgroundImage?.details.imageWidth,
	};
	// eslint-disable-next-line prefer-destructuring
	const bgImageIsTransparent = data.bgImageIsTransparent;
	const quote = {
		text: data.quote.quote,
		textColor: data.quote.textColor,
	};
	const quoteInfo = {
		text: data.quoteInfo.quoteInfo,
		textColor: data.quoteInfo.textColor,
	};
	return {
		image,
		quote,
		quoteInfo,
		bgImageIsTransparent,
	};
};
