/** @format */

import {
	Box,
	useMultiStyleConfig,
	Text,
	useBreakpointValue,
	Container,
} from '@chakra-ui/react';
import Image from 'next/future/image';
import {CSSProperties} from 'react';
import ButtonLink from '../../components/ButtonLink';
import {ImageTextFullWidthProps} from './imageTextFullWidthProps';

const ImageTextFullWidth = ({image, text}: ImageTextFullWidthProps) => {
	const styles = useMultiStyleConfig('imageAndTextFullWidth');
	const headingStyle = useBreakpointValue(
		{
			base:
				text.textColor === 'dark' ? 'display5LeftDark' : 'display5LeftLight',
			md: text.textColor === 'dark' ? 'display4LeftDark' : 'display4LeftLight',
		},
		{ssr: true},
	);
	const bodyStyle
		= text.textColor === 'dark'
			? 'paragraph1LeftItalicDark'
			: 'paragraph1LeftItalicLight';
	const backgroundImageStyles = styles.backgroundImage as CSSProperties;
	backgroundImageStyles.opacity = image.imageOpacity
		? backgroundImageStyles.opacity
		: 10;

	return (
		<Box __css={styles.backgroundImageContainer}>
			<Image
				alt={image.altText}
				src={image.image.sourceUrl}
				style={backgroundImageStyles}
				fill
			/>
			<Container maxW='container.xl' mx='auto'>
				<Box __css={styles.textBox}>
					<Text textStyle={headingStyle} mb={{base: '1rem', md: '2rem'}}>
						{text.heading}
					</Text>
					<Text textStyle={bodyStyle} mb={{base: '2rem', md: '2rem'}}>
						{text.body}
					</Text>
					{text?.linkButtons && text.linkButtons.length ? (
						<Box mb={{base: '0.5rem', md: '2rem'}}>
							{text?.linkButtons.map(link => (
								<ButtonLink buttonLink={link} key={link.link.url} />
							))}
						</Box>
					) : null}
				</Box>
			</Container>
		</Box>
	);
};

export default ImageTextFullWidth;
