/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
/**
 * eslint @typescript-eslint/naming-convention: 1, no-mixed-spaces-and-tabs: 1, no-negated-condition: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/naming-convention: 1, no-mixed-spaces-and-tabs: 1, no-negated-condition: 1
 *
 * @format
 */

import {
	Box,
	Button,
	chakra,
	Container,
	Flex,
	HStack,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import LinkButton from '../../../components/LinkButton';
import ModalNewsLetter from '../../ModalNewsLetter';
import {CTABannerProps} from '../CTABannerProps';

const CentreAlignedCTABanner = ({
	variant,
	heading,
	isFullBleed,
	text,
	includeBackgroundImage,
	backgroundImage,
	backgroundColor,
	textColor,
	ctas,
}: CTABannerProps) => {
	const {isOpen, onOpen, onClose} = useDisclosure();

	const color = textColor && textColor === 'light' ? 'Light' : 'Dark';
	const view = useBreakpointValue({base: 'mobile', md: 'desktop'}, {ssr: true});
	const border = isFullBleed ? 'none' : 'card';
	const overlay
		= textColor === 'light'
			? 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))'
			: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0))';
	const bgImage
		= includeBackgroundImage && backgroundImage?.src
			? {
				backgroundImage: `${overlay}, url(${backgroundImage.src})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			  }
			: null;

	const content = (
		<Flex
			direction='column'
			sx={{'> *:last-child': {mb: '0'}}}
			alignItems={view === 'mobile' ? 'center' : 'center'}
			textAlign={view === 'mobile' ? 'center' : 'center'}
			maxW='60rem'
			width='100%'>
			<Heading heading={heading} color={color} />
			{text && (
				<Box
					textStyle={{
						base: `paragraph1LeftItalic${color}`,
					}}
					mb={view === 'mobile' ? '2.125rem' : '4'}
					whiteSpace='pre-line'>
					{text}
				</Box>
			)}
			{ctas
				&& ctas.length > 0
				&& (view === 'desktop' && ctas.length < 3 ? (
					<HStack spacing='4' flexShrink={0}>
						<CTAs
							ctas={ctas}
							onOpen={onOpen}
							isOpen={isOpen}
							onClose={onClose}
						/>
					</HStack>
				) : (
					<VStack spacing='4' width='100%'>
						<CTAs
							ctas={ctas}
							onOpen={onOpen}
							isOpen={isOpen}
							onClose={onClose}
						/>
					</VStack>
				))}
		</Flex>
	);

	return (
		<>
			{!isFullBleed ? (
				<Box as='section' overflow='hidden'>
					<Container maxW='container.xl' mx='auto' px={{sm: '0', md: '16px'}}>
						<Flex
							layerStyle={border}
							borderRadius={{base: 'none', md: '5px'}}
							paddingTop={{base: '2rem', md: '2.25rem'}}
							paddingBottom={{base: '1.5rem', md: '2.25rem'}}
							px={{base: '1rem', md: '3.5rem'}}
							flexWrap={'wrap'}
							direction='column'
							sx={{'> *:last-child': {mb: '0'}}}
							alignItems={view === 'mobile' ? 'flex-start' : 'center'}
							textAlign={view === 'mobile' ? 'left' : 'center'}
							backgroundColor={backgroundColor || 'colors.arctic'}
							{...bgImage}>
							{content}
						</Flex>
					</Container>
				</Box>
			) : (
				<Flex
					layerStyle={border}
					paddingTop={{base: '2rem', md: '2.25rem'}}
					paddingBottom={{base: '1.5rem', md: '2.25rem'}}
					px={{base: '1rem', md: '3.5rem'}}
					flexWrap={'wrap'}
					direction='column'
					sx={{'> *:last-child': {mb: '0'}}}
					alignItems={view === 'mobile' ? 'flex-start' : 'center'}
					textAlign={view === 'mobile' ? 'left' : 'center'}
					backgroundColor={backgroundColor || 'arctic'}
					{...bgImage}>
					{content}
				</Flex>
			)}
		</>
	);
};

export default CentreAlignedCTABanner;

export const CTAs = ({ctas, isOpen, onOpen, onClose}) =>
	ctas.map((cta, i) => (
		<Box key={`cta-${i}`} width='100%'>
			{cta.isLink ? (
				<LinkButton
					href={cta.url}
					text={cta.title}
					variant={cta.buttonType}
					// variant={
					//   i === 0 || textColor === "light" ? "primary" : "secondary"
					// }
					width='100%'
					justifyContent='center'
					flexShrink={0}
				/>
			) : (
				<>
					<Button
						variant={cta.buttonType}
						width='100%'
						justifyContent='center'
						flexShrink={0}
						onClick={() => onOpen()}>
						{cta.signUpNewsletterText}
					</Button>
					<ModalNewsLetter onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
				</>
			)}
		</Box>
	));

export const Heading = ({heading, color}) => {
	if (heading) {
		return (
			<chakra.h2
				textStyle={{
					base: `display6Left${color}`,
					// md: `display5Left${color}`,
				}}
				mb={{base: '1.5rem', md: '0.5rem'}}
				whiteSpace='pre-line'>
				{heading}
			</chakra.h2>
		);
	}

	return null;
};
