/** @format */

import {ImageTextFullWidthProps} from './imageTextFullWidthProps';

export const imageTextFullWidthMapping = (data: {
	Text: {
		body: string;
		heading: string;
		textColor: string;
		linkButtons: Array<{
			buttonVariant: string;
			link: {
				target: string;
				title: string;
				url: string;
			};
		}>;
	};
	backgroundImage: {
		image: {
			sourceUrl: string;
		};
		altText: string;
		imageOpacity: string;
	};
}): ImageTextFullWidthProps => ({
	text: data.Text,
	image: data.backgroundImage,
});
