/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/indent: 1
 *
 * @format
 */
/* eslint @typescript-eslint/indent: 1 */
/* eslint @typescript-eslint/no-unsafe-assignment: 1 */

import {linkResolver} from '../../lib/linkResolver';
import {TitleTextBannerProps} from './TitleTextBannerProps';

export const titleTextBannerMapping = (
    data: any,
): TitleTextBannerProps | undefined => {
    if (!data) {
        return null;
    }

    const titleTextBannerData = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        heading: data.pageTitle,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        includeBackLink: data.includeBackButton,
        backLink: data.backButtonLink
            ? {
                url: linkResolver(data.backButtonLink.url),
			  }
            : undefined,
        includePublishedDate: data.includePublishedDate,
        publishedDate: data.publishedDate,
    };

    return titleTextBannerData;
};
