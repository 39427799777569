/** @format */

import {CtqChecklistProps} from './CtqChecklistProps';

export default (data: CtqChecklistProps) => {
	if (!data) {
		return null;
	}

	const {backgroundColor, heading, checklists}: CtqChecklistProps = data;

	const checklistsArr = checklists.map((item: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const txt: string = item.checklistText || '';
		return txt.trim();
	});

	return {backgroundColor, heading: heading.trim(), checklists: checklistsArr};
};
