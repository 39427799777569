/* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1 */
/** @format */

import {imageMapping} from '../Image/imageMapping';
import {LogoGridProps} from './LogoGridProps';

export const logoGridMapping = (data: any): LogoGridProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const imagesLinks
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		= data.imageslinks?.map(imageObj => imageMapping(imageObj, true).image) || [];

	const mappedData = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor: data.backgroundColor || '',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		imagesLinks,
	};

	return mappedData;
};
