/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import {SpacerProps} from './SpacerProps';

export const spacerMapping = (data: any): SpacerProps | undefined => {
	if (!data) {
		return null;
	}

	return {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		size: data.size,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor: data.backgroundColor,
	};
};
