/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/* eslint @typescript-eslint/naming-convention: 1 */
import {Container, Grid} from '@chakra-ui/react';
import React from 'react';
import {WYSIWYG as WYSIWYGComponent} from '../../components/WYSIWYG';
import {WYSIWYGProps} from './WYSIWYGProps';

const WYSIWYG = ({html}: WYSIWYGProps) => (
	<Container maxW='container.xl' mx='auto'>
		<Grid gridTemplateColumns={{base: '1fr', md: '3fr 1fr'}}>
			{ }
			<WYSIWYGComponent html={html} />
		</Grid>{' '}
	</Container>
);

export default WYSIWYG;
