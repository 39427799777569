/** @format */

import {ImageProps} from 'next/image';
import {
	Box,
	SimpleGrid,
	useStyleConfig,
	Text,
	LinkOverlay,
	useBreakpointValue,
	Heading,
	Container,
	Flex,
	SkeletonText,
	SystemStyleObject,
} from '@chakra-ui/react';
import {Card} from '../../components/Card/Card';
import LinkButton from '../../components/LinkButton';
import Link from 'next/link';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {textStyles} from '../../styles/js/textStyles';
import ChakraNextImage from '../../components/ChakraWrappedImage';
import {useQuery} from '@apollo/client';
import {queryLatestNewsPosts, NewsPostData} from './queryLatestNewsPosts';
import {SkeletonLatestNews} from './skeleton';
import {postCard} from '../../styles/js/card';
import {colors} from '../../styles/js/colors';
import Placeholder from '../../../public/images/placeholder.png';

const LatestNews = () => {
	const cardStyle = postCard;
	const headingStyle = useBreakpointValue(
		{
			base: textStyles.display8LeftDark,
			md: textStyles.display7LeftDark,
		},
		{ssr: true},
	);

	const {loading, error, data} = useQuery<NewsPostData>(queryLatestNewsPosts);

	if (loading) {
		return <SkeletonLatestNews />;
	}

	if (error) {
		console.log(error);
	}

	// if (!data) {
	// 	return null;
	// }

	return (
		<Container maxW='container.xl' mx='auto'>
			<SimpleGrid columns={{base: 1, md: 2}} spacing={4} h='100%'>
				{data.posts.nodes.map(post => {
					const imageProps: ImageProps = {
						src: post.card.cardviewFeaturedImage?.sourceUrl || post.featuredImage?.node.sourceUrl || Placeholder.src,
						alt: post.card.cardviewFeaturedImage?.altText || post.featuredImage?.node.altText || 'Placeholder',
					};
					const formattedDate = new Date(post.date).toLocaleDateString('en-GB', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					});
					return (
						<Card sx={cardStyle} key={post.id} minH={'17rem'}>
							<SimpleGrid columns={2} spacing={8} h='100%'>
								<Box position='relative'>
									<ChakraNextImage image={imageProps} maxW='35rem' />
								</Box>
								<Flex direction='column' justifyContent='space-evenly' m={4}>
									{/* <Badge text={post.categories.edges[0].node.name} /> */}
									<Flex m={2}>
										<Badge
											text={formattedDate}
										/>
									</Flex>
									<Text sx={headingStyle} mb='1rem' minH='4rem'>
										{`${post.card.cardviewHeading || post.title}`}
									</Text>
									<Link href={post.uri} passHref>
										<LinkButton
											as={LinkOverlay}
											text={post.card.cardviewLinkText || 'Read more'}
											isExternal={false}
											variant='textLink'
											color='AquaGreen'
											rightIcon={
												<ChevronRightIcon
													w={5}
													h={5}
													color={colors.aquaGreen[500]}
												/>
											}
											sx={{
												// eslint-disable-next-line @typescript-eslint/naming-convention
												'&::before': {
													bgColor: 'transparent',
													opacity: 0,
													height: 'auto',
												},
												// eslint-disable-next-line @typescript-eslint/naming-convention
												'&:hover::before': {
													opacity: 1,
												},
											}}
										/>
									</Link>
								</Flex>
							</SimpleGrid>
						</Card>
					);
				})}
			</SimpleGrid>
		</Container>
	);
};

export default LatestNews;

export const Badge = ({
	text,
}: {
	text?: string;
}) => {
	const badgeTextStyle = useBreakpointValue(
		{
			base: textStyles.display9LeftLight,
			md: textStyles.paragraph2LeftLight,
		},
		{ssr: true},
	);
	return (
		<Box
			sx={badgeTextStyle}
			bgColor='lime.600'
			px={{
				base: '4',
				md: '6',
			}}
			py='0.5'
			borderRadius='md'
			textAlign='center'>
			{text ? (
				<Text noOfLines={2}>{text}</Text>
			) : (
				<SkeletonText noOfLines={1} skeletonHeight='6' />
			)}
		</Box>
	);
};
