/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import {linkResolver} from '../../lib/linkResolver';
import {SplitImageTextProps} from './splitImageTextProps';

export const splitImageTextMapping = (
	data: any,
): SplitImageTextProps | undefined => {
	if (!data) {
		return null;
	}

	const image = data.image
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.image.sourceUrl,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.image.altText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: data.image.mediaDetails.width,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.image.mediaDetails.height,
		}
		: undefined;

	return {
		textComponent1: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.textcomponent1Variant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.textcomponent1Heading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			text: data.textcomponent1Text,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			label: data.textcomponent1Label,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink: data.textcomponent1ButtonLink
				? {
					...data.textcomponent1ButtonLink,
					url: linkResolver(data.textcomponent1ButtonLink.url),
				}
				: undefined,
		},
		image,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		textAlignment: data.textAlignment,
	};
};
