/**
 * eslint @typescript-eslint/no-base-to-string: 1, @typescript-eslint/restrict-template-expressions: 1
 *
 * @format
 */

import {
	Box,
	ChakraProps,
	HStack,
	Text,
	useStyleConfig,
	useMultiStyleConfig,
	useBreakpointValue,
	VStack,
	SystemStyleObject,
	Center,
	Container,
	Flex,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, {ReactElement} from 'react';
import {ButtonLinks, HeroBannerHomeProps} from './HeroBannerHomeProps';
import {colors} from '../../../styles/js/colors';
import {Card} from '../../../components/Card/Card';
import {textStyles} from '../../../styles/js/textStyles';

const LinkButtons = ({
	buttonLinks,
	styles,
}: {
	buttonLinks: ButtonLinks;
	styles: {
		buttonSize: string;
	};
}) => (
	<>
		{buttonLinks?.map(buttonLink => {
			const buttonStyle = useStyleConfig('Button', {
				variant: buttonLink.buttonVariant,
				size: styles.buttonSize,
			});
			return (
				<Center sx={buttonStyle} key={buttonLink.link.url} role='link'>
					<Link key={buttonLink.link.url} href={buttonLink.link.url} passHref>
						{buttonLink.link.title}
					</Link>
				</Center>
			);
		})}
	</>
);

const MobileButtons = ({
	buttonLinks,
	styles,
}: {
	buttonLinks: ButtonLinks;
	styles: {
		buttonSize: string;
	};
}) => (
	<VStack>
		<LinkButtons buttonLinks={buttonLinks} styles={styles} />
	</VStack>
);

const DesktopButtons = ({
	buttonLinks,
	styles,
}: {
	buttonLinks: ButtonLinks;
	styles: {
		buttonSize: string;
	};
}) => (
	<HStack>
		<LinkButtons buttonLinks={buttonLinks} styles={styles} />
	</HStack>
);

const HomeHeroBanner = ({
	cardHeading,
	cardText,
	buttonLinks,
	backgroundColor,
	image,
}: HeroBannerHomeProps) => {
	const {box, headingContainer, textContainer, card}
		= useMultiStyleConfig('homeHeroBanner');

	// eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
	const bgImage = image.src ? `url(${image.src})` : undefined;
	const bgColor = backgroundColor || colors.patternBaseBg;
	const bgPosition = 'center';
	const boxProps: ChakraProps = {
		bgImage,
		bgColor,
		bgPosition,
	};

	const headingStyle = useBreakpointValue(
		{
			base: textStyles.display5LeftDark,
			md: textStyles.display4LeftDark,
		},
		{ssr: true},
	);

	const textStyle = useBreakpointValue(
		{
			base: textStyles.paragraph2LeftItalicDark,
			md: textStyles.paragraph1LeftItalicDark,
		},
		{ssr: true},
	);

	const buttonSize = useBreakpointValue(
		{
			base: 'fullWidth',
			md: '',
		},
		{ssr: true},
	);

	const ButtonStack = useBreakpointValue(
		{
			base: <MobileButtons buttonLinks={buttonLinks} styles={{buttonSize}} />,
			md: <DesktopButtons buttonLinks={buttonLinks} styles={{buttonSize}} />,
		},
		{ssr: true},
	);

	const children: ReactElement = (
		<Box>
			<Box sx={headingContainer}>
				<Text sx={headingStyle}>{cardHeading}</Text>
			</Box>
			<Box sx={textContainer}>
				<Text sx={textStyle} noOfLines={6}>
					{cardText}
				</Text>
			</Box>
			{ButtonStack ? ButtonStack : null}
		</Box>
	);

	return (
		<Box {...boxProps} sx={box}>
			<Container maxW='container.xl' mx='auto'>
				<Flex
					pt={{base: '0rem', md: '6rem'}}
					pb={{base: '0rem', md: '6rem'}}
					minH={{base: '474px'}}>
					<Card sx={card} className='homehero-card'>
						{children}
					</Card>
				</Flex>
			</Container>
		</Box>
	);
};

export default HomeHeroBanner;
