/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import {linkResolver} from '../../lib/linkResolver';
import {FullImageTextProps} from './FullImageTextProps';

export const fullImageTextMapping = (data: any): FullImageTextProps | undefined => {
	if (!data) {
		return null;
	}

	const image = data.backgroundImage
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.backgroundImage.altText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.backgroundImage.sourceUrl,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.backgroundImage.mediaDetails.height,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: data.backgroundImage.mediaDetails.width,
		}
		: undefined;

	const fullImageTextData: FullImageTextProps = {
		image,
		textComponent1: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.textcomponent1Variant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.textcomponent1Heading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			text: data.textcomponent1Text,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			label: data.textcomponent1Label,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink: data.textcomponent1ButtonLink
				? {
					...data.textcomponent1ButtonLink,
					url: linkResolver(data.textcomponent1ButtonLink.url),
				}
				: undefined,
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		includeImageOverlay: data.includeImageOverlay,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		alignment: data.textAlignment,
	};

	return fullImageTextData;
};
