/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import React, {ReactElement} from 'react';
import {TitleTextBannerProps} from './TitleTextBannerProps';
import BackLink from '../../components/BackLink';
import TitleText from '../../components/TitleText';
import {Box, Container} from '@chakra-ui/react';
import Date from '../../components/Date';

function TitleTextBanner({
	backgroundColor,
	variant,
	heading,
	includeBackLink,
	backLink,
	includePublishedDate,
	publishedDate,
}: TitleTextBannerProps): ReactElement {
	if (!heading) {
		return null;
	}
	// console.log('includePublishedDate: ', includePublishedDate);

	return (
		<Box backgroundColor={backgroundColor || 'arctic'}>
			<Container maxW='container.xl' mx='auto' px='0' overflow='auto'>
				<Container maxW='container.md' ml='0' mr='auto' pt='10' overflow='auto'>
					{includeBackLink ? (
						<Box mb={{base: '6', md: '8'}}>
							<BackLink backLink={{url: backLink.url}} variant='dark' />
						</Box>
					) : null}
					{includePublishedDate ? (
						<Box mb={{base: '6', md: '8'}}>
							<Date />
						</Box>
					) : null}
					<Box mb={{base: '6', md: '12'}}>
						<TitleText
							/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
							heading={heading}
							variant={variant || 'dark'}></TitleText>
					</Box>
				</Container>
			</Container>
		</Box>
	);
}

export default TitleTextBanner;
