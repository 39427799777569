import {Box, Link, useBreakpointValue, useStyleConfig, Text} from '@chakra-ui/react';
import {ButtonLinkProps} from './ButtonLinkProps';

const ButtonLink = ({buttonLink}: ButtonLinkProps): React.ReactElement => {
	const {buttonVariant, link} = buttonLink;
	const {url, title} = link;
	const buttonSize = useBreakpointValue({
		base: 'fullWidth',
		md: 'md',
		lg: 'hero',
	}, {ssr: true});
	const buttonStyle = useStyleConfig('Button', {variant: buttonVariant, size: buttonSize});

	return (
		<Link href={url} sx={buttonStyle}>
			<Text>
				{title}
			</Text>
		</Link>
	);
};

export default ButtonLink;
