/* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/prefer-optional-chain: 1 */
/** @format */

import {ButtonGridProps} from './buttonGridProps';
import {linkResolver} from '../../lib/linkResolver';

export const buttonGridMapping = (data: any): ButtonGridProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {variant, backgroundColor, buttons = [], textcomponent1 = []} = data;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const textComponent = textcomponent1?.find(
		item => item.fieldGroupName === 'textComponent1',
	);

	let buttonLink = null;
	if (
		textComponent
		&& textComponent.textcomponent1ButtonLink
		&& textComponent.textcomponent1ButtonLink.url
	) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		buttonLink = {
			...textComponent.textcomponent1ButtonLink,
			url: linkResolver(textComponent.textcomponent1ButtonLink?.url),
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const btns
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		= buttons?.map(item => ({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			title: item.link.title,
			url: linkResolver(item.link.url),
			isExternal: item.link.target === '_blank',
		})) || [];

	return {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		variant,
		textComponent: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: textComponent?.textcomponent1Variant || undefined,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: textComponent?.textcomponent1Heading || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			text: textComponent?.textcomponent1Text || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			label: textComponent?.textcomponent1Label || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink,
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		buttons: variant === 'forum' ? [] : btns,
	};
};
