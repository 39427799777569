/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import {linkResolver} from '../../lib/linkResolver';
import {ImageProps} from './ImageProps';

export const imageMapping = (
	data: any,
	getLinks?: boolean,
): ImageProps | undefined => {
	if (!data) {
		return null;
	}

	let imageLink = null;

	const image = data.image
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: data.image?.mediaDetails.width,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.image?.mediaDetails.height,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.image?.altText || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.image?.sourceUrl,
		  }
		: undefined;

	if (getLinks) {
		imageLink = {
			image,
		};

		if (data.link) {
			imageLink.link = {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				title: data.link.title || '',
				url: linkResolver(data.link.url),
				isExternal: Boolean(data?.link?.target === '_blank'),
			};
		}
	}

	const imageData = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		image: imageLink ? imageLink : image,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		displayFullBleed: data.displayFullBleed || undefined,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		displayFullWidth: data.displayFullWidth || undefined,
	};

	return imageData;
};
