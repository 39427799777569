/* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1 */
import {PostGridProps} from './PostGridProps';

export const postGridMapping = (data: any): PostGridProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const taxonomies = data.filters.map((filter: any) => ({
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		allLabel: filter.allLabel,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		label: filter.label,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		taxonomy: filter.filter,
	}));

	const postGridData = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		columns: data.columns,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		pageSize: data.pageSize,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		postType: data.postType,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		taxonomiesArray: taxonomies,
		containerProps: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			backgroundColor: data.backgroundColor,
		},
	};
	return postGridData;
};
