/** @format */

import {ImageAndTextProps} from './imageAndTextProps';

export const imageAndTextMapping = (data: {
	backgroundColor: string;
	buttonLinks: [
		{
			buttonVariant: string;
			link: {
				target: string;
				title: string;
				url: string;
			};
		},
	];
	image?: {
		image: {
			id: string;
			sourceUrl: string;
		};
		imageAlignment: string;
	};
	textElements: {
		body: string;
		heading: any;
	};
}): ImageAndTextProps => {
	const {textElements, backgroundColor} = data;

	const image = {
		src: data?.image?.image?.sourceUrl,
		alignment: data?.image?.imageAlignment,
	};

	let buttonLinks = null;
	if (data.buttonLinks && data.buttonLinks.length > 0) {
		buttonLinks = data.buttonLinks.map(buttonLink => ({
			variant: buttonLink.buttonVariant,
			target: buttonLink.link.target,
			title: buttonLink.link.title,
			url: buttonLink.link.url,
		}));
	}

	return {
		backgroundColor,
		image,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		buttonLinks,
		text: textElements,
	};
};
