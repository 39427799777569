/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import {BoardMembersListProps} from './boardMembersListProps';

export const boardMembersListMapping = (
	data: any,
): BoardMembersListProps | undefined => {
	if (!data) {
		return null;
	}

	const boardMembersListData = {
		/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
		heading: data.heading,
		text: data.text,
		people: data.people,
	};

	return boardMembersListData;
};
