/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {ReactElement, useEffect, useState} from 'react';
import {Box, Container, Grid} from '@chakra-ui/react';
import TextComponent1 from '../../components/TextComponent/TextComponent1';
import Card from '../../components/Card';
import {RelatedGridProps} from './RelatedGridProps';
import axios from 'axios';
import CardEmpty from '../../components/Card/CardEmpty';

function RelatedGrid({
	posts,
	columns = 2,
	textComponent1,
	containerProps,
	cardStyle = 'ROW_COLUMN',
	defaultPosts,
}: RelatedGridProps): ReactElement {
	const [relatedPosts, setRelatedPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const loadingPosts = [...new Array(columns)];
	const [isFeatured, setIsFeatured] = useState(false);

	useEffect(() => {
		if (posts) {
			setRelatedPosts(posts);
			setLoading(false);
			setIsFeatured(posts.length === 1);
		} else {
			setLoading(true);
			const fetchLatestPosts = async () => {
				const res = await axios.get(
					`/api/taxonomy-types/${defaultPosts.taxonomy}`,
				);
				const termsRes = res.data.terms;
				const terms = termsRes.map(term => term.name);
				const response = await axios.post(
					`/api/post-types/${defaultPosts.postType}/search`,
					{
						taxQueryArray: [
							{
								terms: [...terms],
								taxonomySingle: defaultPosts.taxonomySingleENUM,
							},
						],
						after: '',
						before: '',
						first: 2,
						last: null,
					},
				);
				return response.data.posts;
			};

			fetchLatestPosts()
				.then(posts => {
					setRelatedPosts(posts);
					setLoading(false);
				})
				.catch(error => {
					console.error('Error response RelatedGrid:');
					console.error(error);
				});
		}
	}, []);

	return (
		<Box
			backgroundColor={containerProps.backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			{...containerProps}
		>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{base: '1fr', md: '8fr 2fr'}}
					columnGap='8'
					mb={
						textComponent1.heading || textComponent1.text
							? {base: '10', md: '14'}
							: null
					}
				>
					<TextComponent1
						containerProps={{
							textAlign: 'left',
							alignItems: 'flex-start',
						}}
						heading={textComponent1.heading}
						text={textComponent1.text}
						variant={textComponent1.variant}
					></TextComponent1>
				</Grid>
				<Grid
					templateColumns={
						isFeatured
							? {
								base: '1fr',
							}
							: cardStyle === 'ROW' || cardStyle === 'ROW_COLUMN'
								? {
									base: '1fr',
									md: `repeat(${columns}, 1fr)`,
								}
								: {
									base: '1fr',
									sm: '1fr 1fr',
									md: `repeat(${columns}, 1fr)`,
								}
					}
					columnGap='8'
					rowGap='4'
					marginX={
						isFeatured || cardStyle === 'COLUMN' || cardStyle === 'COLUMN_ROW'
							? 0
							: {base: '-1rem', md: 0}
					}
				>
					{loading ? (
						isFeatured ? (
							<CardEmpty
								key={'loading-featured'}
								cardStyle={cardStyle}
								isFeatured={isFeatured}
							/>
						) : (
							loadingPosts.map((item, index) => (
								<CardEmpty
									key={`loading-${index}`}
									cardStyle={cardStyle}
									isFeatured={isFeatured}
								/>
							))
						)
					) : (
						relatedPosts.map((post: any, index: number) => {
							const cardProps
                = relatedPosts.length === 1
                	? {
                		image: {
                			alt: post.card.cardviewFeaturedImage.altText,
                			src: post.card.cardviewFeaturedImage.sourceUrl,
                			height:
post.card.cardviewFeaturedImage.mediaDetails.height,
                			width:
post.card.cardviewFeaturedImage.mediaDetails.width,
                		},
                		heading: post.card.cardviewHeading,
                		excerpt: post.card.cardviewExcerpt,
                		linkText: post.card.cardviewLinkText,
                	}
                	: {
                		image: {
                			alt: post.card.cardviewFeaturedImage.altText,
                			src: post.card.cardviewFeaturedImage.sourceUrl,
                			height:
post.card.cardviewFeaturedImage.mediaDetails.height,
                			width:
post.card.cardviewFeaturedImage.mediaDetails.width,
                		},
                		heading: post.card.cardviewHeading,
                		linkText: post.card.cardviewLinkText,
                	};
							return (
								<Card
									card={cardProps}
									uri={post.uri}
									terms={post.terms}
									key={`${post.slug}-${index}`}
									cardStyle={cardStyle}
									isFeatured={isFeatured}
									customLayerStyle={
										containerProps.backgroundColor === 'arcticScale.100'
											? 'cardLight'
											: 'card'
									}
									containerProps={containerProps}
								/>
							);
						})
					)}
				</Grid>
			</Container>
		</Box>
	);
}

export default RelatedGrid;
