/**
 * eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/restrict-template-expressions: 1, @typescript-eslint/no-unsafe-assignment: 1, react/prop-types: 1
 *
 * @format
 */

import React, {ReactElement} from 'react';
import {
	Box,
	Text,
	LinkBox,
	Flex,
	GridItem,
	Container,
	Grid,
	chakra,
} from '@chakra-ui/react';
import {ImageTextColsColumn, ImageTextColsProps} from './ImageTextColsProps';
import CardText from '../../components/Card/CardText';
import Image from 'next/image';

function ImageTextCols({
	mainTitle,
	variant,
	backgroundColor,
	columns,
}: ImageTextColsProps): ReactElement {
	return (
		<Box
			className='image-text-cols'
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			py={{base: '10', md: '12'}}>
			<Container maxW='container.xl' mx='auto'>
				{mainTitle ? (
					<Text
						mb={'3rem'}
						textStyle={{base: 'display5LeftDark', md: 'display4LeftDark'}}
						as='div'>
						{mainTitle}
					</Text>
				) : null}

				<Grid
					templateColumns={{
						base: '1fr',
						sm: '1fr',
						md: 'repeat(auto-fit, minmax(250px, 1fr))',
					}}
					columnGap='6'
					rowGap={{base: '2.5rem', md: '2.5rem'}}>
					{columns.map((columnItem: ImageTextColsColumn, index: number) => (
						<GridItem key={`columnItem-title-${index}`} flexShrink='0' w='100%'>
							{variant === 'icon' ? (
								<CardIcon
									col={columnItem}
									cardContentPaddingX='0'
									cardContentPaddingY={{base: '4', md: '4'}}
								/>
							) : (
								<CardText
									variant={variant}
									card={{
										image: {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
											alt: columnItem.image.altText,
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
											src: columnItem.image.sourceUrl,
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
											height: columnItem.image.height,
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
											width: columnItem.image.width,
										},

										heading: columnItem.title,

										headingColour: columnItem.titleTextColour,
										subTitle: columnItem.subTitle,

										excerpt: columnItem.description,

										contactNumber:
											variant === 'bio' ? columnItem.contactNumber : '',

										emailAddress:
											variant === 'bio' ? columnItem.emailAddress : '',

										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										linkText: columnItem.link.linkTitle,
									}}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									uri={columnItem.link.url || ''}

									containerProps={{flexDirection: 'column'}}
									cardContentPaddingX='0'
									cardContentPaddingY={{base: '6', md: '6'}}
								/>
							)}
						</GridItem>
					))}
				</Grid>
			</Container>
		</Box>
	);
}

export default ImageTextCols;

// eslint-disable-next-line react/prop-types
const CardIcon = ({col, cardContentPaddingX, cardContentPaddingY}) => (
	<Flex
		direction={'column'}
		alignItems='stretch'
		justifyContent='stretch'
		minHeight={{md: '273px'}}
		as={LinkBox}>
		{/* eslint-disable-next-line react/prop-types */}
		{col.image ? (
			<Box flexGrow={0} position='relative' overflow='hidden'>
				<Image
					/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment */
					src={col.image.sourceUrl}
					width='40px'
					height='40px'
					layout='fixed'
				/>
			</Box>
		) : null}

		<Box // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			paddingY={cardContentPaddingY}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			paddingX={cardContentPaddingX}>
			{/* eslint-disable-next-line react/prop-types */}
			{col.title ? (
				<chakra.h2
					textStyle={{
						base: 'display6LeftDarkGreen',
						md: 'display6LeftDarkGreen',
					}}
					mb={{base: '2', md: '2'}}>
					{/* eslint-disable-next-line react/prop-types */}
					{col.title}
				</chakra.h2>
			) : null}

			{/* eslint-disable-next-line react/prop-types */}
			{col.subTitle ? (
				<Text
					textStyle={{
						base: '',
						md: '',
					}}>
					{/* eslint-disable-next-line react/prop-types */}
					{col.subTitle}
				</Text>
			) : null}

			{/* eslint-disable-next-line react/prop-types */}
			{col.description ? (
				<Text
					// title={col.description}
					noOfLines={[4, 6, 10]}
					textStyle={{
						base: 'paragraph1LeftItalicDark',
						md: 'paragraph1LeftItalicDark',
					}}>
					{/* eslint-disable-next-line react/prop-types */}
					{col.description}
				</Text>
			) : null}
		</Box>
	</Flex>
);
