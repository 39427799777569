/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import React, {ReactElement} from 'react';
import Image from '../../components/Image';
import {Box, Container, Grid} from '@chakra-ui/react';
import TextComponent1 from '../../components/TextComponent/TextComponent1';
import {SplitImageTextProps} from './splitImageTextProps';

function SplitImageText({
	textComponent1,
	image,
	textAlignment,
}: SplitImageTextProps): ReactElement {
	if (!textComponent1) {
		return null;
	}

	const badgeProps = {size: 'lg', label: textComponent1.label};
	return (
		<Box as='section' overflow='hidden'>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{base: '1fr', md: '6fr 6fr'}}
					columnGap='8'
					rowGap={{base: '8', md: '0'}}
					alignItems='center'
				>
					<Box
						order={
							textAlignment === 'left' ? {base: 2, md: 1} : {base: 2, md: 2}
						}
						py={{md: '6'}}
					>
						<TextComponent1
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							heading={textComponent1.heading}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							text={textComponent1.text}
							label={textComponent1.label}
							buttonLink={textComponent1.buttonLink}
							variant={'dark'}
							{...badgeProps}
						></TextComponent1>
					</Box>
					<Box
						order={
							textAlignment === 'left' ? {base: 1, md: 2} : {base: 1, md: 1}
						}
						alignSelf='stretch'
					>
						{image ? (
							<Image
								src={image.src}
								alt={image.alt}
								width={image.width}
								height={image.height}
								layout='responsive'
							/>
						) : null}
					</Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default SplitImageText;
