/* eslint @typescript-eslint/naming-convention: 1 */
import React from 'react';
import {Box} from '@chakra-ui/react';
import {WYSIWYGProps} from './WYSIWYGProps';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function WYSIWYG({html, variant, ...containerProps}: WYSIWYGProps) {
	/* Style class will need a corresponding global style config object in theme.js */
	const style = variant ? `WYSIWYG--${variant}` : 'WYSIWYG--default';
	const WYSIWYG = props => <Box className={`WYSIWYG ${style}`} {...props} />;
	return (
		<>
			<WYSIWYG
				// eslint-disable-next-line @typescript-eslint/naming-convention
				dangerouslySetInnerHTML={{__html: html}}
				{...containerProps}
			></WYSIWYG>
		</>
	);
}
