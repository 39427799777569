/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import React, {ReactElement} from 'react';
import Image from '../../components/Image';
import {Box, Container, Grid} from '@chakra-ui/react';
import TextComponent1 from '../../components/TextComponent/TextComponent1';
import {FullImageTextProps} from './FullImageTextProps';

function FullImageText({
	textComponent1,
	image,
	includeImageOverlay,
	alignment,
}: FullImageTextProps): ReactElement {
	if (!textComponent1) {
		return null;
	}

	const overlay = includeImageOverlay
		? 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))'
		: 'none';

	return (
		<Box
			backgroundColor='white'
			as='section'
			overflow='hidden'
			position='relative'
			pb={{base: '14'}}
			pt={{base: '315px', md: '14'}}
		>
			<Box position='absolute' top='0' left='0' right='0' bottom='0'>
				{image && (
					<Image
						src={image.src}
						alt={image.alt}
						/* Width={backgroundImage.mediaDetails.width}
            height={backgroundImage.mediaDetails.height} */
						layout='fill'
						objectFit='cover'
						objectPosition='center'
					/>
				)}
			</Box>
			{includeImageOverlay && (
				<Box
					position='absolute'
					top='0'
					left='0'
					right='0'
					bottom='0'
					backgroundImage={overlay}
					backgroundSize='cover'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'
				></Box>
			)}
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={
						alignment === 'left'
							? {base: '1fr', md: '7fr 5fr'}
							: {base: '1fr', md: '5fr 7fr'}
					}
					columnGap='8'
					alignItems='center'
				>
					<Box
						order={
							alignment === 'left' ? {base: 2, md: 1} : {base: 2, md: 2}
						}
						py={{md: '6'}}
					>
						<TextComponent1
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							heading={textComponent1.heading}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							text={textComponent1.text}
							label={textComponent1.label}
							buttonLink={textComponent1.buttonLink}
							variant={'light'}
						></TextComponent1>
					</Box>
					<Box
						order={
							alignment === 'left' ? {base: 1, md: 2} : {base: 1, md: 1}
						}
						alignSelf='stretch'
					></Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default FullImageText;
