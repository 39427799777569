/* eslint @typescript-eslint/no-implicit-any-catch: 1 */
export const linkResolver = (link: string) => {
	try {
		if (!process.env.WORDPRESS_API_URL || !isUrlAbsolute(link)) {
			return link;
		}

		const {pathname, host} = new URL(link);

		// if external
		const {host: host2} = new URL(process.env.WORDPRESS_API_URL);
		if (host !== host2) {
			return link;
		}

		return process.env.NEXT_PUBLIC_APP_URL + pathname;
	} catch (e: unknown) {
		console.error(
			'WARNING - LinkResolver - Failed: ',
			link,
			' - Error: ',
			JSON.stringify(e),
		);
		return link;
	}
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const isUrlAbsolute = url => url.indexOf('://') > 0 || url.indexOf('//') === 0;
