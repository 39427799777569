import {Box, BoxProps, forwardRef, SystemStyleObject} from '@chakra-ui/react';
import React, {ReactElement} from 'react';

type Props = {
	style?: SystemStyleObject;
	children: ReactElement;
	rest?: BoxProps;
};

export const Card = forwardRef<BoxProps, 'div'>((props, ref) => {
	const {style, children, ...rest} = props;
	return (
		<Box sx={style} {...rest} ref={ref}>
			{children}
		</Box>
	);
});
