/**
 * /* eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/** @format */

import React, {ReactElement, useEffect, useState} from 'react';
import {
	Box,
	chakra,
	color,
	Container,
	Flex,
	Grid,
	GridItem,
	Link,
	LinkBox,
	LinkOverlay,
	useBreakpointValue,
} from '@chakra-ui/react';
import Image from 'next/image';

import {PartnerCardProps} from './PartnerCardProps';
import {WYSIWYG} from '../../components/WYSIWYG';
import {colors} from '../../styles/js/colors';
import Accordions from '../Accordions';
// import useUser from '../../../lib/useUser';
import {useRouter} from 'next/router';
import axios from 'axios';

function PartnerCard({
	image,
	imageLink,
	socialMediaLinks,
	accordions,
	title,
	description,
	personName,
	jobTitle,
	contactNumber,
	emailAddress,
	websiteAddress,
	activeSubscriptions,
}: PartnerCardProps): ReactElement {
	if (!image) {
		return null;
	}

	const router = useRouter();
	const path = router.asPath;

	return (
		<Box as='section' overflow='hidden' pt={{base: '5rem'}} pb={{base: '3rem'}}>
			<Container maxW='container.xl' mx='auto'>
				<Grid templateColumns={{base: 'repeat(12, 1fr)'}} gap={10}>
					<GridItem colSpan={{base: 12, md: 4}}>
						{imageLink.href ? (
							<LinkBox
								transition='all .2s ease-in-out'
								_hover={{
									// boxShadow: 'dark-lg',
									boxShadow: '0 15px 15px 5px rgb(8 146 95 / 7%)',
									transform: 'translate(0, -2px)',
								}}
								boxShadow={`0 2px 10px 0 ${colors.gneiss[200]}`}
								border={`solid 2px ${colors.slate[200]}`}
								px={{base: '2rem'}}
								py={{base: '2.5rem'}}
								alignItems='center'
								justifyContent='center'
								display='flex'>
								<LinkOverlay
									href={imageLink.href}
									isExternal={Boolean(imageLink?.isExternal)}>
									<Image
										src={image.src}
										alt={image.alt}
										width={image.width}
										height={image.height}
									/>
								</LinkOverlay>
							</LinkBox>
						) : (
							<Image
								src={image.src}
								alt={image.alt}
								width={image.width}
								height={image.height}
							/>
						)}
					</GridItem>
					<GridItem colSpan={{base: 12, md: 8}}>
						{title ? (
							<chakra.h2
								textStyle={{
									base: 'display5LeftDark',
								}}
								mb={{base: '4'}}>
								{title}
							</chakra.h2>
						) : null}
						{description ? (
							<WYSIWYG
								html={description}
								textStyle={{
									base: 'paragraph1LeftItalicDark',
								}}
								pb={{base: '2rem'}}
							/>
						) : null}
						{personName ? (
							<chakra.p
								textStyle={{
									base: 'paragraph1LeftItalicDark',
								}}
								mb={{base: '4'}}>
								{personName}
								{jobTitle ? ` | ${jobTitle}` : null}
							</chakra.p>
						) : null}
						{contactNumber ? (
							<Box mb={{base: '4'}}>
								<chakra.a
									textStyle={{
										base: 'paragraph1LeftItalicDark',
									}}
									mb={{base: '4'}}
									href={`tel:${contactNumber}`}>
									{contactNumber}
								</chakra.a>
							</Box>
						) : null}
						{emailAddress ? (
							<chakra.a
								display={{base: 'block'}}
								textStyle={{
									base: 'paragraph1LeftItalicDark',
								}}
								_hover={{
									textColor: colors.aquaGreen[500],
								}}
								_active={{
									textColor: colors.aquaGreen[700],
								}}
								mb={{base: '4'}}
								target='_blank'
								rel='noopener noreferrer'
								href={`mailto:${emailAddress}?subject=Mail from ASSC Website`}>
								{emailAddress}
							</chakra.a>
						) : null}
						{websiteAddress?.url ? (
							<Box mb={{base: '4'}}>
								<Link
									fontSize={{base: ' 1.25rem '}}
									color={colors.leaf[900]}
									variant={'native'}
									isExternal={Boolean(websiteAddress.isExternal)}
									rel='noopener noreferrer'
									textStyle={{
										base: 'paragraph1LeftItalicDark',
									}}
									_hover={{
										textColor: colors.aquaGreen[500],
									}}
									_active={{
										textColor: colors.aquaGreen[700],
									}}
									href={websiteAddress.url}>
									{websiteAddress.title || websiteAddress.url || ''}
								</Link>
							</Box>
						) : null}
						{socialMediaLinks.length > 0 &&
							socialMediaLinks.map((link, i) => (
								<Box mb={{base: '4'}} key={`${i}-social-media`}>
									<Link
										fontSize={{base: ' 1.25rem '}}
										color={colors.leaf[900]}
										variant={'native'}
										isExternal={Boolean(imageLink?.isExternal)}
										textStyle={{
											base: 'paragraph1LeftItalicDark',
										}}
										_hover={{
											textColor: colors.aquaGreen[500],
										}}
										_active={{
											textColor: colors.aquaGreen[700],
										}}
										href={link.url}>
										{link.title}
									</Link>
								</Box>
							))}
						{path === '/holiday-let-trade-suppliers' ? (
							activeSubscriptions > 0 ? (
								<Accordions
									{...accordions}
									containerProps={{
										px: {base: 0},
									}}
									alignTitleText={{base: 'center', md: 'left'}}
								/>
							) : null
						) : (
							<Accordions
								{...accordions}
								containerProps={{
									px: {base: 0},
								}}
								alignTitleText={{base: 'center', md: 'left'}}
							/>
						)}
					</GridItem>
				</Grid>
			</Container>
		</Box>
	);
}

export default PartnerCard;
