/** @format */

import React, {ReactElement} from 'react';
import LinkButton from '../LinkButton';
import Link from 'next/link';
import {BackLinkProps} from './backLinkProps';
import {ChevronLeftIcon} from '@chakra-ui/icons';

function BackLink({backLink, variant}: BackLinkProps): ReactElement {
	// eslint-disable-next-line no-warning-comments
	/* TODO: Extend this to provide browser back behaviour when flag is set */
	const color = variant && variant === 'light' ? 'arcticScale' : 'abyss';
	return (
		<Link href={backLink.url} passHref>
			<LinkButton
				text='Back'
				variant='link'
				colorScheme={color}
				leftIcon={<ChevronLeftIcon w={5} h={5} />}
			/>
		</Link>
	);
}

export default BackLink;
