import {BoxProps, chakra, forwardRef} from '@chakra-ui/react';
import Image, {ImageProps} from 'next/image';

const ChakraWrappedImage = chakra(Image, {
	shouldForwardProp: prop => ['width', 'height', 'src', 'alt', 'quality', 'placeholder', 'blurDataURL', 'loader', 'layout', 'objectFit', 'children', 'borderRadius'].includes(prop),
});

type Props = {
	image: ImageProps;
	rest?: BoxProps;
	layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
	fit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
	children?: React.ReactNode;
};

const ChakraNextImage = forwardRef<any, 'div'>((props: Props, ref) => {
	const {image, layout, fit, children, ...rest} = props;
	const {src, alt} = image;

	if (layout && layout !== 'fill') {
		return (
			<ChakraWrappedImage
				src={src}
				// eslint-disable-next-line no-negated-condition
				alt={alt !== '' ? alt : 'Image'}
				width='100%'
				height='100%'
				layout={layout}
				objectFit={fit ?? 'contain'}
				{...rest}
			/>
		);
	}

	return (

		<ChakraWrappedImage
			src={src}
			// eslint-disable-next-line no-negated-condition
			alt={alt !== '' ? alt : 'Image'}
			layout={layout ?? 'fill'}
			objectFit={fit ?? 'contain'}
			{...rest}
		/>
	);
});

export default ChakraNextImage;
