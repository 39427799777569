/** @format */

import {Box, Button, Grid, GridItem} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {FeedbackMsgs} from '../../../patterns/CtqChecklist';
import Spacer from '../../../patterns/Spacer';
import {OptionListProps} from '../../FormikField/OptionProps';
import {FormikFieldSelect} from '../../FormikField/Select';

const membershipsArr: OptionListProps = [
	{value: '', text: 'Select your option', disabled: true},
	{value: '1', text: 'ASSC Membership'},
	{value: '2', text: 'ASSC Membership plus Marketing Package'},
	{value: 'agency3', text: 'Agency Membership'},
	{value: 'agency4', text: 'Agency Membership plus Marketing Package'},
	{value: 'trade', text: 'Trade Supplier Membership'},
];

export const SubscriptionForm: React.FC<{
	onSubmit: any;
	success: string;
	error: boolean;
}> = ({onSubmit, success, error}) => {
	const [formErrorMsg, setFormErrorMsg] = useState('');

	useEffect(() => {
		setFormErrorMsg(
			`Your susbscription has been updated to ASSC member. Payment for this will be taken on ${new Intl.DateTimeFormat(
				'en-UK',
				{
					day: 'numeric',
					month: 'numeric',
					year: '2-digit',
				},
			).format(new Date())}`,
		);

		return () => {
			setFormErrorMsg('');
		};
	}, []);

	const initialValues: {subscriptionType: string} = {
		subscriptionType: '',
	};

	const validationSchema = Yup.object().shape({
		subscriptionType: Yup.string().required('Required'),
	});

	return (
		<Grid>
			<Box
				p={{base: '0'}}
				textStyle={{
					base: 'display7LeftDark',
				}}></Box>

			{formErrorMsg ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					{formErrorMsg}
				</Box>
			) : null}

			<Box
				p={{base: '0'}}
				pt={{base: '2.5rem'}}
				textStyle={{
					base: 'display5LeftDark',
				}}>
				Change & Manage Subscription
			</Box>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				onSubmit={onSubmit}>
				{({errors, touched, isSubmitting}) => (
					<Form style={{width: '100%'}}>
						<Grid
							templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
							gap={{base: '0', md: '6'}}>
							<GridItem>
								<FormikFieldSelect
									errors={errors}
									touched={touched}
									label={'Select your signposting type:'}
									nameKey={'subscriptionType'}
									options={membershipsArr}
									required={true}
								/>
								<FeedbackMsgs success={success} error={error} />
								<Spacer />
								<Button
									type='submit'
									disabled={isSubmitting}
									mt={{base: '4rem'}}
									float={{base: 'right'}}
									w={{base: '100%', md: 'auto'}}>
									Update Subscription
								</Button>
							</GridItem>
						</Grid>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};
