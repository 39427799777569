/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
/** @format */

import {
	Box,
	Container,
	Flex,
	HStack,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import {CTAs, Heading} from '../CentreAlignedCTABanner';
import {CTABannerProps} from '../CTABannerProps';

// eslint-disable-next-line @typescript-eslint/naming-convention
const LeftAlignedCTABanner = ({
	variant,
	heading,
	isFullBleed,
	text,
	includeBackgroundImage,
	backgroundImage,
	backgroundColor,
	textColor,
	ctas,
}: CTABannerProps) => {
	const {isOpen, onOpen, onClose} = useDisclosure();
	const color = textColor && textColor === 'light' ? 'Light' : 'Dark';
	const view = useBreakpointValue({base: 'mobile', md: 'desktop'}, {ssr: true});
	const ctaAlignment
		= view === 'mobile' ? 'flex-start' : ctas.length > 1 ? 'flex-end' : 'center';
	const border = isFullBleed ? 'none' : 'card';
	const overlay
		= textColor === 'light'
			? 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))'
			: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0))';
	const bgImage
		= includeBackgroundImage && backgroundImage?.src
			? {
				backgroundImage: `${overlay}, url(${backgroundImage.src})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			  }
			: null;

	const content = (
		<>
			<Flex
				direction='column'
				sx={{'> *:last-child': {mb: '0'}}}
				alignItems='flex-start'
				flex={view === 'mobile' ? '1' : '2'}>
				<Heading heading={heading} color={color} />
				{text && (
					<Box
						textStyle={{
							base: `paragraph1LeftItalic${color}`,
						}}
						mb={{base: '2.125rem'}}
						whiteSpace='pre-line'>
						{text}
					</Box>
				)}
			</Flex>

			{ctas && ctas.length > 0 && (
				<Flex
					flex='1'
					flexShrink={0}
					justifyContent={ctaAlignment}
					pt={view === 'mobile' ? '8' : '0'}
					marginLeft={view === 'mobile' ? '0' : '6'}>
					{view === 'desktop' && ctas.length < 3 ? (
						<HStack spacing='4' flexShrink={0} width='100%'>
							<CTAs
								ctas={ctas}
								onOpen={onOpen}
								isOpen={isOpen}
								onClose={onClose}
							/>
						</HStack>
					) : (
						<VStack spacing='4' width='100%' flexShrink={0}>
							<CTAs
								ctas={ctas}
								onOpen={onOpen}
								isOpen={isOpen}
								onClose={onClose}
							/>
						</VStack>
					)}
				</Flex>
			)}
		</>
	);

	return (
		<>
			{!isFullBleed ? (
				<Box as='section' overflow='hidden'>
					<Container maxW='container.xl' mx='auto' px={{sm: '0', md: '16px'}}>
						<Flex
							layerStyle={border}
							borderRadius={{base: 'none', md: '5px'}}
							paddingTop={{base: '2rem', md: '2.25rem'}}
							paddingBottom={{base: '1.5rem', md: '2.25rem'}}
							px={{base: '1rem', md: '3.5rem'}}
							direction={view === 'mobile' ? 'column' : 'row'}
							alignItems={view === 'mobile' ? 'stretch' : 'center'}
							flexWrap={'wrap'}
							backgroundColor={backgroundColor || 'colors.arctic'}
							{...bgImage}>
							{content}
						</Flex>
					</Container>
				</Box>
			) : (
				<Container maxW='container.xl'>
					<Flex
						paddingTop={{base: '2rem', md: '2.25rem'}}
						paddingBottom={{base: '1.5rem', md: '2.25rem'}}
						px={{base: '1rem', md: '3.5rem'}}
						direction={view === 'mobile' ? 'column' : 'row'}
						alignItems={view === 'mobile' ? 'stretch' : 'center'}
						flexWrap={'wrap'}
						backgroundColor={backgroundColor || 'colors.arctic'}
						{...bgImage}>
						{content}
					</Flex>
				</Container>
			)}
		</>
	);
};

export default LeftAlignedCTABanner;
