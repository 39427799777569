/** @format */

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormErrorMessage,
	Text,
} from '@chakra-ui/react';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import {ReactElement, useEffect, useState} from 'react';
import Spacer from '../Spacer';

import {CtqChecklistProps} from './CtqChecklistProps';

function CtqChecklist({
	backgroundColor,
	heading,
	checklists,
}: CtqChecklistProps): ReactElement {
	const [success, setSuccess] = useState('');
	const [error, setError] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		if (checklists.length) {
			const createdInitialValues = {};
			// eslint-disable-next-line no-return-assign
			checklists.map((checklist, i) => (createdInitialValues[i] = true));
			setInitialValues(createdInitialValues);
		}

		return () => {
			setInitialValues(null);
		};
	}, [checklists]);

	const onSubmit = async values => {
		setError(false);
		const message = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_email: process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_name: '',
			subject: 'CTQ Accepted',
			text: 'User has now accepted the CTQ',
			to: [
				{
					email: process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL,
					type: 'to',
				},
			],
		};
		const response = await axios.post('/api/contact', message);
		if (response.status === 200) {
			setSuccess(
				`Submitted ${new Intl.DateTimeFormat('en-UK', {
					day: 'numeric',
					month: 'numeric',
					year: '2-digit',
				}).format(new Date())}`,
			);
		} else {
			setError(true);
		}
	};

	return (
		<Box
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			py={{base: '3rem'}}>
			<Container maxW='container.xl' mx='auto'>
				<Box>
					<Box
						pb={{base: '2rem'}}
						textStyle={{
							base: 'display5LeftDark',
						}}>
						{heading}
					</Box>
					{initialValues ? (
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						<Formik initialValues={initialValues} onSubmit={onSubmit}>
							{({values, errors, touched, isSubmitting}) => (
								<Form style={{width: '100%'}}>
									{checklists.map((checklist, i) => (
										<Box
											key={`${i}-checklist2`}
											pb={{base: '2rem'}}
											textStyle={{
												base: 'display7LeftDark',
											}}>
											<Field type='checkbox' name={i}>
												{({field, form}) => (
													<FormControl isInvalid={Boolean(errors[i])}>
														<Checkbox
															size='md'
															colorScheme='lime'
															name={i}
															// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
															isChecked={values[i]}
															{...field}>
															<Text
																textStyle={{
																	base: 'display7LeftDark',
																}}>
																{checklist}
															</Text>
														</Checkbox>
														<FormErrorMessage>{errors[i]}</FormErrorMessage>
														<FormErrorMessage>{errors}</FormErrorMessage>
													</FormControl>
												)}
											</Field>
										</Box>
									))}

									<FeedbackMsgs success={success} error={error} />
									<Button
										variant='primary'
										type='submit'
										disabled={
											isSubmitting
											|| !Object.values(values).every(value => value === true)
										}
										mt={{base: '4rem'}}
										w={{base: '100%', md: 'auto'}}>
										Submit to ASSC
									</Button>
								</Form>
							)}
						</Formik>
					) : null}
				</Box>
			</Container>
		</Box>
	);
}

export default CtqChecklist;

// eslint-disable-next-line react/prop-types
export const FeedbackMsgs = ({success = '', error}) => {
	if (typeof success !== 'string' || !success.trim()) {
		success = null;
	}

	if (typeof error === 'undefined') {
		error = null;
	}

	if (!success && !error) {
		return null;
	}

	return (
		<Box pb={{base: '1.5rem'}}>
			{success ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display6LeftDark',
					}}>
					{success}
				</Box>
			) : null}
			{error ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display6LeftDark',
					}}>
					Oops, something went wrong. Please try again later...
				</Box>
			) : null}
		</Box>
	);
};
