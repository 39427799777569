/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import React, {ReactElement} from 'react';
import Image from '../../../components/Image';
import {Box, Container, Grid} from '@chakra-ui/react';
import HeroText from '../HeroText';
import {HeroBannerPhotographicProps} from './HeroBannerPhotographicProps';

function HeroBannerPhotographic({
	image,
	backgroundColor,
	hasBackground,
	backgroundHeight,
	overlayTextAlignment,
	heroText,
}: HeroBannerPhotographicProps): ReactElement {
	if (!heroText || !backgroundHeight) {
		return null;
	}

	const badgeProps = {size: 'lg', label: heroText?.badge?.label};
	const overlay = hasBackground
		? 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))'
		: 'none';

	return (
		<Box

			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			position='relative'
		>
			<Box position='absolute' top='0' left='0' right='0' bottom='0'>
				{image && (
					<Image
						src={image.src}
						alt={image.alt}
						/* Width={backgroundImage.mediaDetails.width}
            height={backgroundImage.mediaDetails.height} */
						layout='fill'
						objectFit='cover'
						objectPosition='center'
					/>
				)}
			</Box>
			{hasBackground && (
				<Box
					position='absolute'
					top='0'
					left='0'
					right='0'
					bottom='0'
					backgroundImage={overlay}
					backgroundSize='cover'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'
				></Box>
			)}
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{base: '1fr', md: '10fr 2fr'}}
					columnGap='8'
					rowGap={{base: 8, md: '0'}}
					minHeight={
						backgroundHeight === 'short'
							? {base: '520px', md: '536px'}
							: backgroundHeight === 'tall'
								? {base: '664px', md: '776px'}
								: {base: '302px', md: '360px'}
					}
				>
					<Box
						alignSelf={
							overlayTextAlignment === 'bottom'
								? 'flex-end'
								: overlayTextAlignment === 'center'
									? 'center'
									: 'flex-start'
						}
						paddingTop={
							backgroundHeight === 'short'
								? {base: '40', md: '104px'}
								: backgroundHeight === 'tall'
									? {base: '80px', md: '176px'}
									: {base: '64px', md: '80px'}
						}
						paddingBottom={
							backgroundHeight === 'short'
								? {base: '10'}
								: backgroundHeight === 'tall'
									? {base: '40px', md: '72px'}
									: {base: '24px', md: '40px'}
						}
					>
						<HeroText
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							heading={heroText.heading}
							supportingText={heroText.supportingText}
							buttonLink={heroText.buttonLink}
							variant={overlay !== 'none' || image ? 'light' : 'dark'}
							badge={badgeProps}
						></HeroText>
					</Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default HeroBannerPhotographic;
