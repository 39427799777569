/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {linkResolver} from '../../lib/linkResolver';
import {RelatedGridProps} from './RelatedGridProps';

export const relatedGridMapping = (data: {
	posts: any;
	textcomponent1Variant: string;
	textcomponent1Overline: string;
	textcomponent1Heading: string;
	textcomponent1Text: string;
	textcomponent1Label: string;
	textcomponent1ButtonLink: any;
	backgroundColor: string;
}): RelatedGridProps | undefined => {
	if (!data) {
		return null;
	}

	return {
		posts: data.posts,
		// columns: data.relatedgridColumns,
		textComponent1: {
			variant: data.textcomponent1Variant,
			overline: data.textcomponent1Overline,
			heading: data.textcomponent1Heading,
			text: data.textcomponent1Text,
			label: data.textcomponent1Label,
			buttonLink: data.textcomponent1ButtonLink
				? {
					...data.textcomponent1ButtonLink,
					url: linkResolver(data.textcomponent1ButtonLink.url),
				}
				: undefined,
		},
		containerProps: {
			backgroundColor: data.backgroundColor ? data.backgroundColor : undefined,
		},
	};
};
