/** @format */

import {gql} from '@apollo/client';

export const queryLatestNewsPosts = gql(`
query LatestNewsPosts {
	posts(first: 2, where: {categoryName: "industry-news"}) {
	  nodes {
		title
		date
		featuredImage {
		  node{
			altText
			sourceUrl
		  }
		}
		id
		uri
		categories {
		  edges {
			node {
			  name
			}
		  }
		}
		card {
		  cardviewExcerpt
		  cardviewFeaturedImage {
			sourceUrl
			altText
		  }
		  cardviewHeading
		  cardviewLinkText
		}
	  }
	}
  }
`);

type NewsPost = {
	id: string;
	date: string;
	uri: string;
	title: string;
	featuredImage: {
		node: {
			altText: string;
			sourceUrl: string;
		};
	};
	categories: {
		edges: Array<{
			node: {
				name: string;
			};
		}>;
	};
	card: {
		cardviewFeaturedImage: {
			sourceUrl: string;
			altText: string;
		};
		cardviewHeading: string;
		cardviewExcerpt: string;
		cardviewLinkText: string;
	};
};

export type NewsPostData = {
	posts: {
		nodes: NewsPost[];
	};
};
