/**
 * eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/** @format */

import {Box, Button, Grid, Text, GridItem, Heading} from '@chakra-ui/react';
import axios from 'axios';
import {Form, Formik} from 'formik';
import {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import useUser from '../../../../lib/useUser';
import {FeedbackMsgs} from '../../../patterns/CtqChecklist';
import Spacer from '../../../patterns/Spacer';
import {FormikField} from '../../FormikField/FormikField';
import {FormValues} from './FormValuesProps';

const EditUserSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	// propertyName: Yup.string().required('Required'),
	// // phone: Yup.string().min(6).required('Required'),
	// propertyWebsite: Yup.string().required('Required'),
	addressLine1: Yup.string().required('Required'),
	addressLine4: Yup.string().required('Required'),
	addressLine5: Yup.string().required('Required'),
	shippingAddressLine1: Yup.string().required('Required'),
	shippingAddressLine4: Yup.string().required('Required'),
	shippingAddressLine5: Yup.string().required('Required'),
});

const initialValues: FormValues = {
	firstName: '',
	lastName: '',
	propertyName: '',
	email: '',
	phone: '',
	propertyWebsite: '',
	addressLine1: '',
	addressLine2: '',
	addressLine3: '',
	addressLine4: '',
	addressLine5: '',
	shippingAddressLine1: '',
	shippingAddressLine2: '',
	shippingAddressLine3: '',
	shippingAddressLine4: '',
	shippingAddressLine5: '',
	username: '',
};

export const EditUserForm = () => {
	const [loadedValues, setLoadedValues] = useState<any>(initialValues);
	const [success, setSuccess] = useState('');
	const [error, setError] = useState(false);
	const {executeRecaptcha} = useGoogleReCaptcha();
	const {user, mutateUser} = useUser();

	const [loadingStripeUser, setLoadingStripeUser] = useState(null);
	const [errorLoadingStripeUser, setErrorLoadingStripeUser] = useState('');

	const [isSubmitLoading, setSubmitLoading] = useState(false);

	useEffect(() => {
		const fetchStripeId = async () => {
			setLoadingStripeUser(true);
			if (user?.id) {
				const response = await axios.post('/api/member/getStripeMember', {
					// prettier-ignore
					// query: `email:\'${user.email}\'`,
					// prettier-ignore
					// eslint-disable-next-line no-useless-escape
					query: `metadata[\'cmsUserId\']:\'${user.id}\'`, // dXN=...
				});

				if (response?.data && response.data.length) {
					let stripeUser = response.data[0];

					const {
						id = '',
						phone = '',
						email = '',
						metadata: {
							firstName = '',
							lastName = '',
							cmsUserId = '',
							propertyWebsite = '',
						},
						address,
						shipping,
					} = stripeUser;

					stripeUser = {
						id,
						cmsUserId,
						phone: phone || '',
						email,
						firstName,
						lastName,
						addressLine1: address?.line1 || '',
						addressLine2: address?.line2 || '',
						addressLine3: address?.state || '',
						addressLine4: address?.city || '',
						addressLine5: address?.postal_code || '',
						shippingAddressLine1: shipping?.address?.line1 || '',
						shippingAddressLine2: shipping?.address?.line2 || '',
						shippingAddressLine3: shipping?.address?.state || '',
						shippingAddressLine4: shipping?.address?.city || '',
						shippingAddressLine5: shipping?.address?.postal_code || '',
						propertyWebsite,
					};
					setLoadedValues(stripeUser);
					setSuccess('');
				}
			}

			setLoadingStripeUser(false);
		};

		fetchStripeId().catch(e => {
			setErrorLoadingStripeUser(e.message);
			console.error(e);
		});

		return () => {
			setErrorLoadingStripeUser('');
			setLoadingStripeUser(null);
			setLoadedValues(null);
		};
	}, []);

	const onSubmit = async (
		values: any,
		gReCaptchaToken: string,
		resetForm: () => void,
	) => {
		setError(false);
		setSuccess('');
		setSubmitLoading(true);

		try {
			const validateReCaptchaResponse = await axios.post(
				'/api/validateReCaptcha',
				{gReCaptchaToken},
			);

			if (validateReCaptchaResponse.status === 200) {
				const userObj = {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					name: `${values.firstName} ${values.lastName}`,
					phone: values.phone || '',
					// email: values.email,
					address: {
						line1: values.addressLine1 || '',
						line2: values.addressLine2 || '',
						state: values.addressLine3 || '',
						city: values.addressLine4 || '',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						postal_code: values.addressLine5 || '',
						country: 'GB',
					},
					shipping: {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						name: `${values.firstName} ${values.lastName}`,
						address: {
							line1: values.shippingAddressLine1 || '',
							line2: values.shippingAddressLine2 || '',
							state: values.shippingAddressLine3 || '',
							city: values.shippingAddressLine4 || '',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							postal_code: values.shippingAddressLine5 || '',
							country: 'GB',
						},
					},
					metadata: {
						firstName: values.firstName || '',
						lastName: values.lastName || '',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						phone_number: values.phone || '',
						propertyWebsite: values.propertyWebsite || '',
					},
				};

				const response = await axios.post('/api/member/updateStripeMember', {
					id: loadedValues.id,
					params: userObj,
				});

				if (response.status === 200) {
					setSuccess(
						`Submitted ${new Intl.DateTimeFormat('en-UK', {
							day: 'numeric',
							month: 'numeric',
							year: '2-digit',
						}).format(new Date(response.headers.date))}`,
					);
				}

				// resetForm();
			} else {
				setError(true);
				throw new Error();
			}

			if (validateReCaptchaResponse.status === 200) {
				const setUserMetaResponse = await axios.post(
					'/api/member/setUserMeta',
					{
						id: user.id,
						formData: values,
						refreshToken: user.refreshToken,
					},
				);
			}
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
		} catch (err) {
			setError(true);
		}

		setSubmitLoading(false);
	};

	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('editUserFormEditUser');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	if (!loadedValues) {
		return (
			<Box my={18}>
				<Heading as='h1' size='2xl' fontSize='2xl'>
					No payment account created for you yet, please contact your
					administrator.
				</Heading>
				<Spacer />
				{user ? (
					<>
						<Text>Email: {user?.email}</Text>
						<Text>Username: {user?.username}</Text>
					</>
				) : null}
			</Box>
		);
	}

	if (errorLoadingStripeUser) {
		return <Box>{`Loading User details error! ${errorLoadingStripeUser}`}</Box>;
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={loadedValues}
			validationSchema={EditUserSchema}
			onSubmit={async (values, {resetForm}) => {
				const gReCaptchaToken = await addRecaptcha(values);
				await onSubmit(values, gReCaptchaToken, resetForm);
			}}>
			{({errors, touched, isSubmitting}) => (
				<Form style={{width: '100%'}}>
					{/* <Box>WP id: {loadedValues.cmsUserId}</Box>
					<Box>Stripe id: {loadedValues.id}</Box> */}
					<Grid
						templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
						gap={{base: '0', md: '8rem'}}>
						<GridItem>
							<FormikField
								errors={errors}
								touched={touched}
								label={'First Name'}
								nameKey={'firstName'}
								placeholder={'First name'}
								autoComplete='given-name'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Last Name'}
								nameKey={'lastName'}
								placeholder={'Last name'}
								autoComplete='family-name'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Address'}
								nameKey={'addressLine1'}
								placeholder={'Address Line 1'}
								autoComplete='address-line1'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'addressLine2'}
								placeholder={'Address Line 2'}
								autoComplete='address-line2'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'addressLine4'}
								placeholder={'City'}
								autoComplete='address-level2'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'addressLine3'}
								placeholder={'Local Authority'}
								autoComplete='address-line3'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'addressLine5'}
								placeholder={'Post code'}
								autoComplete='postal-code'
							/>

						</GridItem>
						<GridItem>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Personal Address'}
								nameKey={'shippingAddressLine1'}
								placeholder={'Personal Address Line 1'}
								autoComplete='shipping address-line1'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'shippingAddressLine2'}
								placeholder={'Personal Address Line 2'}
								autoComplete='shipping address-line2'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'shippingAddressLine4'}
								placeholder={'Personal City'}
								autoComplete='shipping address-level2'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'shippingAddressLine3'}
								placeholder={'Personal Local Authority'}
								autoComplete='shipping address-line3'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								nameKey={'shippingAddressLine5'}
								placeholder={'Personal Post code'}
								autoComplete='shipping postal-code'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Email Address'}
								nameKey={'email'}
								placeholder='email'
								autoComplete='email'
								disabled={true}
							/>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Telephone Number'}
								nameKey={'phone'}
								placeholder=''
								autoComplete='tel'
							/>
							<FormikField
								errors={errors}
								touched={touched}
								label={'Property Website'}
								nameKey={'propertyWebsite'}
								placeholder='Property Website'
								autoComplete='url'
							/>
							<FeedbackMsgs success={success} error={error} />

							<Button
								isLoading={isSubmitLoading}
								loadingText='Submitting'
								type='submit'
								disabled={isSubmitting || isSubmitLoading}
								mt={{base: '4rem'}}
								float={{base: 'right'}}
								w={{base: '100%', md: 'auto'}}>
								Save & Update Details
							</Button>
						</GridItem>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
