/** @format */

import React, {ReactElement} from 'react';
import {DateProps} from './dateProps';
import {chakra, Flex, Box} from '@chakra-ui/react';
import {textStyles} from '../../styles/js/textStyles';

function Date({date}: DateProps): ReactElement {
	// const color = variant && variant === 'light' ? 'arcticScale' : 'abyss';
	const headingTextStyle = textStyles.tagsDatesLight;

	return (
		<Flex
			direction='column'
			alignItems='flex-start'
			sx={{'> *:last-child': {mb: '0'}}}>
			<Box
				sx={headingTextStyle}
				pr={{lg: '1.5em'}}
				mb={{base: '6'}}
				whiteSpace='pre-line'>
				24/06/2022
			</Box>
		</Flex>
	);
}

export default Date;
