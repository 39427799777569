import {Box, Container} from '@chakra-ui/react';
import React from 'react';
import ImageComponent from '../../components/Image';
import {ImageProps} from './ImageProps';
import {ImageProps as NextImageProps} from 'next/image';

const Image = ({displayFullBleed, displayFullWidth, image}: ImageProps) => {
	const imageProps: NextImageProps = displayFullWidth
		? {
			src: image.src,
			layout: 'responsive',
			width: image.width,
			height: image.height,
		}
		: {
			src: image.src,
			width: image.width,
			height: image.height,
		};
	const imageComponent = displayFullWidth ? (
		<Box width='100%' position='relative'>
			<ImageComponent {...imageProps} />
		</Box>
	) : (
		<ImageComponent {...imageProps} />
	);
	return (
		<>
			{displayFullBleed ? (
				imageComponent
			) : (
				<Container maxW='container.xl' mx='auto'>
					{imageComponent}
				</Container>
			)}
		</>
	);
};

export default Image;
