/** @format */

/**
 * this is a singleton to ensure we only instantiate Stripe once.
 */
import {Stripe, loadStripe} from '@stripe/stripe-js';
const locales = 'en-GB';

let stripePromise: Promise<Stripe | undefined>;
export const getStripe = async () => {
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	if (!stripePromise) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!);
	}

	return stripePromise;
};

export const htmlDecode = input => {
	const doc = new DOMParser().parseFromString(input, 'text/html');
	return doc.documentElement.textContent;
};

export const scrollToTop = (top = 0) => {
	if (window) {
		window.scrollTo({top, behavior: 'smooth'});
	}
};

export const formatTimeForDisplay = (unixTimeStamp: number): string => {
	// unixTimeStamp represents the of seconds that have elapsed since January 1, 1970 (UTC)
	const startTimestamp = unixTimeStamp;
	// we multiply the startTimestamp value by 1000 because the Date constructor expects the input to be in milliseconds, not seconds.

	if (!startTimestamp) {
		return '';
	}

	const startDate = new Date(startTimestamp * 1000);
	const localeTimeString = startDate.toLocaleString(locales);
	return localeTimeString;
};

export const currencyType = 'GBP';

const numberFormat = new Intl.NumberFormat([locales], {
	style: 'currency',
	currency: currencyType,
	currencyDisplay: 'symbol',
});

export function formatAmountForStripe(amount = 0): number {
	const parts = numberFormat.formatToParts(amount);
	let zeroDecimalCurrency = true;
	for (const part of parts) {
		if (part.type === 'decimal') {
			zeroDecimalCurrency = false;
		}
	}

	return zeroDecimalCurrency ? amount : Math.round(amount * 100);
}

export const formatAmountFromStripe = (amount = 0): string => {
	const strAmount = numberFormat.format(amount / 100);
	return strAmount;
};

export const formatTaxAmount = (amount = 0): string => {
	const taxAmount = numberFormat.format((amount * 20) / 100);
	return taxAmount;
};

export const minAmount = 10.0;
export const maxAmount = 50000.0;
