import {FlexProps, SystemProps} from '@chakra-ui/react';

export function direction(cardStyle): SystemProps['flexDirection'] {
	switch (cardStyle) {
		case 'ROW_COLUMN':
			return {base: 'row', md: 'column'};
		case 'COLUMN':
			return 'column';
		case 'ROW':
			return 'row';
		case 'COLUMN_ROW':
			return {base: 'column', md: 'row'};
		default:
			return 'column';
	}
}

export function imageWidth(cardStyle): SystemProps['width'] {
	switch (cardStyle) {
		case 'ROW_COLUMN':
			return {base: '172px', sm: 'calc(50% - 16px)', md: '100%'};
		case 'COLUMN':
			return '100%';
		case 'ROW':
			return {base: '172px', sm: 'calc(50% - 16px)'};
		case 'COLUMN_ROW':
			return {base: '100%', sm: 'calc(50% - 16px)'};
		default:
			return '100%';
	}
}

export function imageHeight(cardStyle): SystemProps['height'] {
	switch (cardStyle) {
		case 'ROW_COLUMN':
			return {md: '253px'};
		case 'COLUMN':
			return {base: '189px', md: '253px'};
		case 'ROW':
			return 0;
		case 'COLUMN_ROW':
			return {base: '189px', md: 0};
		default:
			return {base: '189px', md: '253px'};
	}
}

export function contentPadding(cardStyle): FlexProps {
	switch (cardStyle) {
		case 'ROW_COLUMN':
			return {
				paddingY: {base: '4', sm: '6'},
				paddingX: {base: '4', sm: '4'},
			};
		case 'COLUMN':
			return {
				paddingY: {base: '6'},
				paddingX: {base: 0, sm: '4'},
			};
		case 'ROW':
			return {
				paddingY: {base: '4'},
				paddingX: {base: '4'},
			};
		case 'COLUMN_ROW':
			return {
				paddingY: {base: '6', sm: '4'},
				paddingX: {base: 0, sm: '4'},
			};
		default:
			return {
				paddingY: {base: '6'},
				paddingX: {base: 0, sm: '4'},
			};
	}
}
