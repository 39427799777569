import {SystemStyleObject, Box, Text, useMultiStyleConfig} from '@chakra-ui/react';
import {textStyles} from '../../styles/js/textStyles';
import ButtonStack from './ButtonStack';
import {TextAndBulletsProps} from './TextAndBulletsProps';

type TextContentProps = {
	subHeadingStyle: SystemStyleObject;
	textContent: TextAndBulletsProps['textContent'];
};

const TextContent = ({
	subHeadingStyle, textContent,
}: TextContentProps,
) => {
	const {textContentContainerItem} = useMultiStyleConfig('textAndBullets');
	const isDarkText = textContent.textColor === 'dark';
	const headingStyle: SystemStyleObject = isDarkText ? textStyles.display5LeftDark : textStyles.display5LeftLight;
	return (
		<Box
			display='flex'
			flexDirection='column'
			px={{base: 0, md: 8}}
		>
			<Text sx={{...headingStyle, ...textContentContainerItem}}>
				{textContent.heading}
			</Text>
			<Box>
				<Text sx={{...subHeadingStyle, ...textContentContainerItem}}>
					{textContent.subheading}
				</Text>
				{
					textContent.linkButtons && ButtonStack
						? <ButtonStack linkButtons={textContent.linkButtons} />
						: null
				}
			</Box>
		</Box>
	);
};

export default TextContent;
