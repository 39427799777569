/**
 * eslint-disable react/prop-types
 *
 * @format
 */

/* eslint no-negated-condition: 1, @typescript-eslint/no-unsafe-assignment: 1 */
import {
	Box,
	useMultiStyleConfig,
	SimpleGrid,
	Flex,
	SystemStyleObject,
	Container,
} from '@chakra-ui/react';
import React from 'react';
import {textStyles} from '../../styles/js/textStyles';
import IconBullet from './IconBullet';
import {TextAndBulletsProps} from './TextAndBulletsProps';
import TextContent from './TextContent';

const TextAndBullets = ({
	bullets,
	textContent,
	backgroundColor,
}: TextAndBulletsProps) => {
	const isDarkText = textContent.textColor === 'dark';
	const subHeadingStyle: SystemStyleObject = isDarkText
		? textStyles.paragraph1LeftItalicDark
		: textStyles.paragraph1LeftItalicLight;
	const {box} = useMultiStyleConfig('textAndBullets');

	const BulletsContent = () => (
		<Box>
			{
				// eslint-disable-next-line react/prop-types
				bullets.map((bullet, index) => (
					<IconBullet
						key={index}
						textStyle={{...subHeadingStyle}}
						bullet={bullet}
					/>
				))
			}
		</Box>
	);

	const HorizontalTextBullets = () => (
		<>
			<TextContent
				subHeadingStyle={subHeadingStyle}
				textContent={textContent}
			/>
			<SimpleGrid columns={{base: 1, md: 2}}>
				<BulletsContent />
			</SimpleGrid>
		</>
	);

	const VerticalTextBullets = () => (
		<SimpleGrid columns={{base: 1, md: 2}}>
			<TextContent
				subHeadingStyle={subHeadingStyle}
				textContent={textContent}
			/>
			<Flex direction='column'>
				<BulletsContent />
			</Flex>
		</SimpleGrid>
	);

	return (
		<Box sx={box} bgColor={backgroundColor}>
			<Container maxW='container.xl' mx='auto'>
				{textContent.layout === 'horizontal' ? (
					<HorizontalTextBullets />
				) : (
					<VerticalTextBullets />
				)}
			</Container>
		</Box>
	);
};

export default TextAndBullets;
