/** @format */

import {
	Box,
	Flex,
	ChakraProps,
	ImageProps,
	Container,
	Text,
	useBreakpointValue,
	useMultiStyleConfig,
	VStack,
	Spacer,
} from '@chakra-ui/react';
import {CSSProperties} from 'react';
import ChakraNextImage from '../../components/ChakraWrappedImage';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import {FeaturedQuoteProps} from './FeaturedQuoteProps';
import Image from '../../components/Image';
import quoteImgStart from '../../../public/images/quote-mark-beginning.svg';
import quoteImgEnd from '../../../public/images/quote-mark-end.svg';

const FeaturedQuote = ({
	image,
	quote,
	quoteInfo,
	bgImageIsTransparent,
}: FeaturedQuoteProps) => {
	const bgColor = colors.tweed[400];
	const boxProps: ChakraProps = {
		bgColor,
	};

	const {box, quoteBox, quoteInfoBox} = useMultiStyleConfig('featuredQuote');

	const getQuoteTextStyle = (): ChakraProps => {
		const isDark = quote.textColor === 'Dark';
		return isDark ? textStyles.display5LeftDark : textStyles.display5LeftLight;
	};

	const getQuoteInfoTextStyle = (): ChakraProps => {
		const isDark = quoteInfo.textColor === 'Dark';
		return useBreakpointValue({
			base: isDark ? textStyles.display7LeftDark : textStyles.display7LeftLight,
			md: isDark ? textStyles.display6LeftDark2 : textStyles.display6LeftLight,
		}, {ssr: true});
	};

	return (
		<Box position='relative'>
			<ChakraNextImage
				image={image}
				opacity={bgImageIsTransparent === 'true' ? '0.5' : '1'}
				objectFit='cover'
			/>
			<Box {...boxProps} sx={box}>
				{' '}
				<Container maxW='container.lg' mx='auto'>
					<blockquote>
						<Box sx={{...getQuoteTextStyle(), ...quoteBox}}>
							<VStack alignItems={'flex-start'}>
								<Box
									mb={-4}
									ml={{base: -6, lg: -4}}
									sx={{
										transform: {base: 'scale(0.5)', lg: 'scale(0.7)'},
									}}>
									{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
									<Image width={100} height={100} src={quoteImgStart} alt='"' />
								</Box>

								<Text
									pt={{base: 0, lg: 2}}
									pb={{base: 16, lg: 8}}
									maxW={{base: '100%', lg: '92%'}}
									zIndex={1}>
									{quote.text}
								</Text>

								<Flex width={'100%'} height={100} maxWidth={'100%'}>
									<Box zIndex={1}>
										<Text sx={getQuoteInfoTextStyle()}>{quoteInfo.text}</Text>
									</Box>
									<Spacer />
									<Box
										sx={{
											transform: {base: 'scale(0.5)', lg: 'scale(0.7)'},
											marginTop: {base: -24, md: -10},
										}}>
										{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
										<Image width={100} height={100} src={quoteImgEnd} alt='"' />
									</Box>
								</Flex>
							</VStack>
						</Box>{' '}
					</blockquote>
				</Container>
			</Box>
		</Box>
	);
};

export default FeaturedQuote;
