/* eslint @typescript-eslint/naming-convention: 1 */
import React from 'react';
import CentreAlignedCTABanner from './CentreAlignedCTABanner';
import {CTABannerProps} from './CTABannerProps';
import LeftAlignedCTABanner from './LeftAlignedCTABanner';

// eslint-disable-next-line @typescript-eslint/naming-convention
const CTABanner = (props: CTABannerProps) => {
	switch (props.variant) {
		case 'center':
			return <CentreAlignedCTABanner {...props} />;

		case 'left':
		default:
			return <LeftAlignedCTABanner {...props} />;
	}
};

export default CTABanner;
