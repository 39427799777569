/** @format */

/**
 * prettier-ignore
 * eslint-disable @typescript-eslint/no-unsafe-call
 *
 * @format
 */

import {
	Box,
	SimpleGrid,
	Text,
	LinkOverlay,
	useBreakpointValue,
	SystemStyleObject,
	Skeleton,
	SkeletonText,
	Flex,
	Spacer,
	Center,
	Heading,
	Select,
	Container,
	Button,
	HStack,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Card} from '../../components/Card/Card';
import ChakraNextImage from '../../components/ChakraWrappedImage';
import {ChevronRightIcon} from '@chakra-ui/icons';
import LinkButton from '../../components/LinkButton';
import Link from 'next/link';
import {textStyles} from '../../styles/js/textStyles';
import {postCard} from '../../styles/js/card';
import {PaginatedPostGridProps} from './paginatedPostGridProps';
import {colors} from '../../styles/js/colors';
import useUser from '../../../lib/useUser';
import Image from 'next/image';
import axios from 'axios';
import {scrollToTop} from '../../utils';

type Post = {
	uri: string;
	id: string;
	date: string;
	title: string;
	featuredImage?: {
		node?: {
			sourceUrl?: string;
			altText?: string;
		};
	};
	categories: {
		nodes: Array<{
			name: string;
			slug: string;
		}>;
	};
	card: {
		cardviewExcerpt: string;
		cardviewFeaturedImage?: {
			sourceUrl?: string;
			altText?: string;
		};
		cardviewHeading: string;
		cardviewLinkText: string;
	};
	__typename: string;
};

type PostsData = {
	nodes: Post[];
	pageInfo: {
		startCursor: string;
		endCursor: string;
		hasPreviousPage: boolean;
		hasNextPage: boolean;
	};
};

// const paginatedArray = [1, 2, 3, 4];

// eslint-disable-next-line @typescript-eslint/naming-convention
let CATEGORIES_QUERY = gql`
	query Categories {
		categories(first: 1000) {
			nodes {
				id
				name
				slug
				count
			}
		}
	}
`;

// const PAGINATED_QUERY = gql`
// 	query PageList {
// 		posts {
// 			pageInfo {
// 				currentPage
// 				totalPages
// 				perPage
// 			}
// 		}
// 	}
// `;

// export async function getPageNumbers(currentPage) {
// 	// Set up the GraphQL query to fetch the total number of posts and the number of posts per page
// 	const query = `
// 	  query {
// 		posts {
// 		  pageInfo {
// 			currentPage
// 			totalPages
// 			perPage
// 		  }
// 		}
// 	  }
// 	`;

// 	// Make the GraphQL request to fetch the post data
// 	// const data = await request('https://your-wpgraphql-endpoint.com/graphql', query);
// 	const data = {posts: []};

// 	// Get the total number of pages and current page from the GraphQL response
// 	const { totalPages, currentPage: graphqlCurrentPage, perPage } = data.posts.pageInfo;

// 	// Calculate the number of page numbers to show before and after the current page
// 	const pagesToShow = 3;
// 	const startPage = Math.max(1, currentPage - pagesToShow);
// 	const endPage = Math.min(totalPages, currentPage + pagesToShow);

// 	// Build an array of page numbers to return
// 	const pageNumbers = [];

// 	for (let i = startPage; i <= endPage; i++) {
// 	  pageNumbers.push(i);
// 	}

// 	return pageNumbers;
//   }

const FilterHeader = ({
	postType,
	setSelectedCategory,
	setSelectedMonth,
	setSelectedYear,
}: {
	postType: string;
	setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
	setSelectedMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
	setSelectedYear: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
	if (postType === 'events') {
		CATEGORIES_QUERY = gql`
			query Categories {
				events {
					nodes {
						categories(first: 1000) {
							nodes {
								id
								name
								slug
								count
							}
						}
					}
				}
			}
		`;
	}

	const {
		loading: categoriesLoading,
		error: categoriesError,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		data: categoriesData,
	} = useQuery(CATEGORIES_QUERY);

	if (categoriesError) {
		console.error(categoriesError);
	}

	if (
		categoriesLoading
		|| (postType !== 'events' && categoriesData && !categoriesData.categories)
	) {
		return (
			<Box>
				<SimpleGrid columns={[1, 3]} spacing='2' my={12}>
					<Select placeholder='Select category' isDisabled />
					<Select placeholder='Select month' isDisabled />
					<Select placeholder='Select year' isDisabled />
				</SimpleGrid>
			</Box>
		);
	}

	const date = new Date();
	const months = [];
	for (let i = 0; i < 12; i++) {
		date.setMonth(i);
		months.push({
			id: i + 1,
			name: date.toLocaleString('default', {month: 'long'}),
		});
	}

	const years = [];
	years.push({
		id: date.getFullYear(),
	});
	for (let i = 0; i < 9; i++) {
		date.setFullYear(date.getFullYear() - 1);
		years.push({
			id: date.getFullYear(),
		});
	}

	const categoryHideFilter = (catName, names) =>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
		names.includes(catName.toLowerCase());

	type Category = {
		id: string;
		name: string;
		count: number;
	};

	const categoryArray = ['members content'];

	if (postType !== 'people') {
		return (
			<Box>
				<SimpleGrid columns={[1, 3]} spacing={4} my={12}>
					<Select
						placeholder='Select category'
						onChange={e => {
							setSelectedCategory(e.target.value);
						}}>
						{postType !== 'events'
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							&& categoriesData?.categories?.nodes?.map(
								// prettier-ignore
								(category: {id: string; name: string; count: number}) => !categoryHideFilter(category.name, ['uncategorized', 'members content', 'assc talks', 'legacy news', 'Members Content', 'hide post']) && category.count > 0 ? (
									<option key={category.id} value={category.name}>
										{category.name} <Box>({category.count})</Box>
									</option>
								) : null,
							)}
						{postType === 'events'
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							&& categoriesData?.events?.nodes?.map(
								(event: {categories: {nodes: Category[]}}) =>
									event.categories.nodes.map((category: Category) => {
										if (!categoryHideFilter(category.name, categoryArray)) {
											categoryArray.push(category.name.toLowerCase());
											return (
												<option key={category.id} value={category.name}>
													{category.name} <Box>({category.count})</Box>
												</option>
											);
										}

										return null;
									}),
							)}
					</Select>
					<Select
						placeholder='Select month'
						onChange={e => {
							if (e.target.value === 'Select month') {
								setSelectedMonth(undefined);
							} else {
								setSelectedMonth(parseInt(e.target.value, 10));
							}
						}}>
						{months.map((month: {id: string; name: string}) => (
							<option key={month.id} value={month.id}>
								{month.name}
							</option>
						))}
					</Select>
					<Select
						placeholder='Select year'
						onChange={e => {
							if (e.target.value === 'Select year') {
								setSelectedYear(undefined);
							} else {
								setSelectedYear(parseInt(e.target.value, 10));
							}
						}}>
						{years.map((year: {id: string}) => (
							<option key={year.id} value={year.id}>
								{year.id}
							</option>
						))}
					</Select>
				</SimpleGrid>
			</Box>
		);
	}

	return null;
};

const PaginatedPostGrid = ({
	postType,
	fixedCategory,
	hideFilter,
}: PaginatedPostGridProps) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const POSTS_QUERY = gql`
		query Posts($after: String, $first: Int, $before: String, $last: Int, $category: String, $month: Int, $year: Int) {
			${postType}(first: $first, after: $after, before: $before, last: $last, where: {
				categoryName: $category,
				dateQuery: {
					month: $month
					year: $year
				}
			}) {
				nodes {
					title
					date
					uri
					id
					featuredImage {
						node {
						sourceUrl
						altText
						}
					}
					categories {
						nodes {
						name
						slug
						}
					}
					card {
						cardviewExcerpt
						cardviewFeaturedImage {
						sourceUrl
						altText
						}
						cardviewHeading
						cardviewLinkText
					}
				}
				pageInfo {
					startCursor
					endCursor
					hasPreviousPage
					hasNextPage
				}
			}
		}
`;

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const {user, mutateUser} = useUser();
	const [posts, setPosts] = useState<PostsData>();
	const [postsLoading, setPostsLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [activeSubscriptions, setActiveSubscriptions] = useState([]);
	const cardStyle = postCard;
	const headingStyle = useBreakpointValue(
		{
			base: textStyles.display7LeftDark,
			md: textStyles.display7LeftDark,
		},
		{ssr: true},
	);
	const badgeTextStyle = useBreakpointValue(
		{
			base: textStyles.display9LeftLight,
			md: textStyles.paragraph2LeftLight,
		},
		{ssr: true},
	);

	const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
		undefined,
	);
	const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
		undefined,
	);
	const [selectedYear, setSelectedYear] = useState<number | undefined>(
		undefined,
	);
	const [hideFilters, setHideFilters] = useState<string | undefined>(undefined);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {loading, error, data, fetchMore} = useQuery(POSTS_QUERY, {
		variables: {
			first: 12,
		},
	});

	// Logged-in useEffect
	useEffect(() => {
		if (user) {
			if (user?.isLoggedIn) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		}
	}, [user, isLoggedIn]);

	// Subscription useEffect
	useEffect(() => {
		const checkActiveSubscriptions = async () => {
			try {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const {data} = await axios.post('/api/stripe/findActiveSubscription', {
					cmsUserId: user?.id,
				});
				setActiveSubscriptions(data?.activeSubscriptions || []);
			} catch (error: unknown) {
				console.error(
					'An error occurred retrieving subscription data. ',
					error,
				);
			}
		};

		void checkActiveSubscriptions();

		return () => {
			setActiveSubscriptions([]);
		};
	}, [user?.id]);

	// Posts query useEffect
	useEffect(() => {
		if (data) {
			setPosts(data[postType]);
			setPostsLoading(false);
		}
	}, [data]);

	// Category selection useEffect
	useEffect(() => {
		if (fixedCategory.name) {
			setSelectedCategory(fixedCategory.name);
		}
	}, []);

	// Hide filter useEffect
	useEffect(() => {
		if (hideFilter) {
			setHideFilters(hideFilter);
		}
	}, []);

	const fetchMorePosts = async (variables: {
		after?: string;
		before?: string;
		first?: number;
		last?: number;
		category?: string;
		month?: number;
		year?: number;
	}) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const {data: morePosts} = await fetchMore({
			variables: {
				...variables,
			},
		});
		scrollToTop();
		setPosts(morePosts[postType]);
		setPostsLoading(false);
	};

	useEffect(() => {
		if (selectedCategory || selectedMonth || selectedYear) {
			void fetchMorePosts({
				first: 12,
				category: selectedCategory,
				month: selectedMonth || undefined,
				year: selectedYear || undefined,
			});
		} else {
			void fetchMorePosts({
				first: 12,
			});
		}
	}, [selectedCategory, selectedMonth, selectedYear]);

	const handleFetchPrevious = () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		page > 1 && setPage(page - 1);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		page !== 1
			// eslint-disable-next-line no-void
			&& void fetchMorePosts({
				before: posts.pageInfo.startCursor,
				first: undefined,
				last: 12,
				category: selectedCategory || undefined,
				month: selectedMonth || undefined,
				year: selectedYear || undefined,
			});
	};

	if (error) {
		console.error(error);
	}

	if (loading || (data && !posts) || postsLoading) {
		return (
			<Container maxW='container.xl' mx='auto'>
				<FilterHeader
					postType={postType}
					setSelectedCategory={setSelectedCategory}
					setSelectedMonth={setSelectedMonth}
					setSelectedYear={setSelectedYear}
				/>
				<SkeletonPostGrid
					cardStyle={cardStyle}
					badgeTextStyle={badgeTextStyle}
				/>
			</Container>
		);
	}

	const Pagination = () => (
		// Get the list of pages:
		// const {
		// 	loading: categoriesLoading,
		// 	error: categoriesError,
		// 	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		// 	data: categoriesData,
		// } = useQuery(PAGINATED_QUERY);

		<Box my={6}>
			<Center>
				<Flex>
					{posts.pageInfo.hasPreviousPage ? (
						<Button
							variant={'solid'}
							onClick={() => {
								handleFetchPrevious();
							}}>
							&lt;
						</Button>
					) : (
						<Spacer />
					)}

					{/* {paginatedArray.map(item => (
						<Button variant={'solid'}>{item}</Button>
					))} */}
					<Box mx={6}>
						<Text py={3}>Page: {page}</Text>
					</Box>

					<Button
						variant={'solid'}
						onClick={() => {
							setPage(page + 1);
							void fetchMorePosts({
								after: posts.pageInfo.endCursor,
								first: 12,
								category: selectedCategory || undefined,
								month: selectedMonth || undefined,
								year: selectedYear || undefined,
							});
						}}>
						&gt;
					</Button>
				</Flex>
			</Center>
		</Box>
	);

	return (
		<Container maxW='container.xl' mx='auto'>
			{
				// hide the filter bar if we have a set category in the CMS:
				// eslint-disable-next-line no-negated-condition
				hideFilters[0]?.startsWith('yes') ? null : !fixedCategory.name ? ( // prettier-ignore
					<FilterHeader
						postType={postType}
						setSelectedCategory={setSelectedCategory}
						setSelectedMonth={setSelectedMonth}
						setSelectedYear={setSelectedYear}
						// prettier-ignore
					/>
				) : null
			}

			<SimpleGrid columns={[1, 4]} spacing={4} mt={4}>
				{posts.nodes.map(post => {
					const styles = {
						cardStyle,
						headingStyle,
						badgeTextStyle,
					};

					const hasHidePostCategory = post.categories?.nodes.filter(
						category => category.slug === 'hide-post',
					);
					if (hasHidePostCategory.length > 0) {
						return;
					}
					// if (
					// 	post.uri.includes(
					// 		'scottish-tourism-alliance-industry-survey-may-june-2023',
					// 	)
					// ) {
					// 	return;
					// }

					return (
						<PostCard
							key={post.id}
							post={post}
							styles={styles}
							isLoggedIn={isLoggedIn}
							activeSubscriptions={activeSubscriptions}
						/>
					);
				})}
			</SimpleGrid>
			<Pagination />
		</Container>
	);
};

const Badge = ({
	text,
	badgeTextStyle,
}: {
	text?: string;
	badgeTextStyle: SystemStyleObject;
}) => (
	<Box
		sx={badgeTextStyle}
		bgColor='lime.600'
		px={{
			base: '4',
			md: '6',
		}}
		py='0.5'
		borderRadius='md'
		textAlign='center'>
		{text ? (
			<Text noOfLines={2}>{text}</Text>
		) : (
			<SkeletonText noOfLines={1} skeletonHeight='6' />
		)}
	</Box>
);

const PostCard = ({
	post,
	styles,
	isLoggedIn,
	activeSubscriptions,
}: {
	post: Post;
	styles: {
		cardStyle: SystemStyleObject;
		headingStyle: SystemStyleObject;
		badgeTextStyle: SystemStyleObject;
	};
	isLoggedIn;
	activeSubscriptions;
}) => {
	const hasMemberOnlyCategory = post.categories?.nodes.filter(
		category => category.slug === 'members-content',
	);
	const {cardStyle, headingStyle} = styles;
	const imageProps = {
		src: post?.featuredImage?.node?.sourceUrl,
		alt: post?.featuredImage?.node?.altText,
	};
	if (!imageProps.src) {
		imageProps.src = post?.card?.cardviewFeaturedImage?.sourceUrl;
		imageProps.alt = post?.card?.cardviewFeaturedImage?.altText;
	}

	if (!imageProps.src) {
		imageProps.src = '/images/placeholder.png';
		imageProps.alt = 'placeholder';
	}

	if (!imageProps.alt) {
		imageProps.alt = 'placeholder';
	}

	const formattedDate = new Date(post.date).toLocaleDateString('en-GB', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});

	const typenamesToRemoveDate = ['GuidanceSheet', 'Event'];
	const isDateful = !typenamesToRemoveDate.includes(post.__typename);

	return (
		<Card key={post.id} sx={cardStyle}>
			<Flex direction='column' height={'100%'}>
				<ChakraNextImage
					layout='responsive'
					image={imageProps}
					fit='cover'
					borderRadius='5px 5px 0 0'
					height={33}
				/>
				{/* <SimpleGrid columns={[1, 3]} spacing='2' mb={2} mx={2}>
					{post.categories.nodes.map(category => (
						<Badge
							key={post.id.concat(category.name)}
							text={category.name}
							badgeTextStyle={styles.badgeTextStyle}
						/>
					))}
				</SimpleGrid> */}
				{isDateful ? (
					<Flex m={2}>
						<Badge
							text={formattedDate}
							badgeTextStyle={styles.badgeTextStyle}
						/>
					</Flex>
				) : null}
				<Box m={2}>
					<Flex my={1}>
						{/* <Box flex={2}>
							{post.categories?.nodes[0] ? (
								<Badge
									key={post.id.concat(post.categories.nodes[0].name)}
									text={post.categories.nodes[0].name}
									badgeTextStyle={styles.badgeTextStyle}
								/>
							) : null}
						</Box> */}
					</Flex>

					<Text sx={headingStyle} mb='1rem' minH='4rem'>
						{`${post.card.cardviewHeading || post.title}`}
					</Text>
				</Box>
				<Spacer />
				<Box m={2}>
					<HStack>
						<Link href={post.uri} passHref>
							<LinkButton
								as={LinkOverlay}
								text={post.card.cardviewLinkText || 'Read more'}
								isExternal={false}
								variant='link'
								rightIcon={
									<ChevronRightIcon w={5} h={5} color={colors.aquaGreen[500]} />
								}
								sx={{
									textColor: colors.aquaGreen[500],
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&::before': {
										bgColor: 'transparent',
										opacity: 0,
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover': {
										textDecoration: 'none',
										textColor: colors.aquaGreen[400],
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover::before': {
										opacity: 1,
									},
								}}
							/>
						</Link>

						<Box flex={1} textAlign={'right'}>
							{activeSubscriptions.length === 0
								&& hasMemberOnlyCategory.length > 0 ? ( // prettier-ignore
								// prettier-ignore
								// eslint-disable-next-line @typescript-eslint/indent
								<Image
										width={30}
										height={30}
										src={'/icons/component-icon-padlock.svg'}
										alt={'Lock icon'}// prettier-ignore
										// eslint-disable-next-line @typescript-eslint/indent
								/> // eslint-disable-next-line @typescript-eslint/indent
							) // prettier-ignore
							// eslint-disable-next-line @typescript-eslint/indent
							: null}
						</Box>
					</HStack>
				</Box>
			</Flex>
		</Card>
	);
};

const SkeletonPostGrid = ({
	cardStyle,
	badgeTextStyle,
}: {
	cardStyle: SystemStyleObject;
	badgeTextStyle: SystemStyleObject;
}) => (
	<Container maxW='container.xl' mx='auto'>
		<SimpleGrid columns={[1, 4]} spacing={4}>
			{Array.from(Array(12)).map((_, index) => (
				<Card key={index} sx={cardStyle} minH='260px'>
					<Flex direction='column'>
						<Skeleton height='14rem' width='100%' />
						<Skeleton height='100%' width='100%' />
						<Box m={2}>
							{/* <Badge badgeTextStyle={badgeTextStyle} /> */}
							<Skeleton minH='3rem' width='100%' mb='1rem' />
							<LinkButton
								as={LinkOverlay}
								text={'Read more'}
								isExternal={false}
								variant='link'
								colorScheme='aquaGreen'
								rightIcon={<ChevronRightIcon w={5} h={5} />}
								sx={{
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&::before': {
										bgColor: 'transparent',
										opacity: 0,
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover::before': {
										opacity: 1,
									},
								}}
							/>
						</Box>
					</Flex>
				</Card>
			))}
		</SimpleGrid>
	</Container>
);

export default PaginatedPostGrid;
