/* eslint @typescript-eslint/no-base-to-string: 1, @typescript-eslint/restrict-template-expressions: 1, @typescript-eslint/no-unsafe-assignment: 1 */
import React, {ReactElement} from 'react';
import Image from '../../../components/Image';
import {Box, Container, Grid} from '@chakra-ui/react';
import HeroText from '../HeroText';
import {HeroBanner2ColumnProps} from './HeroBanner2ColumnProps';

function HeroBanner2Column({
	image,
	isFullBleed,
	heroText,
}: HeroBanner2ColumnProps): ReactElement {
	if (!heroText || !isFullBleed) {
		return null;
	}

	const badgeProps = {size: 'lg', label: heroText.badge.label};

	return (
		<Box
			backgroundColor={{base: 'transparent', md: 'white'}}
			as='section'
			overflow='hidden'
			position='relative'
		>
			<Box
				position={{base: 'relative', md: 'absolute'}}
				top='0'
				bottom='0'
				right='0'
				left={{base: '0', md: '50%'}}
				height={{base: '227px', md: '100%'}}
				backgroundImage={{
					base: isFullBleed && image?.src ? `url(${image.src})` : 'none',
				}}
				backgroundSize='contain'
				backgroundPosition={{base: 'left center', md: 'right center'}}
				backgroundRepeat='no-repeat'
			/>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{base: '1fr', md: '6fr 6fr'}}
					columnGap='8'
					rowGap='0'
				>
					<Box
						order={{base: 2, md: 1}}
						paddingTop={{base: '6', md: '16'}}
						paddingBottom={{base: '8', md: '16'}}
					>
						<HeroText
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							heading={heroText.heading}
							supportingText={heroText.supportingText}
							buttonLink={heroText.buttonLink}
							variant={'dark'}
							badge={badgeProps}
						></HeroText>
					</Box>
					<Box order={{base: 1, md: 2}}>
						{!isFullBleed && image?.src && (
						/* Box below for design demonstration, to be removed for production */
							<Box opacity='0.8' border='1px solid black'>
								<Image
									src={image.src}
									alt={image.alt}
									width={image.width}
									height={image.height}
									layout='responsive'
								/>
							</Box>
						)}
					</Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default HeroBanner2Column;
