/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1
 *
 * @format
 */

/** @format */

import {AccordionProps, AccordionListProps} from './accordionProps';

export const accordionMapping = (data: any): AccordionProps | undefined => {
	if (!data) {
		return null;
	}

	let accordions = [];
	if (data.accordionList) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
		accordions = data.accordionList.map((accordion: AccordionListProps) => ({
			title: accordion.title,
			content: accordion.content,
		}));
	}

	return {
		accordions,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		allowMultiple: data.allowMultiple || true,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		alignTitleText: data.alignTitleText || 'left',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor: data.backgroundColor || '',
	};
};
