/* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/prefer-optional-chain: 1, no-mixed-spaces-and-tabs: 1 */
/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1
 *
 * @format
 */

import {linkResolver} from '../../lib/linkResolver';
import {CTABannerProps} from './CTABannerProps';

export const ctaBannerMapping = (data: any): CTABannerProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const ctas = data.ctas.map(cta => ({
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		buttonType: cta.buttontype,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		isLink: cta.isLink,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		signUpNewsletterText: cta.signUpNewsletterText,
		url: cta.link && cta.link.url ? linkResolver(cta.link.url) : null,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		title: (cta.link && cta.link.title) || '',
	}));

	return {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		variant: data.variant,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		heading: data.heading,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		text: data.text,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		isFullBleed: data.displayFullBleed,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		includeBackgroundImage: data.includeBackgroundImage,
		backgroundImage: data.backgroundImage
			? {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				src: data.backgroundImage.sourceUrl,
			  }
			: undefined,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor: data.backgroundColor,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		textColor: data.textColour,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		ctas,
	};
};
