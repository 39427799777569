/* eslint react/prop-types: 1, @typescript-eslint/no-unsafe-return: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/naming-convention: 1, @typescript-eslint/no-base-to-string: 1, @typescript-eslint/restrict-template-expressions: 1 */
import React from 'react';
import {Box, Select, VStack} from '@chakra-ui/react';
import {TermProps} from './FilterProps';

// eslint-disable-next-line @typescript-eslint/naming-convention, react/prop-types
export const Filter = ({terms, handleChange, label, taxonomy, allLabel}) => (
	<VStack
		spacing='4'
		justifyContent={'flex-start'}
		alignItems={'flex-start'}
		width='100%'
	>
		<Box textStyle='display8LeftAbyss800'>{label}</Box>
		<Select
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			onChange={e => handleChange(e, taxonomy)}
			textStyle='paragraph2LeftAbyss'
			size='lg'
			borderColor='obsidian.100'
			borderWidth='2px'
			iconSize='24px'
			// eslint-disable-next-line @typescript-eslint/naming-convention
			sx={{'&': {paddingRight: '44px'}, '& + div': {right: '0.6rem'}}}
			rootProps={{
				borderRadius: '4px',
				backgroundImage:
            'linear-gradient(to left, var(--chakra-colors-lunar-50) 44px, white 44px 40%, white)',
			}}
		>
			<option value={'all'}>{allLabel}</option>
			{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
			{terms.map((term: TermProps, i: number) => (
				<option value={term.slug} key={`${term}${i}`}>
					{term.name}
				</option>
			))}
		</Select>
	</VStack>
);
