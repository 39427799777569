/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/prefer-optional-chain: 1
 *
 * @format
 */

/** @format */

import FormProps from './FormProps';
import {linkResolver} from '../../lib/linkResolver';

export const formComponentMapping = (data: any): FormProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const textComponent = data?.textcomponent1?.find(
		item => item.fieldGroupName === 'textComponent1',
	);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, prefer-destructuring
	const backgroundColor = data.backgroundColor;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, prefer-destructuring
	const formVariant = data.formVariant;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, prefer-destructuring
	const fieldGroupName = data.fieldGroupName;

	return {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundColor,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		formVariant,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		fieldGroupName,
		textComponent: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: textComponent?.textcomponent1Variant || undefined,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: textComponent?.textcomponent1Heading || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			text: textComponent?.textcomponent1Text || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			label: textComponent?.textcomponent1Label || '',

			buttonLink: null,
		},
	};
};
