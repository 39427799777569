/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, no-mixed-spaces-and-tabs: 1
 *
 * @format
 */

import {ImageProps} from 'next/image';
import {linkResolver} from '../../../lib/linkResolver';
import {HeroBannerProps} from './HeroBannerProps';

export const heroBannerMapping = (data: any): HeroBannerProps | undefined => {
	if (!data) {
		return null;
	}

	const image: ImageProps = data.backgroundImage
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.backgroundImage?.altText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.backgroundImage?.sourceUrl,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.backgroundImage?.mediaDetails.height,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: data.backgroundImage?.mediaDetails.width,
		  }
		: undefined;

	return {
		image,
		backgroundColor: String(data.backgroundColor),
		textColor: String(data.textColor),
		hasBackground: Boolean(data.includeImageOverlay),
		backgroundHeight: String(data.height),
		heroText: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.herotextVariant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.herotextHeading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			supportingText: data.herotextSupportingText,
			button: {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				title: data.herotextButtonLink?.title,
				url: data.herotextButtonLink?.url
					? linkResolver(data.herotextButtonLink.url)
					: undefined,
				isExternal: data.herotextButtonLink?.target === '_blank',
			},
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			badge: {label: data.herotextLabel},
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			backgroundColor: data.backgroundColor || 'white',
		},
	};
};
