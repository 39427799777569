import React, {ReactElement} from 'react';
import {Box, Text} from '@chakra-ui/react';
import {BadgeProps} from './BadgeProps';
import {textStyles} from '../../styles/js/textStyles';

function Badge({size, label, colorScheme}: BadgeProps): ReactElement {
	return (
		<Box
			sx={textStyles.display6LeftLight}
			bgColor={colorScheme}
			px={{base: '4', md: '6'}}
			py='0.5'
			borderRadius='md'
		>
			<Text noOfLines={1}>{label}</Text>
		</Box>
	);
}

export default Badge;
