import {ImageProps} from 'next/image';
import {HeroBannerHomeProps} from './HeroBannerHomeProps';

export const heroBannerHomeMapping = (data: {
	backgroundImage: {
		altText: string;
		mediaDetails: {
			imageHeight: string;
			imageWidth: string;
		};
		image: {
			sourceUrl: string;
		};
	};
	cardHeading: string;
	cardText: string;
	buttonLinks: Array<{
		buttonVariant: string;
		link: {
			target: string;
			title: string;
			url: string;
		};
	}>;
	backgroundColor: string;
}): HeroBannerHomeProps => {
	const image: ImageProps = {
		alt: data.backgroundImage?.altText,
		src: data.backgroundImage?.image?.sourceUrl,
		height: data.backgroundImage?.mediaDetails?.imageHeight,
		width: data.backgroundImage?.mediaDetails?.imageWidth,
	};

	return {
		image,
		cardHeading: data.cardHeading,
		cardText: data.cardText,
		buttonLinks: data?.buttonLinks.map(link => link),
		backgroundColor: data.backgroundColor,
	};
};
