/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, no-negated-condition: 1, no-mixed-spaces-and-tabs: 1, @typescript-eslint/restrict-template-expressions: 1, @typescript-eslint/naming-convention: 1
 *
 * @format
 */

import React, {ReactElement} from 'react';
import {
	Box,
	chakra,
	Flex,
	Heading,
	LinkBox,
	LinkOverlay,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import Link from 'next/link';
import Image from '../Image';
import {CardProps} from './CardProps';
import Badge from '../../components/Badge';
import LinkButton from '../LinkButton';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {layerStyles} from '../../styles/js/layerStyles';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';

function Card({
	card,
	uri,
	terms,
	cardContentPaddingX,
	cardContentPaddingY,
	customLayerStyle,
	containerProps,
	cardStyle,
	isFeatured,
	textBackgroundColor,
}: CardProps): ReactElement {
	const imageHeightValue = '71.5909091%';
	const imageWidthValue = {base: '172px', md: '47.2072072%'};
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const layerStyle = layerStyles[customLayerStyle];
	const cardBorderColor = colors.moss[200];

	const cardStyles = useBreakpointValue({
		base: {},
		md: {
			border: `solid 2px ${cardBorderColor}`,
			boxShadow: '0 0 15px 0 rgba(8, 146, 95, 0.07)',
		},
	}, 'md');

	return (
		<Flex
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			bg={textBackgroundColor ? textBackgroundColor : 'white'}
			direction={containerProps?.flexDirection || 'column'}
			alignItems='stretch'
			justifyContent='stretch'
			minHeight={{md: '273px'}}
			as={LinkBox}
			{...containerProps}
			sx={cardStyles}>
			{
				<Box
					width={
						// eslint-disable-next-line no-negated-condition
						containerProps?.flexDirection !== 'row' ? '100%' : imageWidthValue
					}
					paddingTop={
						// eslint-disable-next-line no-negated-condition
						containerProps?.flexDirection !== 'row' ? imageHeightValue : 0
					}
					flexGrow={0}
					position='relative'
					overflow='hidden'>
					<Image
						src={card.image.src}
						alt={card.image.alt}
						layout='fill'
						objectFit='cover'
					/>
				</Box>
			}
			<Flex
				direction='column'
				alignItems='flex-start'
				justifyContent={
					containerProps?.flexDirection === 'column' ? 'flex-start' : 'center'
				}
				sx={{'> *:last-child': {mb: '0'}}}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				paddingY={cardContentPaddingY}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				paddingX={cardContentPaddingX}
				px={{base: '2', md: '4'}}>
				{terms?.nodes?.length > 0
					&& terms.nodes.map((term: any, index: number) => (
						<Box
							mb={{base: '2', md: '4'}}
							mt={{base: 2, md: 4}}
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							key={`${term.name}-${index}`}>
							{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
							<Badge colorScheme='lime.700' size={'sm'} label={term.name} />
						</Box>
					))}
				<Heading
					sx={textStyles.display7LeftDark}
					mb={{base: '6', md: '6'}}
					as='h2'>
					{card.heading}
				</Heading>
				{card.excerpt && (
					<Box
						textStyle={{
							base: 'paragraph1LeftItalicAbyss',
						}}
						mb={{base: '6', md: '6'}}
						whiteSpace='pre-line'>
						{card.excerpt}
					</Box>
				)}
				<Box>
					<Link href={uri} passHref>
						<LinkButton
							as={LinkOverlay}
							text={card.linkText || 'Read more'}
							isExternal={false}
							variant='link'
							colorScheme='aquaGreen'
							rightIcon={<ChevronRightIcon w={5} h={5} />}
							sx={{
								/* eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment */
								'&::before': {
									...layerStyle,
									bgColor: 'transparent',
									opacity: 0,
								},
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'&:hover::before': {
									opacity: 1,
								},
							}}
						/>
					</Link>
				</Box>
			</Flex>
		</Flex>
	);
}

export default Card;
