/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, no-empty-pattern: 1, @typescript-eslint/no-unsafe-call: 1
 *
 * @format
 */

import {linkResolver} from '../../lib/linkResolver';

export const imageTextColsMapping = (data: any) => {
	if (!data) {
		return null;
	}

	const {mainTitle = '', backgroundColor = '', type = '', column = []} = data;

	const columnsArr = column.map((columnItem, i) => {
		const {
			image: {
				sourceUrl,
				altText,
				mediaDetails: {width, height},
			},

			link,
			description = '',
			subTitle = '',
			title = '',
			titleTextColour,
		} = columnItem;

		const col = {
			image: {

				sourceUrl,

				width,

				height,

				altText,
			},
			link: {

				linkTitle: link?.title || '',
				url: link?.url ? linkResolver(link.url) : '',
			},

			description,

			subTitle: type === 'bio' ? columnItem.jobTitle : subTitle,

			title: type === 'bio' ? columnItem.fullName : title,

			titleTextColour,

			contactNumber: columnItem.contactNumber || '',

			emailAddress: columnItem.emailAddress || '',
		};

		return col;
	});

	return {

		columns: columnsArr,
		variant: type,
		backgroundColor,
		mainTitle,
	};
};
