/**
 * /* eslint-disable @typescript-eslint/no-implicit-any-catch
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/no-children-prop: 1
 *
 * @format
 */

import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	InputGroup,
	Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import {useCallback, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import {FeedbackMsgs} from '../../../patterns/CtqChecklist';
import Spacer from '../../../patterns/Spacer';
import {FormValues} from './FormValuesProps';

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	// subject: Yup.string()
	// 	.min(2, 'Too Short!')
	// 	.max(50, 'Too Long!')
	// 	.required('Required'),
	message: Yup.string().required('Required'),
});

const initialValues: FormValues = {
	name: '',
	email: '',
	subject: 'contact form',
	message: '',
};

export const ContactForm = () => {
	const [success, setSuccess] = useState('');
	const [error, setError] = useState(false);
	const {executeRecaptcha} = useGoogleReCaptcha();

	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('enquiryFormContact');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	const onSubmit = async (
		values: {
			email: string;
			name: string;
			subject: string;
			message: string;
			to?: any[];
		},
		gReCaptchaToken: string,
		resetForm: () => void,
	) => {
		setError(false);
		setSuccess('');
		const message = {
			gReCaptchaToken,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_email: process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL_FROM,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_name: values.name,
			subject: 'ASSC Talk to us',
			text: `
			\tName: ${values.name}
Email: ${values.email}

Talk to us requested from ASSC Website.

Here is the message:

			${values.message}`,
			to: [
				{
					email:
						process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL_TO_MEMBERSHIP,
					type: 'to',
				},
				{
					email: values.email,
					type: 'cc',
				},
			],
		};

		try {
			const validateReCaptchaResponse = await axios.post(
				'/api/validateReCaptcha',
				message,
			);

			// format lines for messages
			const lines = message.text.split('\n');
			const maxLength = Math.max(
				...lines.map(line => line.replace(/\t/g, '    ').length),
			);
			message.text = lines
				.map(line => line.replace(/\t/g, '    ').padEnd(maxLength, ' '))
				.join('\n');

			if (validateReCaptchaResponse.status === 200) {
				const response = await axios.post('/api/contact', message);
				if (response.status === 200) {
					setSuccess(
						`Submitted ${new Intl.DateTimeFormat('en-UK', {
							day: 'numeric',
							month: 'numeric',
							year: '2-digit',
						}).format(new Date(response.headers.date))}`,
					);
				}

				resetForm();
			} else {
				throw new Error();
			}
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
		} catch (e) {
			setError(true);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={ContactSchema}
			onSubmit={async (values, {resetForm}) => {
				const gReCaptchaToken = await addRecaptcha(values);
				await onSubmit(values, gReCaptchaToken, resetForm);
			}}>
			{({errors, touched, isSubmitting}) => (
				<Form style={{width: '100%'}}>
					<Grid
						templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
						gap={{base: '0', md: '6'}}>
						<Field name='name'>
							{({field, form}: any) => (
								<FormControl
									isInvalid={Boolean(errors.name && touched.name)}
									pt='8'
									position='relative'>
									<FormLabel
										textStyle={{
											base: 'displayFormLabel',
										}}
										htmlFor='name'>
										Your Name
									</FormLabel>
									<InputGroup>
										<Input {...field} id='name' placeholder='name' />
									</InputGroup>

									<FormErrorMessage position='absolute'>
										{errors.name}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='email' type='email'>
							{({field, form}) => (
								<FormControl
									isInvalid={Boolean(errors.email && touched.email)}
									pt='8'
									position='relative'>
									<FormLabel
										textStyle={{
											base: 'displayFormLabel',
										}}
										htmlFor='email'>
										Email Address
									</FormLabel>
									<Input {...field} id='email' placeholder='email' />
									<FormErrorMessage position='absolute'>
										{errors.email}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</Grid>
					{/* <Field name='subject'>
						{({field, form}) => (
							<FormControl
								isInvalid={Boolean(errors.subject && touched.subject)}
								pt='8'
								position='relative'>
								<FormLabel textStyle={{
								base: 'displayFormLabel',
							}} htmlFor='subject'>Subject</FormLabel>
								<Input {...field} id='subject' placeholder='subject' />
								<FormErrorMessage position='absolute'>
									{errors.subject}
								</FormErrorMessage>
							</FormControl>
						)}
					</Field> */}
					<Field name='message'>
						{({field, form}) => (
							<FormControl
								isInvalid={Boolean(errors.message && touched.message)}
								pt='8'
								position='relative'>
								<FormLabel
									textStyle={{
										base: 'displayFormLabel',
									}}
									htmlFor='message'>
									Message
								</FormLabel>
								<Textarea {...field} id='message' placeholder='message' />
								<FormErrorMessage position='absolute'>
									{errors.message}
								</FormErrorMessage>
							</FormControl>
						)}
					</Field>

					<FeedbackMsgs success={success} error={error} />
					<Spacer />
					<Button
						type='submit'
						disabled={isSubmitting}
						mt={{base: '0.25rem'}}
						w={{base: '100%', md: 'auto'}}>
						Submit Message
					</Button>
				</Form>
			)}
		</Formik>
	);
};
