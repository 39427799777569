import {VStack, HStack, useBreakpointValue} from '@chakra-ui/react';
import ButtonLink from '../../components/ButtonLink';

const ButtonStack = ({
	linkButtons,
}: {
	linkButtons: Array<{
		buttonVariant: string;
		link: {
			target: string;
			title: string;
			url: string;
		};
	}>;
}) => {
	const LinkButtons = () => (
		<>
			{linkButtons.map(buttonLink => (
				<ButtonLink key={buttonLink.link.url} buttonLink={buttonLink}/>
			))}
		</>
	);

	const MobileButtons = () => (
		<VStack py={6}>
			<LinkButtons/>
		</VStack>
	);

	const DesktopButtons = () => (
		<HStack py={6}>
			<LinkButtons/>
		</HStack>
	);

	return useBreakpointValue({
		base: <MobileButtons/>,
		md: <DesktopButtons/>,
	},
	{ssr: true},
	);
};

export default ButtonStack;
