/**
 * eslint-disable complexity
 *
 * @format
 */

/**
 * /* eslint-disable complexity
 *
 * @format
 */

import React from 'react';
import HeroBanner2Column from './HeroBanners/TwoColumnBanner';
import {heroBanner2ColumnMapping} from './HeroBanners/TwoColumnBanner/heroBanner2ColumnMapping';
import HeroBannerPhotographic from './HeroBanners/PhotographicBanner';
import {heroBannerPhotographicMapping} from './HeroBanners/PhotographicBanner/heroBannerPhotographicMapping';
import SplitImageText from './SplitImageText';
import {splitImageTextMapping} from './SplitImageText/splitImageTextMapping';
import FullImageText from './FullImageText';
import {fullImageTextMapping} from './FullImageText/fullImageTextMapping';
import Spacer from './Spacer';
import {spacerMapping} from './Spacer/spacerMapping';
import TextAndBullets from './TextAndBullets';
import {textAndBulletsMapping} from './TextAndBullets/textAndBulletsMapping';
import Introduction from './Introduction';
import {introductionMapping} from './Introduction/introductionMapping';
import RelatedGrid from './RelatedGrid';
import {ctaBannerMapping} from './CTABanners/ctaBannerMapping';
import CTABanner from './CTABanners';
import {relatedGridMapping} from './RelatedGrid/relatedGridMapping';
import {postGridMapping} from './PostGrid/postGridMapping';
import PostGrid from './PostGrid';
import {titleTextBannerMapping} from './TitleTextBanner/titleTextBannerMapping';
import TitleTextBanner from './TitleTextBanner';
import Image from './Image';
import {imageMapping} from './Image/imageMapping';
import WYSIWYG from './WYSIWYG';
import {wysiwygMapping} from './WYSIWYG/wysiwygMapping';
import HomeHeroBanner from './HeroBanners/HomeHeroBanner';
import ImageTextCols from './ImageTextCols';
import {heroBannerHomeMapping} from './HeroBanners/HomeHeroBanner/heroBannerHomeMapping';
import {featuredQuoteMapping} from './FeaturedQuote/featuredQuoteMapping';
import FeaturedQuote from './FeaturedQuote';
import ImageAndText from './ImageAndText';
import {imageAndTextMapping} from './ImageAndText/imageAndTextMapping';
import {imageTextColsMapping} from './ImageTextCols/imageTextColsMapping';
import Accordions from './Accordions';
import {accordionMapping} from './Accordions/accordionMapping';
import ButtonGrid from './ButtonGrid';
import {buttonGridMapping} from './ButtonGrid/buttonGridMapping';
import {formComponentMapping} from './FormComponent/formComponentMapping';
import FormComponent from './FormComponent';
import LogoGrid from './logoGrid';
import {logoGridMapping} from './logoGrid/logoGridMapping';
import BoardMembersList from './BoardMembersList';
import {boardMembersListMapping} from './BoardMembersList/boardMembersListMapping';
import ctqChecklistMapping from './CtqChecklist/ctqChecklistMapping';
import CtqChecklist from './CtqChecklist';
import {imageTextFullWidthMapping} from './imageTextFullWidth/imageTextFullWidthMapping';
import ImageTextFullWidth from './imageTextFullWidth';
import {heroBannerMapping} from './HeroBanners/HeroBanner/heroBannerMapping';
import HeroBanner from './HeroBanners/HeroBanner';
import PartnerCard from './PartnerCard';
import {partnerCardMapping} from './PartnerCard/partnerCardMapping';
import LatestNews from './LatestNews';
import PaginatedPostGrid from './PaginatedPostGrid';
import {paginatedPostGridMapping} from './PaginatedPostGrid/paginatedPostGridMapping';

type PatternProps = {
	fieldGroupName: string;
	postType?: string;
	post?: any;
	page?: any;
	posts?: any;
	activeSubscriptions?: number;
};

function Patterns({fieldGroupName, post, activeSubscriptions}: PatternProps) {
	if (!post) {
		return null;
	}

	/* eslint operator-linebreak: 1 */
	const flexibleContentName = fieldGroupName.substr(
		fieldGroupName.indexOf('_Pagelayout_FlexibleContent_') +
			'_Pagelayout_FlexibleContent_'.length,
	);

	try {
		switch (flexibleContentName) {
			case 'TitleTextBanner': {
				const data = titleTextBannerMapping(post);
				return <TitleTextBanner {...data} />;
			}

			case 'Image': {
				const data = imageMapping(post);
				return <Image {...data} />;
			}

			case 'Wysiwyg': {
				const data = wysiwygMapping(post);
				return <WYSIWYG {...data} />;
			}

			case 'CtaBanner': {
				const data = ctaBannerMapping(post);
				return <CTABanner {...data} />;
			}

			case 'PostGrid': {
				const data = postGridMapping(post);
				return <PostGrid {...data} />;
			}

			case 'LayoutHeroBanner2Column': {
				const data = heroBanner2ColumnMapping(post);
				return <HeroBanner2Column {...data} />;
			}

			case 'HeroBannerPhotographic': {
				const data = heroBannerPhotographicMapping(post);
				return <HeroBannerPhotographic {...data} />;
			}

			case 'SplitImageText': {
				const data = splitImageTextMapping(post);
				return <SplitImageText {...data} />;
			}

			case 'FullImageText': {
				const data = fullImageTextMapping(post);
				return <FullImageText {...data} />;
			}

			case 'Spacer': {
				const data = spacerMapping(post);
				return <Spacer {...data} />;
			}

			case 'Bullets': {
				const data = textAndBulletsMapping(post);
				return <TextAndBullets {...data} />;
			}

			case 'Introduction': {
				const data = introductionMapping(post);
				return <Introduction {...data} />;
			}

			case 'RelatedGrid': {
				const data = relatedGridMapping(post);
				return <RelatedGrid {...data} />;
			}

			case 'HomeHeroBanner': {
				const data = heroBannerHomeMapping(post);
				return <HomeHeroBanner {...data} />;
			}

			case 'FeaturedQuote': {
				const data = featuredQuoteMapping(post);
				return <FeaturedQuote {...data} />;
			}

			case 'ImageTextColumns': {
				const data = imageTextColsMapping(post);
				return <ImageTextCols {...data} />;
			}

			case 'ImageAndText': {
				const data = imageAndTextMapping(post);
				return <ImageAndText {...data} />;
			}

			case 'Accordions': {
				const data = accordionMapping(post);
				return <Accordions {...data} />;
			}

			case 'ButtonGrid': {
				const data = buttonGridMapping(post);
				return <ButtonGrid {...data} />;
			}

			case 'Forms': {
				const data = formComponentMapping(post);
				return <FormComponent {...data} />;
			}

			case 'LogoGrid': {
				const data = logoGridMapping(post);
				return <LogoGrid {...data} />;
			}

			case 'BoardMembersList': {
				const data = boardMembersListMapping(post);
				return <BoardMembersList {...data} />;
			}

			case 'CtqChecklist': {
				const data = ctqChecklistMapping(post);
				return <CtqChecklist {...data} />;
			}

			case 'ImageTextFullWidth': {
				const data = imageTextFullWidthMapping(post);
				return <ImageTextFullWidth {...data} />;
			}

			case 'HeroBanner': {
				const data = heroBannerMapping(post);
				return <HeroBanner {...data} />;
			}

			case 'PartnerCard': {
				const data = partnerCardMapping(post);
				return (
					<PartnerCard {...data} activeSubscriptions={activeSubscriptions} />
				);
			}

			case 'LatestNews': {
				return <LatestNews />;
			}

			case 'PaginatedPostGrid': {
				const data = paginatedPostGridMapping(post);
				return <PaginatedPostGrid {...data} />;
			}

			default:
				console.error(
					flexibleContentName,
					' is not defined in patterns src/patterns.ts',
				);
				break;
		}
	} catch (e: unknown) {
		console.error(`Issue with pattern: ${flexibleContentName}`);
		console.error(e);
		throw e;
	}

	return null;
}

export default Patterns;
