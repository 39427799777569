/** @format */
import {
	Box,
	Container,
	Text,
	SimpleGrid,
	useMultiStyleConfig,
	UnorderedList,
	ListItem,
	Circle,
	HStack,
} from '@chakra-ui/react';
import {textStyles} from '../../styles/js/textStyles';
import {ReactElement} from 'react';
import {BoardMembersListProps} from './boardMembersListProps';
import {colors} from '../../styles/js/colors';

const BoardMembersList = ({
	heading,
	text,
	people,
}: BoardMembersListProps): ReactElement => {
	if (!heading) {
		return null;
	}

	const styles = useMultiStyleConfig('boardMembersList');
	/* eslint prefer-destructuring: 1 */
	const headingTextStyle = textStyles.display4LeftDark;
	const bodyTextStyle = textStyles.paragraph1LeftItalicDark;
	const listTextStyle = textStyles.listDarkGreen;
	const {bodyTextContainer} = styles;
	const {headingTextContainer} = styles;
	const {bulletsContainer} = styles;

	const Bullets = () => (
		/* eslint react/prop-types: 1 */
		<UnorderedList spacing={3} listStyleType='none' ml={0}>
			<SimpleGrid columns={{base: 1, md: 2}}>
				{people.map((person, index) => (
					<ListItem key={index} sx={{padding: '10px'}}>
						<HStack spacing='3'>
							<Circle
								size={'6px'}
								backgroundColor={colors.aquaGreen[700]}
								flexShrink={0}
							/>
							<Box sx={listTextStyle} color={colors.aquaGreen[700]}>
								{person.title}
							</Box>
						</HStack>
					</ListItem>
				))}
			</SimpleGrid>
		</UnorderedList>
	);

	return (
		<Box as='section' py={{base: '3rem'}}>
			<Container maxW='container.xl' mx='auto' my={10}>
				<Text sx={{...headingTextStyle, ...headingTextContainer}}>
					{heading}
				</Text>
				<Box sx={bodyTextContainer}>
					<Text sx={bodyTextStyle}>{text}</Text>
				</Box>
				<Box sx={bulletsContainer} maxW='container.md'>
					<Bullets />
				</Box>
			</Container>
		</Box>
	);
};

export default BoardMembersList;
