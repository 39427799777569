/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

/** @format */

import {ChevronRightIcon} from '@chakra-ui/icons';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	HStack,
	LinkBox,
	LinkOverlay,
	Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import {ReactElement} from 'react';
import useUser from '../../../lib/useUser';
import logoWhiteStaticImport from '../../../public/images/logo_white.jpeg';
import downloadStaticImport from '../../../public/images/mask@3x.webp';
import TextComponent1 from '../../components/TextComponent/TextComponent1';
import {colors} from '../../styles/js/colors';
import {ButtonGridProps, ButtonProps} from './buttonGridProps';

function ButtonGrid({
	backgroundColor,
	variant,
	textComponent,
	buttons,
}: ButtonGridProps): ReactElement {
	if (!backgroundColor) {
		return null;
	}

	const hideUpgradeButton = false;

	const getLinkBoxPadding = variant => {
		if (variant === 'download') {
			return 0;
		}

		if (variant === 'forum') {
			return '1.6rem 1.5rem 1.1rem 1.5rem';
		}

		return '2.5rem 1.5rem';
	};

	return (
		<Box
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'
			py={{base: '3rem'}}>
			<Container maxW='container.xl' mx='auto'>
				{textComponent ? (
					<TextComponent1
						containerProps={{
							textAlign: 'left',
							alignItems: 'flex-start',
						}}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						heading={textComponent.heading}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						text={textComponent.text}
						label={textComponent.label}
						buttonLink={textComponent.buttonLink}
						variant={textComponent.variant || 'Dark'}
						overline={textComponent.overline || ''}></TextComponent1>
				) : null}

				<Grid
					pt={{base: textComponent ? '3.5rem' : '0'}}
					templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}}
					gap={{base: '1rem', md: '1.75rem'}}>
					{buttons.map((button, i) => {
						if (button.url.includes('/members/upgrade-membership')) {
							if (hideUpgradeButton) {
								return null;
							}
						}

						return (
							<GridItem
								key={`grid-${variant}-${i}`}
								transition='all .2s ease-in-out'
								_hover={{
									// boxShadow: 'dark-lg',
									boxShadow: '0 15px 15px 5px rgb(8 146 95 / 7%)',
									transform: 'translate(0, -2px)',
								}}
								boxShadow='0 0 15px 0 rgba(8, 146, 95, 0.07)'
								border={`solid 2px ${colors.aquaGreen[400]}`}>
								<LinkBox
									p={{
										base: getLinkBoxPadding(variant),
										md: '',
									}}
									bg='none'
									w='100%'
									_hover={{
										bg: 'none',
									}}>
									<LinkOverlay
										href={button.url}
										isExternal={Boolean(button.isExternal)}>
										<GridContent button={button} variant={variant} />
									</LinkOverlay>
								</LinkBox>
							</GridItem>
						);
					})}
				</Grid>
			</Container>
		</Box>
	);
}

export default ButtonGrid;

const GridContent = ({
	button,
	variant,
}: {
	button: ButtonProps;
	variant: string;
}) => {
	if (variant === 'default') {
		return (
			<Flex
				justifyContent={{base: 'space-between'}}
				alignItems={{base: 'center'}}
				flex='1'>
				<Flex
					textStyle={{
						base: 'display7LeftDark',
						md: 'display7LeftDark',
					}}>
					{button.title}
				</Flex>
				<ChevronRightIcon alignSelf='center' marginLeft='0.5rem' w={5} h={5} />
			</Flex>
		);
	}

	if (variant === 'forum') {
		return (
			<>
				<Box>
					<HStack pb={{base: '5px', md: '10px'}}>
						<Flex
							textStyle={{
								base: 'display7LeftDark',
								md: 'display7LeftDark',
							}}
							flex='1'>
							{button.title}
						</Flex>
						{button.replies === '0' ? (
							<Flex
								textStyle={{
									base: 'displayCustom1LeftDark',
									md: 'displayCustom1LeftDark',
								}}>
								No reply
							</Flex>
						) : (
							<Flex
								textStyle={{
									base: 'displayCustom1LeftDark',
									md: 'displayCustom1LeftDark',
								}}>
								{button.replies} {button.replies === '1' ? 'reply' : 'replies'}
							</Flex>
						)}
					</HStack>
					<HStack pb={{base: '4px', md: '8px'}}>
						<Flex
							textStyle={{
								base: 'paragraph2LeftItalicDark',
								md: 'paragraph2LeftItalicDark',
							}}>
							{button.name}
						</Flex>
					</HStack>
					<HStack>
						<Flex
							textStyle={{
								base: 'display7LeftDark',
								md: 'display7LeftDark',
							}}
							flex='1'>
							{button.dateTime}
						</Flex>
						<Flex
							textStyle={{
								base: 'buttonLeftGreen',
								md: 'buttonLeftGreen',
							}}>
							Read More{' '}
							<ChevronRightIcon
								alignSelf='center'
								marginLeft='0.5rem'
								color={`${colors.aquaGreen[500]}`}
								w={5}
								h={5}
							/>
						</Flex>
					</HStack>
				</Box>
			</>
		);
	}

	return (
		<Flex
			justifyContent={{base: 'space-between'}}
			alignItems={{base: 'center'}}
			flex='1'>
			<Box alignSelf='center' h='auto'>
				<Image
					src={logoWhiteStaticImport}
					alt='assc logo'
					width='88'
					height='88'
					layout='fixed'
				/>
			</Box>
			<HStack
				px={{base: '1rem'}}
				justifyContent={{base: 'space-between'}}
				alignItems={{base: 'center'}}
				flex='1'>
				<Text
					noOfLines={2}
					textStyle={{
						base: 'display7LeftDark',
						md: 'display7LeftDark',
					}}>
					{button.title}
				</Text>
				<Box alignSelf='center' marginRight='0.5rem'>
					<Image
						src={downloadStaticImport}
						alt='download file'
						width='22'
						height='22'
						layout='fixed'
					/>
				</Box>
			</HStack>
		</Flex>
	);
};
