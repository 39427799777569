/** @format */

import React, {ReactElement} from 'react';
import {
	Box,
	Container,
	Grid,
	GridItem,
	LinkBox,
	LinkOverlay,
} from '@chakra-ui/react';
import Image from 'next/image';
import {LogoGridProps} from './LogoGridProps';

function LogoGrid({backgroundColor, imagesLinks}: LogoGridProps): ReactElement {
	return (
		<Box
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'>
			<Container maxW='container.xl' mx='auto'>
				<Grid
					pb='20'
					justifyContent={{base: 'center'}}
					alignItems={{base: 'center'}}
					templateColumns={{
						base: 'repeat(auto-fit, minmax(min-content, 180px))',
					}}
					autoRows={{
						base: '100px',
					}}
					gap='8'>
					{imagesLinks.map((item, i) => (
						<GridItem
							key={`${i}-logo-grid`}
							bg='white'
							transition='all .2s ease-in-out'
							_hover={{
								boxShadow: '0 15px 15px 5px rgb(8 146 95 / 7%)',
								transform: 'translate(0, -5px)',
							}}
							boxShadow='0 0 15px 0 rgba(8, 146, 95, 0.07)'>
							<LinkBox>
								<LinkOverlay
									href={item?.link?.url}
									isExternal={Boolean(item?.link?.isExternal)}>
									{item.image ? (
										<Image
											src={item.image?.src}
											alt={item.image?.alt}
											width={item.image.width}
											height={item.image.height}
											layout='responsive'
											objectFit='contain'
										/>
									) : null}
								</LinkOverlay>
							</LinkBox>
						</GridItem>
					))}
				</Grid>
			</Container>
		</Box>
	);
}

export default LogoGrid;
