/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import {WYSIWYGProps} from './WYSIWYGProps';

export const wysiwygMapping = (data: any): WYSIWYGProps | undefined => {
	if (!data) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {wysiwyg} = data;

	const wysiwygData = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		html: wysiwyg,
	};

	return wysiwygData;
};
