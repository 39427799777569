import {Box, SimpleGrid, LinkOverlay, Skeleton, useStyleConfig, Container} from '@chakra-ui/react';
import {Card} from '../../components/Card/Card';
import LinkButton from '../../components/LinkButton';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {Badge} from './index';

export const SkeletonLatestNews = () => {
	const cardStyle = useStyleConfig('Card', {variant: 'Post'});
	return (
		<Container maxW='container.xl' mx='auto'>
			<SimpleGrid columns={{base: 1, md: 2}} spacing={4} h='100%'>
				{Array.from(Array(2)).map((_, index) => (
					<Card key={index} sx={cardStyle}>
						<SimpleGrid columns={2} spacing={10}>
							<Skeleton height='100%' width='100%' />
							<Box m='2rem'>
								<Badge text={'News'} />
								<Skeleton minH='3rem' width='100%' mb='1rem'/>
								<LinkButton
									as={LinkOverlay}
									text={'Read more'}
									isExternal={false}
									variant='link'
									colorScheme='aquaGreen'
									rightIcon={<ChevronRightIcon w={5} h={5} />}
									sx={{
										// eslint-disable-next-line @typescript-eslint/naming-convention
										'&::before': {
											bgColor: 'transparent',
											opacity: 0,
										},
										// eslint-disable-next-line @typescript-eslint/naming-convention
										'&:hover::before': {
											opacity: 1,
										},
									}} />
							</Box>
						</SimpleGrid>
					</Card>
				))}
			</SimpleGrid>
		</Container>
	);
};
