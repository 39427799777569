/**
 * eslint-disable @typescript-eslint/prefer-optional-chain
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/prefer-optional-chain: 1
 *
 * @format
 */

import React, {ReactElement} from 'react';
import Image from '../../components/Image';
import {chakra, Box, Flex} from '@chakra-ui/react';
import LinkButton from '../../components/LinkButton';
import Link from 'next/link';
import Badge from '../../components/Badge';
import {HeroTextProps} from './HeroTextProps';

function HeroText({
	heading,
	supportingText,
	icon,
	buttonLink,
	variant,
	paddingTop,
	paddingBottom,
	badge,
}: HeroTextProps): ReactElement {
	const color = variant === 'light' ? 'Light' : 'Dark';

	return (
		<Flex
			direction='column'
			alignItems='flex-start'
			pr={{xl: '24'}}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			pt={paddingTop ? paddingTop : {base: '0'}}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			pb={paddingBottom ? paddingBottom : {base: '0'}}
			sx={{'> *:last-child': {mb: '0'}}}>
			{icon?.src && (
				<Box mb='4'>
					<Image
						src={icon.src}
						alt={icon.alt}
						width={icon.width}
						height={icon.height}
					/>
				</Box>
			)}
			{badge?.label && (
				<Box mb={{base: '4', md: '5'}}>
					<Badge
						colorScheme='abyss.600'
						label={badge.label}
						size={badge.size}
					/>
				</Box>
			)}
			{heading ? (
				<chakra.h1
					pr={{lg: '1.5em'}}
					textStyle={{
						base: `display5Left${color}`,
						md: `display3Left${color}`,
					}}
					mb={{base: '6'}}
					whiteSpace='pre-line'>
					{heading}
				</chakra.h1>
			) : null}
			{supportingText && (
				<Box
					textStyle={{
						base: `paragraph1LeftItalic${color}`,
					}}
					mb={{base: '6', md: '8'}}
					whiteSpace='pre-line'>
					{supportingText}
				</Box>
			)}

			{buttonLink?.url && (
				<Box>
					<Link href={buttonLink.url} passHref>
						<LinkButton
							color={color}
							text={buttonLink.title}
							isExternal={Boolean(buttonLink.isExternal)}
							variant='solid'
						/>
					</Link>
				</Box>
			)}
		</Flex>
	);
}

export default HeroText;
