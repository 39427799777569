/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1, no-negated-condition: 1, @typescript-eslint/restrict-template-expressions: 1, no-mixed-spaces-and-tabs: 1, @typescript-eslint/naming-convention: 1
 *
 * @format
 */

import React, {ReactElement} from 'react';
import {Box, chakra, Flex, LinkBox, Text, LinkOverlay} from '@chakra-ui/react';
import Link from 'next/link';
import Image from '../Image';
import {CardProps} from './CardProps';
import Badge from '../Badge';
import LinkButton from '../LinkButton';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {layerStyles} from '../../styles/js/layerStyles';
import {colors} from '../../styles/js/colors';

function CardText({
	variant,
	card,
	uri,
	terms,
	cardContentPaddingX,
	cardContentPaddingY,
	customLayerStyle,
	containerProps,
}: CardProps): ReactElement {
	const imageHeightValue = '71.5909091%';
	const imageWidthValue = {base: '172px', md: '47.2072072%'};
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const layerStyle = layerStyles[customLayerStyle];

	return (
		<Flex
			direction={containerProps.flexDirection || 'column'}
			alignItems='stretch'
			justifyContent='stretch'
			minHeight={{md: '273px'}}
			as={LinkBox}
			{...containerProps}>
			{
				<Box
					width={
						// eslint-disable-next-line no-negated-condition
						containerProps.flexDirection !== 'row' ? '100%' : imageWidthValue
					}
					paddingTop={
						// eslint-disable-next-line no-negated-condition
						containerProps.flexDirection !== 'row' ? imageHeightValue : 0
					}
					flexGrow={0}
					position='relative'
					overflow='hidden'>
					<Image
						src={card.image.src}
						alt={card.image.alt}
						layout='fill'
						objectFit='cover'
					/>
				</Box>
			}
			<Flex
				direction='column'
				alignItems='flex-start'
				justifyContent={
					containerProps.flexDirection === 'column' ? 'flex-start' : 'center'
				}
				sx={{'> *:last-child': {mb: '0'}}}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				paddingY={cardContentPaddingY}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				paddingX={cardContentPaddingX}>
				{terms?.nodes?.length > 0
					&& terms.nodes.map((term: any, index: number) => (
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						<Box mb={{base: '2', md: '4'}} key={`${term.name}-${index}`}>
							{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
							<Badge colorScheme='abyss.600' size={'sm'} label={term.name} />
						</Box>
					))}

				<chakra.h2
					textStyle={
						variant === 'bio'
							? // prettier-ignore
							  {

								base: 'display6LeftDark',

								md: 'display6LeftDark',
							  }
							: // prettier-ignore
							  {

								base: 'display6LeftDarkGreen',

								md: 'display6LeftDarkGreen',
							  }
					}
					sx={{
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						textColor: card.headingColour,
					}}
					mb={{base: '2', md: '2'}}>
					{card.heading}
				</chakra.h2>

				{card.subTitle ? (
					<Text
						noOfLines={[4, 6, 10]}
						textStyle={{
							base: 'paragraph1LeftItalicDark',
						}}
						mb={{base: '6', md: '6'}}
						whiteSpace='pre-line'>
						{card.subTitle}
					</Text>
				) : null}

				{card.excerpt ? (
					<Text
						noOfLines={[4, 6, 10]}
						textStyle={{
							base: 'paragraph1LeftItalicDark',
						}}
						mb={{base: '4', md: '4'}}
						whiteSpace='pre-line'>
						{card.excerpt}
					</Text>
				) : null}

				{card.contactNumber ? (
					<Text
						textStyle={{
							base: 'paragraph1LeftItalicDark',
						}}
						// mb={{base: '2', md: '2'}}
						whiteSpace='pre-line'>
						Tel: {card.contactNumber}
					</Text>
				) : null}

				{card.emailAddress ? (
					<Text
						textStyle={{
							base: 'paragraph1LeftItalicDark',
						}}
						mb={{base: '2', md: '2'}}
						whiteSpace='pre-line'>
						Email: {card.emailAddress}
					</Text>
				) : null}

				{uri && card.linkText ? (
					<Box>
						<Link href={uri} passHref>
							<LinkButton
								as={LinkOverlay}
								text={card.linkText || 'Read more'}
								isExternal={false}
								variant='link'
								colorScheme='blue'
								rightIcon={<ChevronRightIcon w={5} h={5} />}
								sx={{
									/* eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment */
									'&::before': {
										...layerStyle,
										bgColor: 'transparent',
										opacity: 0,
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover::before': {
										opacity: 1,
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover': {
										color: colors.aquaGreen[500],
									},
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:active': {
										color: colors.aquaGreen[700],
									},
								}}
							/>
						</Link>
					</Box>
				) : null}
			</Flex>
		</Flex>
	);
}

export default CardText;
