/** @format */
// https://prateeksurana.me/blog/integrating-recaptcha-with-next/

import {Box, Container, Flex, Grid, space} from '@chakra-ui/react';
import {ContactForm} from '../../components/Forms/Contact';
import {ReferMemberForm} from '../../components/Forms/ReferMember';
import {EditUserForm} from '../../components/Forms/EditUser';
import {PaymentsSubscriptionSignposting} from '../../components/Forms/PaymentsSubscriptionSignposting';
import FormProps from './FormProps';
import TextComponent1 from '../../components/TextComponent/TextComponent1';

const FormComponent = ({
	formVariant,
	backgroundColor,
	textComponent,
}: FormProps) => {
	if (!formVariant) {
		return null;
	}

	return (
		<Box
			backgroundColor={backgroundColor || 'white'}
			as='section'
			overflow='hidden'>
			<Container maxW='container.xl' mx='auto'>
				{textComponent ? (
					<TextComponent1
						containerProps={{
							textAlign: 'left',
							alignItems: 'flex-start',
						}}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						heading={textComponent.heading}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						text={textComponent.text}
						label={textComponent.label}
						buttonLink={textComponent.buttonLink}
						variant={textComponent.variant || 'Dark'}
						overline={textComponent.overline || ''}></TextComponent1>
				) : null}

				<Box
					maxW={{base: 'container.xl', md: 'container.md'}}
					ml={{base: 'auto', md: 0}}
					mr='auto'>
					{formVariant === 'talkToUs' ? <ContactForm /> : null}
					{formVariant === 'referMember' ? <ReferMemberForm /> : null}

					{formVariant === 'editUser' ? <EditUserForm /> : null}

					{formVariant === 'paymentsSubscriptionSignposting' ? (
						<PaymentsSubscriptionSignposting />
					) : null}

					{formVariant === 'login' ? null : null}
					{formVariant === 'register' ? null : null}
					{formVariant === 'lostPassord' ? null : null}
				</Box>
			</Container>
		</Box>
	);
};

export default FormComponent;
