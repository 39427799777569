/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import {ImageProps} from 'next/image';
import {linkResolver} from '../../../lib/linkResolver';
import {HeroBannerPhotographicProps} from './HeroBannerPhotographicProps';

export const heroBannerPhotographicMapping = (
	data: any,
): HeroBannerPhotographicProps | undefined => {
	if (!data) {
		return null;
	}

	const image: ImageProps = data.backgroundImage
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.backgroundImage?.altText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.backgroundImage?.sourceUrl,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.backgroundImage?.mediaDetails.height,

		}
		: undefined;
	return {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		hasBackground: data.backgroundImage?.sourceUrl || false,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		backgroundHeight: data.height,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		overlayTextAlignment: data.textAlignment,
		image,
		heroText: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.herotextVariant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.herotextHeading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			supportingText: data.herotextSupportingText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink: data.herotextButtonLink
				? {
					...data.herotextButtonLink,
					url: linkResolver(data.herotextButtonLink.url)}
				: undefined,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			badge: {label: data.herotextLabel},
		},
	};
};
