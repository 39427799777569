/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/no-children-prop: 1
 *
 * @format
 */

import {Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react';
import {PaymentsForm} from './PaymentsForm';
import {SignpostingForm} from './SignpostingForm';
import {SubscriptionForm} from './SubscriptionForm';
import {useCallback, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from 'axios';

const tabDataArr = [
	{
		title: 'Subscription',
		key: 'subscription',
	},
	{
		title: 'Payments',
		key: 'payments',
	},
	{
		title: 'Signposting',
		key: 'signposting',
	},
];

export const PaymentsSubscriptionSignposting = () => {
	const [success, setSuccess] = useState('');
	const [error, setError] = useState(false);
	const {executeRecaptcha} = useGoogleReCaptcha();

	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('enquiryFormPaymentSubscriptionSignposting');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	const onSubmit = async (
		values: any,
		gReCaptchaToken: string,
		resetForm: () => void,
	) => {
		setError(false);
		console.log('PaymentsSubscriptionSignposting ', values);

		try {
			const validateReCaptchaResponse = await axios.post(
				'/api/validateReCaptcha',
				{gReCaptchaToken},
			);

			if (validateReCaptchaResponse.status === 200) {
				// 	const response = await axios.post('/api/paymentSubscriptionSignPosting', message);
				// 	if (response.status === 200) {
				// 		setSuccess(
				// 			`Submitted ${new Intl.DateTimeFormat('en-UK', {
				// 				day: 'numeric',
				// 				month: 'numeric',
				// 				year: '2-digit',
				// 			}).format(new Date())}`,
				// 		);
				// 	}
				// 	resetForm();
			} else {
				throw new Error();
			}
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
		} catch (err) {
			setError(true);
		}
	};

	return (
		<Tabs isLazy>
			<TabList
				border={{base: 'none'}}
				textStyle={{
					base: 'paragraph1LeftDark2',
				}}>
				{tabDataArr.map((tabItem, i) => (
					<Tab
						key={`${i}-tab-payments`}
						_selected={{
							borderBottom: '2px solid var(--chakra-colors-aquaGreen-400)',
						}}>
						{tabItem.title}
					</Tab>
				))}
			</TabList>

			<TabPanels pt='3rem'>
				{tabDataArr.map((tab, index) => (
					<TabPanel p={4} key={index}>
						{tab.key === 'subscription' ? (
							<SubscriptionForm
								success={success}
								error={error}
								onSubmit={async (values, {resetForm}) => {
									const gReCaptchaToken = await addRecaptcha(values);
									await onSubmit(values, gReCaptchaToken, resetForm);
								}}
							/>
						) : null}

						{tab.key === 'payments' ? (
							<PaymentsForm
								success={success}
								error={error}
								onSubmit={async (values, {resetForm}) => {
									const gReCaptchaToken = await addRecaptcha(values);
									await onSubmit(values, gReCaptchaToken, resetForm);
								}}
							/>
						) : null}

						{tab.key === 'signposting' ? (
							<SignpostingForm
								success={success}
								error={error}
								onSubmit={async (values, {resetForm}) => {
									const gReCaptchaToken = await addRecaptcha(values);
									await onSubmit(values, gReCaptchaToken, resetForm);
								}}
							/>
						) : null}
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
};
