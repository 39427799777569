/**
 * eslint-disable @typescript-eslint/no-unsafe-return
 *
 * @format
 */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/**
 * /* eslint-disable @typescript-eslint/no-implicit-any-catch
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/no-children-prop: 1
 *
 * @format
 */

import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem,
	Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import {useCallback, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import useUser from '../../../../lib/useUser';
import {FeedbackMsgs} from '../../../patterns/CtqChecklist';
import Spacer from '../../../patterns/Spacer';
import {FormikField} from '../../FormikField/FormikField';
import {FormValues} from './FormValuesProps';
const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
});

const initialValues: FormValues = {
	name: '',
	email: '',
	businessName: '',
	phone: '',
	message: '',
};

export const ReferMemberForm = () => {
	const [success, setSuccess] = useState('');
	const [error, setError] = useState(false);
	const {executeRecaptcha} = useGoogleReCaptcha();
	const {user, mutateUser} = useUser();

	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('enquiryFormReferMember');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	const getGlobalMergeVars = formValues => {
		const globalMergeVars: any = [
			{
				name: 'referrer', // a person who makes a referral
				content: user?.firstname || user.name || user.email,
			},
			{
				name: 'referrerFirstName',
				content: user?.firstname || '',
			},
			{
				name: 'referrerLastName',
				content: user.lastname || '',
			},
			{
				name: 'referrerEmail',
				content: user.email,
			},
			{
				name: 'referee',
				content: formValues.name,
			},
			{
				name: 'refereeEmail',
				content: formValues.email,
			},
			{
				name: 'refereeBusinessName',
				content: formValues.businessName,
			},
			{
				name: 'refereePhone',
				content: formValues.phone,
			},
			{
				name: 'message',
				content: formValues.message,
			},
		];

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return globalMergeVars;
	};

	const onSubmit = async (
		values: {
			email: string;
			name: string;
			message: string;
			to?: any[];
		},
		gReCaptchaToken: string,
		resetForm: () => void,
	) => {
		setError(false);
		setSuccess('');
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const global_merge_vars = getGlobalMergeVars(values);
		const message = {
			gReCaptchaToken,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			template_name: 'ASSC Recommend a member',
			tags: ['ASSC', 'refer member'],
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_email: process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL_FROM,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			from_name: 'ASSC referral',
			subject: 'Refer member',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			global_merge_vars,
			to: [
				{
					email: user.email,
					// email: process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL_TO,
					type: 'to',
				},
				{
					email:
						process.env.NEXT_PUBLIC_MAILCHIMP_TRANSACTIONAL_MAIL_TO_MEMBERSHIP,
					type: 'cc',
				},
			],
		};

		try {
			const validateReCaptchaResponse = await axios.post(
				'/api/validateReCaptcha',
				message,
			);

			if (validateReCaptchaResponse.status === 200) {
				const response = await axios.post('/api/sendEmailTemplate', message);
				if (response.data.success) {
					setSuccess(
						`Submitted ${new Intl.DateTimeFormat('en-UK', {
							day: 'numeric',
							month: 'numeric',
							year: '2-digit',
						}).format(new Date(response.headers.date))}`,
					);
					resetForm();
				} else {
					throw new Error('Mail not sent due to an unexpected error.');
				}
			} else {
				throw new Error('ReCaptcha failed');
			}
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
		} catch (e) {
			console.log('error for refer a member: ', e);
			setError(true);
		}
	};

	return (
		<>
			<Box
				p={{base: '0'}}
				pt={{base: '2.5rem'}}
				textStyle={{
					base: 'display5LeftDark',
				}}>
				Your Recommendation
			</Box>
			<Formik
				initialValues={initialValues}
				validationSchema={ContactSchema}
				onSubmit={async (values, {resetForm}) => {
					const gReCaptchaToken = await addRecaptcha(values);
					await onSubmit(values, gReCaptchaToken, resetForm);
				}}>
				{({errors, touched, isSubmitting}) => (
					<Form style={{width: '100%'}}>
						<Grid
							templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
							gap={{base: '0', md: '6'}}>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Their name'}
									nameKey={'name'}
									placeholder=''
								/>
							</GridItem>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Their business or property name'}
									nameKey={'businessName'}
									placeholder=''
								/>
							</GridItem>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Their email'}
									nameKey={'email'}
									placeholder=''
								/>
							</GridItem>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Their contact number'}
									nameKey={'phone'}
									autoComplete='tel'
									placeholder=''
								/>
							</GridItem>
							<GridItem>
								<Field name='message'>
									{({field, form}) => (
										<FormControl
											isInvalid={Boolean(errors.message && touched.message)}
											pt={'8'}
											position='relative'>
											<FormLabel
												htmlFor='message'
												textStyle={{
													base: 'displayFormLabel',
												}}>
												Message
											</FormLabel>
											<Textarea {...field} id='message' placeholder='' />
											<FormErrorMessage position='absolute'>
												{errors.message}
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</GridItem>
						</Grid>

						<FeedbackMsgs success={success} error={error} />
						<Spacer />
						<Button type='submit' disabled={isSubmitting}>
							Submit recommendation
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
};
