/**
 * /* eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/** @format */

import {
	Box,
	Button,
	ButtonGroup,
	FormLabel,
	Grid,
	GridItem,
} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {FormikField} from '../../FormikField/FormikField';
import {OptionListProps} from '../../FormikField/OptionProps';
import {FormikFieldSelect} from '../../FormikField/Select';
const signPostingTypes: OptionListProps = [
	{value: '', text: 'Select your option', disabled: true},
	{value: 'cancel', text: 'Selling/sold business'},
	{value: 'cancel', text: 'Converting to long-term lets'},
	{value: 'cancel', text: 'Ill Health'},
	{value: 'cancel', text: 'No longer self-catering'},
	{value: 'cancel', text: 'Bereavement'},
	{value: 'cancel', text: 'Financial Reasons'},
	{value: 'cancel', text: 'Planning Permission Refused'},
	{value: 'cancel', text: 'Short Term Let License Refused'},
	{value: 'cancel', text: 'Cost associated with Short Term Let Regulations'},
	{value: 'cancel', text: 'Lack of bookings from EmbraceScotland'},
	{value: 'cancel', text: 'Retiring'},
	{value: 'cancel', text: 'Other'},
];

export const PaymentsForm: React.FC<{
	onSubmit: any;
	success: string;
	error: boolean;
}> = ({onSubmit, success, error}) => {
	const [formErrorMsg, setFormErrorMsg] = useState('');
	const [cancelPressed, setCancelPressed] = useState(false);

	const initialValues: {nameOfProperty: string; signPostingType: string} = {
		nameOfProperty: '',
		signPostingType: '',
	};

	const validationSchema = Yup.object().shape({
		nameOfProperty: Yup.string().required('Required'),
		signPostingType: Yup.string().required('Required'),
	});

	const cancelSubscription = values => {
		setCancelPressed(true);
		console.log('cancelSubscription ', values);
	};

	const cancelSubscriptionConfirm = values => {
		console.log('cancelSubscriptionConfirm ', values);
	};

	const submitCancelSubscription = values => {
		console.log('submitCancelSubscription ', values);
	};

	return (
		<Grid>
			{success ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					Your payment details have been updated.
				</Box>
			) : null}
			{error ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					Oops, something went wrong. Please try again later...
				</Box>
			) : null}
			{formErrorMsg ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}></Box>
			) : null}
			<Box
				p={{base: '0'}}
				pt={{base: '2.5rem'}}
				textStyle={{
					base: 'display5LeftDark',
				}}>
				Change & Manage Payments
			</Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				onSubmit={onSubmit}>
				{({values, errors, touched, isSubmitting}) => (
					<Form style={{width: '100%'}}>
						<Grid
							templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
							gap={{base: '0', md: '6'}}>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Card Information'}
									nameKey={'cardInformation'}
									placeholder='1234 1234 1234 1234'
								/>
								<Grid
									templateColumns={{
										base: 'repeat(1, 1fr)',
										md: 'repeat(2, 1fr)',
									}}
									gap={{base: '3', md: '6'}}>
									<FormikField
										errors={errors}
										touched={touched}
										nameKey={'expiry'}
										placeholder='MM YY'
									/>
									<FormikField
										errors={errors}
										touched={touched}
										nameKey={'last3Digits'}
										placeholder='123'
									/>
								</Grid>

								<ButtonGroup
									spacing={{base: '0', md: '4'}}
									flexDirection={{base: 'column', md: 'row'}}
									display={{base: 'flex'}}
									justifyContent={{base: 'flex-end'}}>
									<Button
										variant='primary'
										type='submit'
										disabled={isSubmitting}
										mt={{base: '4rem'}}
										w={{base: '100%', md: 'auto'}}>
										Update Details
									</Button>
									<Button
										onClick={() => {
											cancelSubscription(values);
										}}
										variant='secondary'
										type='submit'
										disabled={isSubmitting}
										mt={{base: '4rem'}}
										w={{base: '100%', md: 'auto'}}>
										Cancel Subscription
									</Button>
								</ButtonGroup>

								{cancelPressed ? (
									<Box pt={{base: '4rem'}}>
										<FormikFieldSelect
											errors={errors}
											touched={touched}
											label={'Select your reason why you would like to cancel:'}
											nameKey={'signPostingType'}
											options={signPostingTypes}
											required={true}
										/>
										<Box
											textStyle={{
												base: 'paragraph1LeftItalicDark',
											}}>
											The ASSC will contact you within 48 hours to discuss your
											cancellation.
										</Box>
										<ButtonGroup
											spacing={{base: '0', md: '4'}}
											flexDirection={{base: 'column', md: 'row'}}
											display={{base: 'flex'}}
											justifyContent={{base: 'flex-end'}}>
											<Button
												onClick={() => {
													cancelSubscriptionConfirm(values);
												}}
												variant='secondary'
												type='button'
												disabled={isSubmitting}
												mt={{base: '4rem'}}
												w={{base: '100%', md: 'auto'}}>
												Cancel
											</Button>
											<Button
												onClick={() => {
													submitCancelSubscription(values);
												}}
												variant='primary'
												type='button'
												disabled={isSubmitting}
												mt={{base: '4rem'}}
												w={{base: '100%', md: 'auto'}}>
												Submit
											</Button>
										</ButtonGroup>
									</Box>
								) : null}
							</GridItem>
						</Grid>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};
