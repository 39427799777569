/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1
 *
 * @format
 */

/** @format */

import {PaginatedPostGridProps} from './paginatedPostGridProps';

export const paginatedPostGridMapping = (
	data: any,
): PaginatedPostGridProps | undefined => {
	if (!data) {
		return null;
	}

	let fixedCategory = {};

	if (data.fixedCategory) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		fixedCategory = data.fixedCategory;
	}

	let hideFilter = [];

	if (data.hideFilter) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		hideFilter = data.hideFilter;
	}

	const paginatedPostGridProps = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		postType: data.postType || 'posts',
		fixedCategory,
		hideFilter,
	};

	return paginatedPostGridProps;
};
