/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {Flex, Box, useBreakpointValue} from '@chakra-ui/react';
import React, {ReactElement} from 'react';
import {contentPadding, direction, imageHeight, imageWidth} from './helpers';
import Image from '../Image';
import {EmptyCardProps} from './EmptyCardProps';
import placeholderImg from '../../../public/images/empty-card.svg';
import placeholderImgFull from '../../../public/images/empty-card-full.svg';

const CardEmpty = ({
	containerProps,
	cardStyle = 'COLUMN',
	isFeatured,
}: EmptyCardProps): ReactElement => {
	const directionProps = direction(cardStyle);
	const imageWidthProps = imageWidth(cardStyle);
	const imageHeightProps = imageHeight(cardStyle);
	const contentPaddingProps = contentPadding(cardStyle);
	const image = cardStyle => {
		switch (cardStyle) {
			case 'ROW_COLUMN':
				return useBreakpointValue({
					base: placeholderImg,
					md: placeholderImgFull,
				});
			case 'COLUMN':
				return useBreakpointValue({base: placeholderImgFull});
			case 'ROW':
				return useBreakpointValue({base: placeholderImg});
			case 'COLUMN_ROW':
				return useBreakpointValue({
					base: placeholderImgFull,
					md: placeholderImg,
				});
			default:
				return useBreakpointValue({base: placeholderImgFull});
		}
	};

	const placeholderImgSrc = image(cardStyle);

	const FeaturedCard = () => (
		<Flex
			direction={{base: 'column', md: 'row'}}
			alignItems='stretch'
			justifyContent={{base: 'stretch', md: 'space-between'}}
			minHeight={{base: '201px', md: '273px', lg: '399px'}}
			{...containerProps}
		>
			{
				<Box
					width={{base: '100%', md: 'calc(50% - 16px)'}}
					paddingTop={{base: '71.5909091%', md: 0}}
					flexGrow={0}
					position='relative'
					overflow='hidden'
					order={{base: 1, md: 2}}
					bgColor='flag.100'
				>
					<Image
						src={placeholderImgSrc || placeholderImg}
						alt=' '
						layout='fill'
						objectFit='contain'
						objectPosition='center'
					/>
				</Box>
			}
			<Flex
				direction='column'
				alignItems='flex-start'
				justifyContent={{base: 'flex-start', md: 'center'}}
				sx={{'> *:last-child': {mb: '0'}}}
				paddingY={{base: '6', md: '4'}}
				paddingX={{base: 0, md: '4'}}
				paddingLeft={{md: 0}}
				order={{base: 2, md: 1}}
				width={{base: '100%', md: '50%'}}
				minHeight={{base: '201px'}}
			>
				<Box
					textStyle={{
						base: 'paragraph1LeftDark',
					}}
					mb={{base: '8'}}
					whiteSpace='pre-line'
				>
            Loading...
				</Box>
			</Flex>
		</Flex>
	);

	return (
		<>
			{isFeatured ? (
				<FeaturedCard />
			) : (
				<Flex
					direction={directionProps}
					alignItems='stretch'
					justifyContent='stretch'
					minHeight={{base: '201px', md: '273px'}}
					layerStyle={
						cardStyle === 'ROW' || cardStyle === 'ROW_COLUMN'
							? 'cardLight'
							: {sm: 'cardLight'}
					}
					{...containerProps}
				>
					{
						<Box
							width={imageWidthProps}
							paddingTop={imageHeightProps}
							flexGrow={0}
							flexShrink={0}
							position='relative'
							overflow='hidden'
							bgColor='flag.100'
						>
							<Image
								src={placeholderImgSrc || placeholderImg}
								alt=' '
								layout='fill'
								objectFit='contain'
								objectPosition='center'
							/>
						</Box>
					}
					<Flex
						direction='column'
						alignItems='flex-start'
						justifyContent='space-between'
						sx={{'> *:last-child': {mb: '0'}}}
						minHeight={{base: '201px'}}
						{...contentPaddingProps}
					>
						<Box
							textStyle={{
								base: 'paragraph1LeftDark',
							}}
							mb={{base: '8'}}
							whiteSpace='pre-line'
						>
              Loading...
						</Box>
					</Flex>
				</Flex>
			)}
		</>
	);
};

export default CardEmpty;
