/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import {ImageProps} from 'next/image';
import {linkResolver} from '../../../lib/linkResolver';
import {HeroBanner2ColumnProps} from './HeroBanner2ColumnProps';

export const heroBanner2ColumnMapping = (data: any): HeroBanner2ColumnProps => {
	if (!data) {
		return null;
	}

	const image: ImageProps = data.image
		? {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			alt: data.image?.altText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			src: data.image?.sourceUrl,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			height: data.image?.mediaDetails.height,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			width: data.image?.mediaDetails.width,
		}
		: undefined;

	const heroBanner2ColumnData: HeroBanner2ColumnProps = {
		image,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		isFullBleed: data.imageDisplayFullBleed,
		heroText: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			variant: data.herotextVariant,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			badge: {label: data.herotextLabel},
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			heading: data.herotextHeading,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			supportingText: data.herotextSupportingText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			buttonLink: data.herotextButtonLink
				? {
					...data.herotextButtonLink,
					url: linkResolver(data.herotextButtonLink.url),
				}
				: undefined,
		},
	};

	return heroBanner2ColumnData;
};
