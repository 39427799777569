/** @format */

import {Box, Button, ButtonGroup, Grid, GridItem} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {FormikField} from '../../FormikField/FormikField';
import {OptionListProps} from '../../FormikField/OptionProps';
import {FormikFieldSelect} from '../../FormikField/Select';
const signPostingTypes: OptionListProps = [
	{value: '', text: 'Select your option', disabled: true},
	{value: 'romantic', text: 'Romantic break'},
	{value: 'coastal', text: 'Coastal break'},
	{value: 'activity', text: 'Activity break'},
	{value: 'hotTub', text: 'Hot tub break'},
];

export const SignpostingForm: React.FC<{
	onSubmit: any;
	success: string;
	error: boolean;
}> = ({onSubmit, success, error}) => {
	const [formErrorMsg, setFormErrorMsg] = useState('');

	useEffect(() => {
		setFormErrorMsg('Signposting has been updated.');

		return () => {
			setFormErrorMsg('');
		};
	}, []);

	const initialValues: {nameOfProperty: string; signPostingType: string} = {
		nameOfProperty: '',
		signPostingType: '',
	};

	const validationSchema = Yup.object().shape({
		nameOfProperty: Yup.string().required('Required'),
		signPostingType: Yup.string().required('Required'),
	});

	const removeSignPosting = values => {
		console.log('removeSignPosting ', values);
	};

	return (
		<Grid>
			{success ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					Signposting has been updated.
				</Box>
			) : null}
			{error ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					Oops, something went wrong. Please try again later...
				</Box>
			) : null}
			{formErrorMsg ? (
				<Box
					p={{base: '0'}}
					textStyle={{
						base: 'display7LeftDark',
					}}>
					{formErrorMsg}
				</Box>
			) : null}

			<Box
				p={{base: '0'}}
				pt={{base: '2.5rem'}}
				textStyle={{
					base: 'display5LeftDark',
				}}>
				Manage Signposting
			</Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				onSubmit={onSubmit}>
				{({values, errors, touched, isSubmitting}) => (
					<Form style={{width: '100%'}}>
						<Grid
							templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}}
							gap={{base: '0', md: '6'}}>
							<GridItem>
								<FormikField
									errors={errors}
									touched={touched}
									label={'Name of Property:'}
									nameKey={'nameOfProperty'}
								/>
								<FormikFieldSelect
									errors={errors}
									touched={touched}
									label={'Select your signposting type:'}
									nameKey={'signPostingType'}
									options={signPostingTypes}
									required={true}
								/>

								<ButtonGroup
									spacing={{base: '0', md: '4'}}
									flexDirection={{base: 'column', md: 'row'}}
									display={{base: 'flex'}}
									justifyContent={{base: 'flex-end'}}>
									<Button
										variant='primary'
										type='submit'
										disabled={isSubmitting}
										mt={{base: '4rem'}}
										w={{base: '100%', md: 'auto'}}>
										Add
									</Button>
									<Button
										onClick={() => {
											removeSignPosting(values);
										}}
										variant='secondary'
										type='button'
										disabled={isSubmitting}
										mt={{base: '4rem'}}
										w={{base: '100%', md: 'auto'}}>
										Remove
									</Button>
								</ButtonGroup>
							</GridItem>
						</Grid>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};
